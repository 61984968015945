export const Agriculture_pest_detection = [
  {
    image: require("../Images/Agriculture_pest_detection/normal1.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage1.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal9.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal2.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage2.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal3.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal4.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage3.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage4.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal5.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal6.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage5.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage6.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage7.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Agriculture_pest_detection/normal7.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal8.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage8.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal10.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage9.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage10.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal11.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage11.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal12.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage12.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage13.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal14.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage14.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage15.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Agriculture_pest_detection/normal15.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal16.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage16.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage17.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Agriculture_pest_detection/normal17.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage18.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal18.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal19.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage19.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal20.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/Pest_Damage20.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Agriculture_pest_detection/normal13.jpg"),
    label: "Unlabeled",
  },
];
