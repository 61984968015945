export const Photography_smile_detection = [
  {
    image: require("../Images/Photography_smile_detection/smile13.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile3.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile7.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile13.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile3.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile12.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile16.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile21.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile4.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile9.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile22.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile8.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile19.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile24.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile14.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile5.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile9.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile15.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile19.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile18.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile20.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile16.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile22.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile23.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile25.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile1.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile11.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile2.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile1.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile2.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile14.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile7.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile23.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile17.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile10.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile4.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile11.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile12.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile20.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile21.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile5.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile15.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/smile17.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile8.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Photography_smile_detection/not_smile24.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile10.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile18.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile25.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/smile6.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Photography_smile_detection/not_smile6.jpg"),
    label: "Unlabeled",
  },
];
