import React, { useState, useEffect, useRef, useContext } from "react";
import { colors } from "../Styles/colors.js";
import {
  Switch,
  TextInput,
  ScrollView,
  FlatList,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import { Ionicons } from "@expo/vector-icons";
import { Overlay } from "react-native-elements";

import { auth } from "../References/firebase";
import { AuthContext } from "../References/Auth";
import Header from "../Components/Header";
import ProfileInfo from "../Components/ProfileInfo";

export default function Error({ navigation }) {
  const window = Dimensions.get("window");
  const [dimensions, setDimensions] = useState({ window });
  const [showProfileInfo, setShowProfileInfo] = useState(false);

  const width = dimensions.window.width;
  const height = dimensions.window.height;

  const { signInCheck } = useContext(AuthContext);

  const animation = useRef(null);

  async function checkIfPremium() {
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }

  useEffect(() => {
    //   auth.currentUser.reload()
    signInCheck == "signed_in" && checkIfPremium();
  });

  function showProfile() {
    setShowProfileInfo(true);
  }

  function closeProfile() {
    setShowProfileInfo(false);
  }
  //Navigation function + parameters (passed to other pages)
  function goTo(page, params) {
    navigation.navigate(page, params);
  }

  return (
    <View style={{ width: width, height: "100%" }}>
      <Header showProfile={showProfile} goTo={goTo} />
      <View style={{ width: "100%", height: "90%" }}>
        <Image
          source={require("../assets/404.png")}
          style={{ position: "absolute", width: width, height: "100%" }}
        />
        <View
          style={{
            position: "absolute",
            width: "35%",
            height: "20%",
            top: "50%",
            left: "15%",
          }}
        >
          <Text
            style={{
              paddingTop: 10,
              fontFamily: "Inter_700Bold",
              fontSize: 30,
              zIndex: 2,
              color: "black",
            }}
          >
            Uh-oh! PAGE NOT FOUND
          </Text>
          <Text
            style={{
              paddingTop: 20,
              fontFamily: "Inter_500Medium",
              fontSize: 20,
              zIndex: 2,
              color: "black",
              opacity: 0.6,
            }}
          >
            We can't find the page you're looking for. Use the button below to
            head back to the home page!
          </Text>
          <Pressable
            onPress={() => navigation.navigate("Home")}
            style={({ hovered }) => [
              {
                width: 200,
                height: 50,
                borderRadius: 10,
                alignItems: "center",
                marginTop: 20,
                flexDirection: "row",
              },
            ]}
          >
            <Ionicons name="arrow-back-outline" size={25} color={"black"} />
            <Text
              style={{
                color: "black",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Back
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}
