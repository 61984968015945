export const colors = {
  green: "#00ddb3",
  greenDark: "#006653",
  greenSwitch: "#4cca61",

  red: "#eb1e63",
  redDark: "#9d1341",
  redHover: "#d31253",
  redError: "#f3729d",

  greyBackground: "#f5f5f7",
  greySidebar: "#f8fafc",
  greyDark: "#505456", //font
  greyDarkHover: "#39394c",
  greyCancel: "#f0f1f1",
  greyCancelHover: "#e6e6e6",
  greyDisabled: "#f0f1f1",
  greyDisabledHover: "#e6e6e6",
  greyLightFont: "#7a8185",
  greyBorder: "#d9e0e6",

  blueChangePassword: "#1a75ff",

  blueLabel: "#ccebff",
  orangeLabel: "#ffebcc",
  greenLabel: "#e6ffe6",
  purpleLabel: "#f2e6ff",
  redLabel: "#ffcce0",

  blueLabelBorder: "#007aff",
  orangeLabelBorder: "#ff9502",
  greenLabelBorder: "#00b300",
  purpleLabelBorder: "#8b00ff",
  redLabelBorder: "#eb1e63",

  /* blueLabelBorder: "#0173e6",
  orangeLabelBorder: "#ff9900",
  greenLabelBorder: "#03bf15",
  purpleLabelBorder: "#9866ff",
  redLabelBorder: "#e71741", */

  blueShadow: "#99d6ff",
  orangeShadow: "#ffd699",
  greenShadow: "#b3ffb3",
  purpleShadow: "#d7b3ff",
  redShadow: "#ff99c2",

  /*  blueShadow: "#0173e6",
  orangeShadow: "#ff9900",
  greenShadow: "#03bf15",
  purpleShadow: "#9866ff",
  redShadow: "#e71741", */
};
