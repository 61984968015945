import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";
import {
  TextInput,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  Alert,
} from "react-native";
import { Overlay } from "react-native-elements";
import { Hoverable, Pressable } from "react-native-web-hover";
import { ActivityIndicator } from "react-native-web";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { colors } from "../Styles/colors";
import { auth, db } from "../References/firebase.js";
import { collection, addDoc, Firestore, doc, setDoc } from "firebase/firestore";
import { AuthContext } from "../References/Auth.js";
import * as Animatable from "react-native-animatable";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export default function SignUp_Editor({ navigation }) {
  var validator = require("email-validator");

  const [emailText, setEmailText] = useState("");
  const [passwordText1, setPasswordText1] = useState("");
  const { dimensions } = useContext(AuthContext);

  const [passwordText2, setPasswordText2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [atleast6Characters, setAtleast6Characters] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [signedUp, setSignedUp] = useState(false);
  const [startedHandleSignUp, setStartedHandleSignUp] = useState(false);
  const [sentVerification, setSentVerification] = useState(false);

  //Auth Provider Object(s)
  const googleProvider = new GoogleAuthProvider();

  //Google Sign Up / Login Function
  const googleSignUp = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        const ref = doc(db, "users", result.user.uid);
        const docRef = await setDoc(ref, {
          uid: result.user.uid,
          email: result.user.email,
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    passwordText1.length > 5
      ? setAtleast6Characters(true)
      : setAtleast6Characters(false);
    passwordText2.length > 0 && passwordText1 == passwordText2
      ? setPasswordsMatch(true)
      : setPasswordsMatch(false);
  });

  useEffect(() => {
    validator.validate(emailText) ? setValidEmail(true) : setValidEmail(false);
  }, [emailText]);

  const width = dimensions.window.width;
  const height = dimensions.window.height;

  const handleSignUp = () => {
    setStartedHandleSignUp(true);
    setSignedUp(true);
    createUserWithEmailAndPassword(auth, emailText, passwordText1)
      .then(async (userCredentials) => {
        const user = userCredentials.user;
        const ref = doc(db, "users", userCredentials.user.uid);
        const docRef = await setDoc(ref, {
          uid: userCredentials.user.uid,
          email: emailText,
        });
        sendEmailVerification(auth.currentUser);
        setEmailText("");
        setPasswordText1("");
        setPasswordText2("");
        setSentVerification(true);
        setSignedUp(false);
        signOut(auth).catch((error) => {
          alert(error);
        });
      })
      .catch((error) => {
        if (error.code == "auth/email-already-in-use") {
          setSignedUp(false);
          alert(
            "This email is already in use. Please navigate to the login screen or create an account with a different email."
          );
        } else {
          alert(error.code);
        }
      });
    setStartedHandleSignUp(false);
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Overlay
        isVisible={loadingImages}
        fullScreen={true}
        overlayStyle={{ backgroundColor: "white", zIndex: 100 }}
      >
        <View
          style={{
            height: height,
            width: width,
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color={colors.blueLabelBorder} />
        </View>
      </Overlay>
      <View style={{ height: "100%", width: "100%", flexDirection: "row" }}>
        <View style={{ height: "100%", width: "55%" }}>
          <Image
            onLoadEnd={() => setLoadingImages(false)}
            style={{ height: "100%", width: "100%" }}
            source={require("../assets/SignUp.png")}
          />
        </View>
        <View
          style={{ height: "100%", width: "45%", backgroundColor: "white" }}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "white",
              justifyContent: "center",
              padding: "15%",
            }}
          >
            {sentVerification && (
              <Animatable.View
                animation={"fadeInRight"}
                style={{
                  backgroundColor: "#e6fffa",
                  padding: 15,
                  width: "75%",
                  paddingVertical: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 10,
                  marginBottom: "5%",
                  zIndex: 2,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    textAlign: "center",
                  }}
                >
                  Please verify your account using the email sent to the address
                  you provided
                </Text>
              </Animatable.View>
            )}
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                fontSize: 40,
                paddingBottom: 40,
              }}
            >
              Sign Up
            </Text>
            <View
              style={{
                flexDirection: width > 450 && "row",
              }}
            >
              <TouchableOpacity onPress={() => googleSignUp()}>
                <View
                  style={{
                    width: 190,
                    padding: 2,
                    borderRadius: 5,
                    backgroundColor: "#4086f4",
                    marginTop: width > 450 ? 15 : 5,
                    marginRight: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: 35,
                      height: 35,
                      backgroundColor: "white",
                      borderRadius: 3,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={require("../assets/Google_icon.png")}
                      style={{ width: 35, height: 35 }}
                    />
                  </View>
                  <View
                    style={{
                      width: 155,
                      alignItems: "center",
                      justifyContent: "center",
                      height: 35,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 13,
                      }}
                    >
                      Sign up with Google
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={{
                width: width < 550 ? "100%" : "70%",
                height: 1,
                borderBottomWidth: 1,
                marginVertical: width > 450 ? 20 : 15,
                borderColor: colors.greyBorder,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: colors.greyLightFont,
                  backgroundColor: "white",
                  paddingHorizontal: 15,
                  marginTop: -8,
                }}
              >
                OR
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 16,
                paddingBottom: 15,
              }}
            >
              Email Address
            </Text>
            <TextInput
              style={{
                height: 40,
                width: "70%",
                borderWidth: 1,
                borderRadius: 10,
                borderColor: "grey",
                padding: 10,
              }}
              placeholder={"Email"}
              onChangeText={setEmailText}
              value={emailText}
            />
            {!validEmail && emailText.length > 0 && (
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: "red",
                }}
              >
                Please enter a valid email address
              </Text>
            )}
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 16,
                paddingTop: 50,
                paddingBottom: 15,
              }}
            >
              Password
            </Text>
            <TextInput
              style={{
                height: 40,
                width: "70%",
                borderWidth: 1,
                borderRadius: 10,
                borderColor: "grey",
                padding: 10,
              }}
              placeholder={"Password"}
              onChangeText={setPasswordText1}
              secureTextEntry
              value={passwordText1}
            />
            {!atleast6Characters && passwordText1.length > 0 && (
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: "red",
                }}
              >
                Must be at least 6 characters in length
              </Text>
            )}
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 16,
                paddingTop: 25,
                paddingBottom: 15,
              }}
            >
              Re-type Password
            </Text>
            <TextInput
              style={{
                height: 40,
                width: "70%",
                borderWidth: 1,
                borderRadius: 10,
                borderColor: "grey",
                padding: 10,
              }}
              placeholder={"Password"}
              onChangeText={setPasswordText2}
              secureTextEntry
              value={passwordText2}
              onKeyPress={(e) =>
                e.key == "Enter" &&
                passwordsMatch &&
                validEmail &&
                !startedHandleSignUp &&
                handleSignUp()
              }
            />
            {!passwordsMatch && passwordText2.length > 0 && (
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: "red",
                }}
              >
                Passwords do not match
              </Text>
            )}
            <Pressable
              onPress={() => handleSignUp()}
              disabled={passwordsMatch && validEmail ? false : true}
              style={({ hovered }) => [
                {
                  height: 50,
                  width: 150,
                  borderRadius: 5,
                  marginTop: 40,
                  backgroundColor:
                    passwordsMatch && validEmail
                      ? hovered
                        ? "#d31253"
                        : "#eb1e63"
                      : "#f0f1f1",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                },
              ]}
            >
              <Text
                style={{
                  color: passwordsMatch && validEmail ? "white" : "lightgrey",
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Sign Up
              </Text>
              {signedUp && (
                <ActivityIndicator
                  color={"white"}
                  size={"small"}
                  style={{ paddingLeft: 10 }}
                />
              )}
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
}
