import React, { useState } from "react";
import { colors } from "../Styles/colors.js";
import { Text, View } from "react-native";

export default function ScreenTooSmall({ navigation }) {
  return (
    <View
      style={{
        backgroundColor: colors.greyBackground,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text>AmpLabel.io works best on a larger screen</Text>
    </View>
  );
}
