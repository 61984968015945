import React, { useState, useContext, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Dimensions } from "react-native";

import {
  collection,
  query,
  getDocs,
  where,
  addDoc,
  Firestore,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { auth, db } from "./firebase.js";

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [signInCheck, setSignInCheck] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [checkCanceled, setCheckCanceled] = useState(null);
  const [premiumStatus, setPremiumStatus] = useState(false);
  const [subscriptionLength, setSubscriptionLength] = useState(null);
  const window = Dimensions.get("window");
  const [dimensions, setDimensions] = useState({ window });
  const width = dimensions.window.width;
  //const height = dimensions.window.height;
  const height = 825;
  const onChange = ({ window }) => {
    setDimensions({ window });
  };

  useEffect(() => {
    //listen for screen size changes
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });

  useEffect(() => {
    //check if signed in
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setSignInCheck(currentUser ? "signed_in" : "signed_out");
    });
    return unsubscribe;
  }, []);

  /* async function checkIfPremium() {
    //function to check if premium
    const decodedToken = await auth.currentUser.getIdTokenResult();
    setPremiumStatus(decodedToken?.claims?.stripeRole);
  }
  useEffect(() => {
    //check if premium
    //check premium status
    {
      premiumStatus != "premium" &&
        signInCheck == "signed_in" &&
        checkIfPremium();
    }
  }); */

  /*  useEffect(() => {
    async function getSubStatus() {
      try {
        const originalSubscriptionStatus = subscriptionStatus;
        const originalPeriodEnd = periodEnd;
        const originalCheckCanceled = checkCanceled;
        const originalSubscriptionLength = subscriptionLength;

        const subRef = collection(
          db,
          "users",
          auth.currentUser?.uid,
          "subscriptions"
        );
        const querySnapshot = await getDocs(subRef);
        const subscriptionList = [];
        querySnapshot.forEach((doc) => {
          subscriptionList.push((doc.id, " => ", doc.data()));
        });
        var secs =
          subscriptionList.length > 0
            ? subscriptionList[subscriptionList.length - 1].current_period_end
            : "none";
        const cleanSecs =
          subscriptionList.length > 0
            ? JSON.stringify(secs)
                .slice(11)
                .slice(0, JSON.stringify(secs).slice(11).indexOf(","))
            : "none";
        const EndPeriod =
          subscriptionList.length > 0 ? new Date(cleanSecs * 1000) : "none";

        setSubscriptionStatus(
          subscriptionList.length > 0
            ? await subscriptionList[subscriptionList.length - 1].status
            : "none"
        );
        const isCanceled =
          subscriptionList.length > 0
            ? await subscriptionList[subscriptionList.length - 1]
                .cancel_at_period_end
            : "none";
        setCheckCanceled(
          subscriptionList.length > 0
            ? isCanceled == true
              ? "Canceled"
              : "Active"
            : "none"
        );

        setPeriodEnd(
          subscriptionList.length > 0 ? await JSON.stringify(EndPeriod) : "none"
        );
        {
          try {
            setSubscriptionLength(
              (await subscriptionList[subscriptionList.length - 1].items[0]
                ?.plan?.interval) == "month"
                ? "monthly"
                : subscriptionList[subscriptionList.length - 1].items[0]?.plan
                    ?.interval == "year"
                ? "yearly"
                : ""
            );
          } catch {}
        }
      } catch {}
    }
    signInCheck == "signed_in" && getSubStatus();
    signInCheck == "signed_in" && console.log("i fired");
  }); */

  const value = {
    signInCheck,
    width,
    height,
    dimensions,
    //premiumStatus,
    /* subscriptionStatus,
    periodEnd,
    checkCanceled,
    subscriptionLength, */
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
