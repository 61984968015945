export const Vehicle_street_signs = [
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end4.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop4.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed1.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed2.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop2.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed4.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end5.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed5.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end15.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop12.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end12.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed12.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop15.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end2.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/speed15.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end1.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop5.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/dead_end9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicle_street_signs/stop1.jpg"),
  },
];
