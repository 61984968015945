export const Vehicles_bike_skateboard_surfboard = [
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard/bike1.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike2.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf5.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard1.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf24.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf2.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard2.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike3.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf30.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard28.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf1.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike4.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf3.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard3.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf7.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard4.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike8.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard5.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike5.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf4.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard7.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike6.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf6.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike7.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard8.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike9.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf8.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard16.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike10.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike11.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf9.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard24.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf10.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf11.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard12.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf12.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike12.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard13.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard14.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike13.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf13.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard9.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf14.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike14.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard15.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike15.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard10.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike16.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard6.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf15.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf16.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf17.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike17.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard17.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike18.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf18.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf19.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike19.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard18.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard19.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike20.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard11.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf20.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard20.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf21.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike21.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf22.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard21.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike22.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard22.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike23.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf23.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike24.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard23.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard25.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike25.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard26.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike26.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf25.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard27.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf26.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike27.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf27.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf28.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard29.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike28.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard30.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf29.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard31.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike29.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike30.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard32.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike31.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//skateboard33.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf31.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike32.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf32.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//surf33.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Vehicles_bike_skateboard_surfboard//bike33.png"),
  },
];
