import React, { useState, useContext, useEffect, useRef } from "react";
import {
  ScrollView,
  Text,
  View,
  Image,
  TouchableOpacity,
  StyleSheet,
  Animated,
} from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import LottieView from "react-native-web-lottie";
import { AuthContext } from "../References/Auth.js";
import { LinearGradient } from "expo-linear-gradient";
import * as Animatable from "react-native-animatable";
import { Ionicons } from "@expo/vector-icons";
import { colors } from "../Styles/colors";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Anchor from "../Components/Anchor";
import { auth } from "../References/firebase";
import MainButton from "../Components/MainButton";
import SecondaryButton from "../Components/SecondaryButton";

export default function Pricing({ navigation }) {
  const { signInCheck, width, height } = useContext(AuthContext);
  const [premiumStatus, setPremiumStatus] = useState(false);

  const contentWidth = 1037;

  //Check if the user is premium
  async function checkIfPremium() {
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }

  //Run premium status check (only if signed in)
  useEffect(() => {
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  //Navigation function + parameters (passed to other pages)
  function goTo(page, params) {
    navigation.navigate(page, params);
  }

  //Animation const used with Lottie
  const animation = useRef(null);

  return (
    <ScrollView
      stickyHeaderIndices={[0]}
      stickyHeaderHiddenOnScroll={true}
      style={[styles.container, { width: width }]}
    >
      <View style={[styles.headerContainer, { backgroundColor: "white" }]}>
        <Header goTo={goTo} style={styles.header} />
      </View>

      <View style={[styles.innerContainer, { width: width }]}>
        <View //Intro
          style={[{ width: width }, styles.introContainer]}
        >
          <Text style={styles.pageHeaderTextMiddle6}>What is Boltlabel?</Text>
          <Text style={styles.pageHeaderTextBottom7}>
            Boltlabel is a browser-based image labeling tool automating the
            creation of training data for computer vision models
          </Text>
          <View style={styles.underHeaderSpacer} />
          <Animatable.Image
            animation={"fadeIn"}
            delay={700}
            source={require("../assets/AboutBackground.png")}
            style={{ width: 1000, height: 350, position: "absolute" }}
          />
        </View>

        <Animatable.View
          animation={"fadeInUp"}
          delay={400}
          style={[
            {
              width: width * 0.8,
            },
            styles.contentContainer,
          ]}
        >
          <View //Layer 1
            style={styles.contentLayer1Container3}
          >
            <View
              style={{
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                height: 450,
                width: "39%",
                backgroundColor: "white",
              }}
            >
              <View style={styles.cardWhiteContent1}>
                <Text style={styles.cardContentHeaderText1}>
                  Expedited Training Data
                </Text>
                <Text style={styles.cardContentBodyText2}>
                  Boltlabel uses machine learning to automate the tedious and
                  time-consuming process of image tagging, allowing users to
                  quickly and accurately label large volumes of data.
                </Text>
                <Text style={styles.cardContentBodyText2}>
                  With Boltlabel, you can speed up the development of your
                  computer vision projects, allowing you to focus on building
                  and improving your machine learning models.
                </Text>
              </View>
            </View>
            <View style={styles.innerCardSpacer} />
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 0.5, y: 0.5 }}
              colors={[colors.orangeLabelBorder, colors.red]}
              style={{
                borderRadius: 25,
                height: 450,
                width: "60%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LottieView
                ref={animation}
                delay={500}
                style={{
                  width: 420,
                  height: 420,
                }}
                autoPlay={true}
                loop={false}
                source={require("../assets/ImageModel.json")}
              />
            </LinearGradient>
          </View>

          <View style={styles.cardSpacer} />

          <View //Layer 2
            style={{
              flexDirection: "row",
              backgroundColor: "white",
              borderRadius: 25,
              width: "100%",
              alignSelf: "center",
              shadowColor: "black",
              shadowOpacity: 0.0,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 0.5, y: 0.5 }}
              colors={[colors.purpleLabelBorder, colors.blueLabelBorder]}
              style={{
                borderRadius: 25,
                height: 450,
                width: "60%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={require("../assets/Mask.png")}
                style={styles.maskImage1}
              />
              <LottieView
                ref={animation}
                style={styles.card2Lottie1}
                autoPlay={true}
                loop={true}
                source={require("../assets/Shield.json")}
              />
            </LinearGradient>
            <View style={styles.innerCardSpacer} />
            <View
              style={{
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                height: 450,
                width: "39%",
                backgroundColor: "white",
              }}
            >
              <View style={styles.cardWhiteContent1}>
                <Text style={styles.cardContentHeaderText1}>
                  Secure & Private
                </Text>
                <Text style={styles.cardContentBodyText2}>
                  Unlike other image labeling solutions, Boltlabel is purely
                  browser-based and does not save users' image data. This
                  ensures the highest level of security and privacy for your
                  data.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.cardSpacer} />

          <View //Layer 3
            style={{
              flexDirection: "row",
              backgroundColor: "white",
              borderRadius: 25,
              width: "100%",
              alignSelf: "center",
              shadowColor: "black",
              shadowOpacity: 0.0,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            {/*            <View
              style={{
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                height: 450,
                width: "39%",
                backgroundColor: "white",
              }}
            >
              <View style={styles.cardWhiteContent1}>
                <Text style={styles.cardContentHeaderText1}>Affordable</Text>
                <Text style={styles.cardContentBodyText2}>
                  Boltlabel offers mutliple different pricing plans to
                  match your project requirements. No hidden fees or strings
                  attached. Just simple, transparent pricing. Use when you need
                  it and cancel when you don't.
                </Text>
                <View style={{ width: "60%", marginTop: 20 }}>
                  <SecondaryButton
                    disabled={false}
                    onPress={() => navigation.navigate("Pricing")}
                    text={"See Subscriptions"}
                    showLoading={false}
                  />
                </View>
              </View>
            </View> */}
            <View
              style={{
                borderTopLeftRadius: 25,
                borderBottomLeftRadius: 25,
                height: 450,
                width: "39%",
                backgroundColor: "white",
              }}
            >
              <View style={styles.cardWhiteContent1}>
                <Text style={styles.cardContentHeaderText1}>
                  Works with your Cloud
                </Text>
                <Text style={styles.cardContentBodyText2}>
                  Supercharge your labeling experience by connecting your cloud
                  data. Boltlabel's BYOS (bring your own storage) approach
                  ensures that your data is never stored in a 3rd-party
                  environment.
                </Text>
                <Text style={styles.cardContentBodyText2}>
                  Compatible with Google Cloud Storage, AWS, and Azure.
                </Text>
                {/* <View style={{ width: "60%", marginTop: 20 }}>
                  <SecondaryButton
                    disabled={false}
                    onPress={() => navigation.navigate("Pricing")}
                    text={"See Subscriptions"}
                    showLoading={false}
                  />
                </View> */}
              </View>
            </View>
            <View style={styles.innerCardSpacer} />
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 0.5, y: 0.5 }}
              colors={[colors.green, colors.greenLabelBorder]}
              style={{
                borderRadius: 25,
                height: 450,
                width: "60%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 150 }}>☁️</Text>
            </LinearGradient>
          </View>
          <View style={styles.cardSpacer} />

          <View //Layer 4
            style={{
              flexDirection: "row",
              backgroundColor: "white",
              borderRadius: 25,
              width: "100%",
              alignSelf: "center",
              shadowColor: "black",
              shadowOpacity: 0.0,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <LinearGradient
              start={{ x: 0, y: 0 }}
              end={{ x: 0.5, y: 0.5 }}
              colors={["#20252C", "#424955"]}
              style={{
                borderRadius: 25,
                height: 450,
                width: "60%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LottieView
                ref={animation}
                style={{
                  width: 320,
                  height: 320,
                }}
                autoPlay={true}
                loop={false}
                source={require("../assets/Simplifying.json")}
              />
            </LinearGradient>
            <View style={styles.innerCardSpacer} />
            <View
              style={{
                borderTopRightRadius: 25,
                borderBottomRightRadius: 25,
                height: 450,
                width: "39%",
                backgroundColor: "white",
              }}
            >
              <View style={styles.cardWhiteContent1}>
                <Text style={styles.cardContentHeaderText1}>
                  Made for Everyone
                </Text>
                <Text style={styles.cardContentBodyText2}>
                  No code, no hassle. Regardless of your role or level of
                  expertise, Boltlabel is the perfect solution to build
                  high-quality training data sets. Boltlabel's intuitive
                  interface makes it easy for anyone to build robust image
                  datsets.
                </Text>
                <View style={{ width: "60%", marginTop: 20 }}>
                  <SecondaryButton
                    disabled={false}
                    onPress={() => navigation.navigate("Demo")}
                    text={"Try a Demo"}
                    showLoading={false}
                  />
                </View>
              </View>
            </View>
          </View>
        </Animatable.View>
      </View>

      <Anchor goTo={goTo} />

      <Footer goTo={goTo} />
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  headerContainer: {
    zIndex: 100,
    shadowColor: "black",
    shadowOpacity: 0,
    shadowRadius: 15,
    shadowOffset: {
      height: 1,
      width: 1,
    },
  },
  header: { zIndex: 100 },
  container: { backgroundColor: colors.greyBackground },
  innerContainer: { backgroundColor: colors.greyBackground },
  introContainer: { alignItems: "center", zIndex: 2 },
  pageHeaderTextTop: {
    fontSize: 16,
    color: "black",
    fontFamily: "Inter_600SemiBold",
    zIndex: 2,
    paddingTop: 20,
  },
  pageHeaderTextMiddle1: {
    paddingTop: 20,
    fontFamily: "Inter_800ExtraBold",
    fontSize: 35,
    color: "black",
    textAlign: "center",
    zIndex: 2,
    paddingHorizontal: 5,
    paddingTop: 20,
  },
  pageHeaderTextMiddle2: {
    fontFamily: "Inter_800ExtraBold",
    fontSize: 40,
    color: "black",
    textAlign: "center",
    zIndex: 2,
    paddingHorizontal: 5,
    paddingTop: 20,
  },
  pageHeaderTextMiddle3: {
    fontFamily: "Inter_800ExtraBold",
    fontSize: 45,
    color: "black",
    textAlign: "center",
    zIndex: 2,
    paddingHorizontal: 5,
    paddingTop: 20,
  },
  pageHeaderTextMiddle4: {
    fontFamily: "Inter_800ExtraBold",
    fontSize: 50,
    color: "black",
    textAlign: "center",
    zIndex: 2,
    paddingHorizontal: 5,
    paddingTop: 20,
  },
  pageHeaderTextMiddle5: {
    fontFamily: "Inter_800ExtraBold",
    fontSize: 60,
    color: "black",
    textAlign: "center",
    zIndex: 2,
    paddingHorizontal: 5,
    paddingTop: 20,
  },
  pageHeaderTextMiddle6: {
    fontFamily: "Inter_600SemiBold",
    fontSize: 50,
    letterSpacing: -2,
    color: "black",
    textAlign: "center",
    zIndex: 2,
    paddingHorizontal: 5,
    paddingTop: 80,
  },
  pageHeaderTextBottom1: {
    fontSize: 20,
    color: "black",
    opacity: 0.6,
    fontFamily: "Inter_600SemiBold",
    zIndex: 2,
    width: 220,
    textAlign: "center",
    paddingTop: 20,
  },
  pageHeaderTextBottom2: {
    fontSize: 20,
    color: "black",
    opacity: 0.6,
    fontFamily: "Inter_600SemiBold",
    zIndex: 2,
    width: 250,
    textAlign: "center",
    paddingTop: 20,
  },
  pageHeaderTextBottom3: {
    fontSize: 20,
    color: "black",
    opacity: 0.6,
    fontFamily: "Inter_600SemiBold",
    zIndex: 2,
    width: 275,
    textAlign: "center",
    paddingTop: 20,
  },
  pageHeaderTextBottom4: {
    fontSize: 20,
    color: "black",
    opacity: 0.6,
    fontFamily: "Inter_600SemiBold",
    zIndex: 2,
    width: 350,
    textAlign: "center",
    paddingTop: 20,
  },
  pageHeaderTextBottom5: {
    fontSize: 20,
    color: "black",
    opacity: 0.6,
    fontFamily: "Inter_600SemiBold",
    zIndex: 2,
    width: 450,
    textAlign: "center",
    paddingTop: 20,
  },
  pageHeaderTextBottom6: {
    fontSize: 20,
    color: "black",
    opacity: 0.6,
    fontFamily: "Inter_600SemiBold",
    zIndex: 2,
    width: 525,
    textAlign: "center",
    paddingTop: 20,
  },
  pageHeaderTextBottom7: {
    fontFamily: "Inter_500Medium",
    fontSize: 20,
    color: "black",
    zIndex: 2,
    width: 600,
    textAlign: "center",
    paddingTop: 18,
  },
  underHeaderSpacer: { paddingTop: "5%" },
  contentContainer: {
    zIndex: 2,
    marginTop: 100,
    paddingBottom: 100,
    alignSelf: "center",
  },
  contentLayer1Container1: {
    marginTop: "-7%",
    backgroundColor: "white",
    borderRadius: 25,
    width: "100%",
    alignSelf: "center",
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowRadius: 25,
    shadowOffset: {
      height: 7,
      width: 1,
    },
  },
  contentLayer1Container2: {
    backgroundColor: "white",
    width: "100%",
    alignSelf: "center",
    shadowColor: "black",
    shadowOpacity: 0.1,
    shadowRadius: 25,
    shadowOffset: {
      height: 7,
      width: 1,
    },
  },
  contentLayer1Container3: {
    marginTop: "-5%",
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: 25,
    width: "100%",
    alignSelf: "center",
    shadowColor: "black",
    shadowOpacity: 0.0,
    shadowRadius: 25,
    shadowOffset: {
      height: 7,
      width: 1,
    },
  },
  contentLayer1Gradient1: {
    height: 300,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  contentLayer1Gradient2: {
    borderRadius: 25,
    height: 300,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  cardWhiteContainer1: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    height: 500,
    width: "55%",
    backgroundColor: "white",
  },
  cardWhiteContainer3: {
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
    height: 300,
    width: "100%",
    backgroundColor: "white",
  },
  cardWhiteContainer2: {
    height: 500,
    width: "100%",
    backgroundColor: "white",
  },
  cardWhiteContent1: { padding: 40 },
  cardContentHeaderText1: {
    color: "black",
    fontSize: 20,
    fontFamily: "Inter_600SemiBold",
  },
  cardContentBodyText1: {
    color: colors.greyDark,
    paddingTop: 10,
    width: "100%",
    fontSize: 18,
    fontFamily: "Inter_400Regular",
    paddingTop: 30,
  },
  cardContentBodyText2: {
    color: colors.greyDark,
    paddingTop: 10,
    width: "85%",
    fontSize: 16,
    fontFamily: "Inter_400Regular",
    paddingTop: 30,
  },
  card1Lottie1: {
    width: 270,
    height: 270,
  },
  card1Lottie2: {
    width: 225,
    height: 225,
  },
  card2Lottie1: {
    width: 800,
    height: 800,
  },
  cardSpacer: { height: 30 },
  cardGradientContainer1: {
    borderRadius: 25,
    height: 500,
    width: "44%",
    justifyContent: "center",
    alignItems: "center",
  },
  cardGradientContainer2: {
    borderRadius: 25,
    height: 450,
    width: "44%",
    justifyContent: "center",
    alignItems: "center",
  },
  maskImage1: {
    position: "absolute",
    width: 90,
    height: 55,
    zIndex: 2,
  },
  boldText: {
    fontSize: 20,
    color: "black",
    fontFamily: "Inter_700Bold",
  },
  bottomPageGradient: { height: 300, marginTop: -340, zIndex: 4 },
  footerContainer: { backgroundColor: colors.greySidebar, height: 100 },
  navigationButton: {
    position: "absolute",
    bottom: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  innerCardSpacer: { width: 20 },
});
