import React, { useRef } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { colors } from "../Styles/colors";
import { Hoverable, Pressable } from "react-native-web-hover";
import LottieView from "react-native-web-lottie";

export default function MainButton(props) {
  const animation = useRef(null);
  return (
    <Hoverable>
      {({ hovered }) => (
        <TouchableOpacity
          disabled={props.disabled}
          onPress={() => {
            props.onPress();
          }}
          style={{
            height: 40,
            borderRadius: 100,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            backgroundColor: hovered ? colors.blueLabelBorder : "#2859ff",
          }}
        >
          <Text
            style={{
              paddingHorizontal: 30,
              fontSize: 15,
              color: "white",
              opacity: !props.showLoading ? 1 : 0,
              fontFamily: "Inter_500Medium",
            }}
          >
            {props.text}
          </Text>

          {props.showLoading && (
            <LottieView
              ref={animation}
              style={{
                width: 40,
                position: "absolute",
                alignSelt: "center",
                height: 15,
              }}
              autoPlay={true}
              loop={true}
              source={require("../assets/loadingAnimation.json")}
            />
          )}
        </TouchableOpacity>
      )}
    </Hoverable>
  );
}
