export const Animals_dog_cat = [
  {
    image: require("../Images/Animals_dog_cat/cat1.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog1.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat2.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog2.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat3.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat4.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog3.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog4.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog5.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat5.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat6.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog6.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog7.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat7.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat8.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog8.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat9.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog9.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog10.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat10.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat11.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat12.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog11.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog12.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat13.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog13.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog14.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat14.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog15.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat15.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat16.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog16.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog17.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat17.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat18.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog18.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog19.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog20.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat19.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat20.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat21.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog21.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat22.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat23.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog22.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog23.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat25.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/cat24.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog24.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Animals_dog_cat/dog25.jpg"),
    label: "Unlabeled",
  },
];
