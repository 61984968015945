import React, { useState, useContext, useEffect, useRef } from "react";
import {
  TextInput,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
} from "react-native";
import { Overlay } from "react-native-elements";

import { Hoverable, Pressable } from "react-native-web-hover";
import {
  getAuth,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { colors } from "../Styles/colors.js";
import { AuthContext } from "../References/Auth.js";
import * as WebBrowser from "expo-web-browser";

import { createCheckoutSession } from "../Stripe/createCheckoutSession";
import {
  collection,
  query,
  getDocs,
  where,
  addDoc,
  Firestore,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { auth, db } from "../References/firebase.js";
import moment from "moment";
import {
  ENV_CHECKOUT,
  ENV_ST_MO,
  ENV_POST_SUBSCR,
  ENV_POST_CANCEL_SUBSCR,
} from "@env";
import SignUp_Editor from "../Components/SignUp_Editor";
import LottieView from "react-native-web-lottie";
import { ActivityIndicator } from "react-native-web";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButton";

export default function HelpInfo({ navigation }) {
  const [useCasePressed, setUseCasePressed] = useState(true);
  const [addImages, setAddImages] = useState(false);
  const [loadModel, setLoadModel] = useState(false);
  const [restart, setRestart] = useState(false);
  const [exportPressed, setExportPressed] = useState(false);
  const { signInCheck, width, height } = useContext(AuthContext);
  const [showingCheckout, setShowingCheckout] = useState(false);
  const [hidePredictions, setHidePredictions] = useState(false);
  const [predictionsPressed, setPredictionsPressed] = useState(false);
  const [powerLabelPressed, setPowerLabelPressed] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [premiumStatus, setPremiumStatus] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingModel, setLoadingModel] = useState(true);
  const [loadingRestart, setLoadingRestart] = useState(true);
  const [loadingExport, setLoadingExport] = useState(true);
  const [loadingHide, setLoadingHide] = useState(true);
  const [loadingPredictions, setLoadingPredictions] = useState(true);
  const [loadingPowerLabel, setLoadingPowerLabel] = useState(true);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);

  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }
  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  const animation = useRef(null);

  const auth = getAuth();

  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: "white",
        height: height * 0.9,
        width: width * 0.8,
        borderRadius: 5,
        padding: 20,
      }}
    >
      <Overlay //Open Sign Up
        isVisible={signInCheck == "signed_in" ? false : showSignUp}
        fullScreen={false}
        onBackdropPress={() => setShowSignUp(false)}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: height * 0.9,
            width: width * 0.9,
            backgroundColor: "white",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => setShowSignUp(false)}
            style={{
              zIndex: 100,
              position: "absolute",
              borderRadius: 100,
              height: 40,
              width: 40,
              backgroundColor: colors.greyDisabled,
              right: 15,
              top: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "Inter_800ExtraBold",
                fontSize: 16,
                color: colors.greyDark,
              }}
            >
              X
            </Text>
          </TouchableOpacity>
          <SignUp_Editor />
        </View>
      </Overlay>
      <View
        style={{
          borderRightWidth: 1,
          borderColor: "lightgrey",
          height: "100%",
          width: "20%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ScrollView
          contentContainerStyle={{
            justifyContent: "center",
            alignItems: "center",
            width: width * 0.8 * 0.2 - 10,
            height: "100%",
          }}
        >
          <Hoverable //Use Cases
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(false),
                  setLoadModel(false),
                  setRestart(false),
                  setExportPressed(false),
                  setHidePredictions(false),
                  setPredictionsPressed(false),
                  setPowerLabelPressed(false),
                  setUseCasePressed(true)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: useCasePressed
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  borderRadius: 5,
                  justifyContent: "center",
                  height: 40,
                  backgroundColor: useCasePressed
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Use Cases
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 15 }} />

          <Hoverable //Add Images
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(true),
                  setLoadModel(false),
                  setRestart(false),
                  setExportPressed(false),
                  setHidePredictions(false),
                  setPredictionsPressed(false),
                  setPowerLabelPressed(false),
                  setUseCasePressed(false)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: addImages
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  height: 40,
                  borderRadius: 5,
                  justifyContent: "center",
                  backgroundColor: addImages
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Add Images
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 15 }} />

          <Hoverable //Load Model
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(false),
                  setLoadModel(true),
                  setRestart(false),
                  setExportPressed(false),
                  setHidePredictions(false),
                  setPredictionsPressed(false),
                  setPowerLabelPressed(false),
                  setUseCasePressed(false)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: loadModel
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  borderRadius: 5,
                  justifyContent: "center",
                  height: 40,
                  backgroundColor: loadModel
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Load Model
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 15 }} />

          <Hoverable //Restart
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(false),
                  setLoadModel(false),
                  setRestart(true),
                  setExportPressed(false),
                  setHidePredictions(false),
                  setPredictionsPressed(false),
                  setPowerLabelPressed(false),
                  setUseCasePressed(false)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: restart
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  borderRadius: 5,
                  justifyContent: "center",
                  height: 40,
                  backgroundColor: restart
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Restart
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 15 }} />

          <Hoverable //Export
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(false),
                  setLoadModel(false),
                  setRestart(false),
                  setExportPressed(true),
                  setHidePredictions(false),
                  setPredictionsPressed(false),
                  setPowerLabelPressed(false),
                  setUseCasePressed(false)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: exportPressed
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  borderRadius: 5,
                  justifyContent: "center",
                  height: 40,
                  backgroundColor: exportPressed
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Export
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 15 }} />

          <Hoverable //Hide Prediction
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(false),
                  setLoadModel(false),
                  setRestart(false),
                  setExportPressed(false),
                  setHidePredictions(true),
                  setPredictionsPressed(false),
                  setPowerLabelPressed(false),
                  setUseCasePressed(false)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: hidePredictions
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  borderRadius: 5,
                  justifyContent: "center",
                  height: 40,
                  backgroundColor: hidePredictions
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Hide Predictions
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 15 }} />

          <Hoverable //Predictions
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(false),
                  setLoadModel(false),
                  setRestart(false),
                  setExportPressed(false),
                  setHidePredictions(false),
                  setPredictionsPressed(true),
                  setPowerLabelPressed(false),
                  setUseCasePressed(false)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: predictionsPressed
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  borderRadius: 5,
                  justifyContent: "center",
                  height: 40,
                  backgroundColor: predictionsPressed
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Predictions
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 15 }} />

          <Hoverable //Power Label
            style={{ width: "80%" }}
          >
            {({ hovered }) => (
              <TouchableOpacity
                onPress={() => (
                  setAddImages(false),
                  setLoadModel(false),
                  setRestart(false),
                  setExportPressed(false),
                  setHidePredictions(false),
                  setPredictionsPressed(false),
                  setPowerLabelPressed(true),
                  setUseCasePressed(false)
                )}
                style={{
                  borderLeftWidth: 4,
                  borderColor: powerLabelPressed
                    ? "#2859ff"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                  paddingLeft: 10,
                  borderRadius: 5,
                  justifyContent: "center",
                  height: 40,
                  backgroundColor: powerLabelPressed
                    ? "#f0f1f1"
                    : hovered
                    ? "#f0f1f1"
                    : "#fff",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Power Label
                </Text>
              </TouchableOpacity>
            )}
          </Hoverable>

          <View style={{ height: 30 }} />

          {signInCheck == "signed_in" && premiumStatus != "premium" && (
            <Hoverable //Upgrade
              style={{ width: "80%" }}
            >
              {({ hovered }) => (
                <TouchableOpacity
                  disabled={showingCheckout}
                  onPress={() => {
                    setShowingCheckout(true),
                      createCheckoutSession(
                        auth.currentUser?.uid,
                        ENV_ST_MO,
                        ENV_POST_SUBSCR,
                        ENV_POST_CANCEL_SUBSCR
                      ),
                      setTimeout(() => {
                        setShowingCheckout(false);
                      }, 5000);
                  }}
                  style={{
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    height: 40,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                  }}
                >
                  {!showingCheckout && (
                    <Text
                      style={{
                        fontSize: 16,
                        color: "white",
                        fontFamily: "Inter_600SemiBold",
                        textAlign: "center",
                      }}
                    >
                      Upgrade
                    </Text>
                  )}
                  {showingCheckout && (
                    <LottieView
                      ref={animation}
                      style={{
                        width: 40,
                        height: 15,
                      }}
                      autoPlay={true}
                      loop={true}
                      source={require("../assets/loadingAnimation.json")}
                    />
                  )}
                </TouchableOpacity>
              )}
            </Hoverable>
          )}
          {/*  {signInCheck == "signed_out" && (
           <MainButton
           disabled={false}
           onPress={() => setShowSignUp(true)}
           text={"Sign Up"}
           showLoading={false}
         />
          )} */}
        </ScrollView>
      </View>
      {useCasePressed && (
        <View style={{ width: "80%", padding: "5%" }}>
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_600SemiBold",
              paddingTop: "5%",
            }}
          >
            Use Cases
          </Text>
          <View
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              source={require("../assets/UseCases_Help.png")}
              onLoadEnd={() => setLoadingImages(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {useCasePressed && loadingSubscriptions && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
      {addImages && (
        <View style={{ width: "80%", padding: "5%" }}>
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_600SemiBold",
              paddingTop: "5%",
            }}
          >
            Add Images
          </Text>
          <View
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              source={require("../assets/AddImages_Help.png")}
              onLoadEnd={() => setLoadingImages(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {addImages && loadingImages && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
      {loadModel && (
        <View style={{ width: "80%", padding: "5%" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                color: "#505456",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
                paddingTop: "5%",
              }}
            >
              Load Model
            </Text>
          </View>
          <View style={{ height: "100%", width: "100%" }}>
            <Image
              source={require("../assets/LoadModel_Help.png")}
              onLoadEnd={() => setLoadingModel(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {loadModel && loadingModel && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
      {restart && (
        <View style={{ width: "80%", padding: "5%" }}>
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_600SemiBold",
              paddingTop: "5%",
            }}
          >
            Restart
          </Text>
          <View
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              source={require("../assets/Restart_Help.png")}
              onLoadEnd={() => setLoadingRestart(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {restart && loadingRestart && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
      {exportPressed && (
        <View style={{ width: "80%", padding: "5%" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                color: "#505456",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
                paddingTop: "5%",
              }}
            >
              Export
            </Text>
          </View>
          <View style={{ height: "100%", width: "100%" }}>
            <Image
              source={require("../assets/Export_Help.png")}
              onLoadEnd={() => setLoadingExport(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {exportPressed && loadingExport && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
      {hidePredictions && (
        <View style={{ width: "80%", padding: "5%" }}>
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_600SemiBold",
              paddingTop: "5%",
            }}
          >
            Hide Predictions
          </Text>
          <View
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              source={require("../assets/HidePredictions_Help.png")}
              onLoadEnd={() => setLoadingHide(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {hidePredictions && loadingHide && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
      {predictionsPressed && (
        <View style={{ width: "80%", padding: "5%" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                color: "#505456",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
                paddingTop: "5%",
              }}
            >
              Predictions
            </Text>
            <Text
              style={{
                color: colors.redError,
                fontSize: 16,
                fontFamily: "Inter_500Medium",
                paddingTop: "5%",
                paddingLeft: 10,
              }}
            >
              *Predictions are only available for Premium users
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              source={require("../assets/Predictions_Help.png")}
              onLoadEnd={() => setLoadingPredictions(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {predictionsPressed && loadingPredictions && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
      {powerLabelPressed && (
        <View style={{ width: "80%", padding: "5%" }}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                color: "#505456",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
                paddingTop: "5%",
              }}
            >
              Power Label
            </Text>
          </View>
          <View
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <Image
              source={require("../assets/PowerLabel_Help.png")}
              onLoadEnd={() => setLoadingPowerLabel(false)}
              resizeMode={"contain"}
              style={{
                width: "100%",
                zIndex: 2,
                height: "100%",
              }}
            />
          </View>
        </View>
      )}
      {powerLabelPressed && loadingPowerLabel && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color="#2859ff" />
        </View>
      )}
    </View>
  );
}
