import React, { useState, useEffect, useRef } from "react";
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Alert,
} from "react-native";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButton";
import LottieView from "react-native-web-lottie";
import { doc, collection, setDoc } from "firebase/firestore";
import { db } from "../References/firebase.js";

var validator = require("email-validator");

//create captcha
const generateCaptcha = () => {
  const randomNumber1 = Math.floor(Math.random() * 100) + 1;
  const randomNumber2 = Math.floor(Math.random() * 10) + 1;
  return `${randomNumber1} + ${randomNumber2}`;
};

export default function EmailSignup(props) {
  const [emailValue, setEmailValue] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [successfullySubmittedEmail, setSuccessfullySubmittedEmail] =
    useState(false);
  const [captcha, setCaptcha] = useState(generateCaptcha()); //captcha puzzle
  const [userInput, setUserInput] = useState(""); //user captcha answer
  const [showCaptcha, setShowCaptcha] = useState(false);

  //verify captcha answer
  const checkCaptcha = () => {
    const captchaNumbers = captcha.split(" + ");
    const correctAnswer =
      parseInt(captchaNumbers[0]) + parseInt(captchaNumbers[1]);

    if (parseInt(userInput) === correctAnswer) {
      submitEmail(emailValue);
    } else {
      alert("Incorrect answer, please try again");
    }

    setCaptcha(generateCaptcha());
    setUserInput("");
  };

  //used in lottie success animation
  const emailSignUpAnimation = useRef(null);

  useEffect(() => {
    validator.validate(emailValue) ? setValidEmail(true) : setValidEmail(false);
  }, [emailValue]);

  const submitEmail = async (emailValue) => {
    setSuccessfullySubmittedEmail(true);
    await setDoc(doc(db, "Newsletter_SignUp_Emails", emailValue), {
      emailValue: emailValue,
    });
    setEmailValue(false);
    setValidEmail(false);
    setShowCaptcha(false);
  };

  return (
    <View
      style={{
        height: 400,
        width: 700,
        backgroundColor: "white",
        borderRadius: 25,
        padding: 20,
      }}
    >
      {successfullySubmittedEmail ? (
        <LottieView
          ref={emailSignUpAnimation}
          style={{
            width: 250,
            height: 250,
            alignSelf: "center",
          }}
          speed={1}
          autoPlay={true}
          loop={false}
          onAnimationFinish={() => props.setShowSignUp(false)}
          source={require("../assets/Checkmark.json")}
        />
      ) : (
        <View>
          {!showCaptcha && (
            <View>
              <Text
                style={{
                  textAlign: "center",
                  letterSpacing: -2,
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 30,
                  paddingTop: "10%",
                }}
              >
                Stay up-to-date
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#505456",
                  fontFamily: "Inter_400Regular",
                  fontSize: 16,
                  paddingTop: "5%",
                }}
              >
                Keep up with the latest Boltlabel developments
              </Text>
              <TextInput
                style={{
                  height: 40,
                  width: "50%",
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: "grey",
                  padding: 10,
                  alignSelf: "center",
                  marginTop: 40,
                }}
                autoFocus
                placeholder={"Email"}
                onChangeText={(text) => setEmailValue(text)}
                value={emailValue}
                onKeyPress={(e) =>
                  e.key == "Enter" &&
                  emailValue != "" &&
                  validEmail &&
                  setShowCaptcha(true)
                }
              />
            </View>
          )}
          {showCaptcha && (
            <View>
              <Text
                style={{
                  textAlign: "center",
                  letterSpacing: -2,
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 30,
                  paddingTop: "10%",
                }}
              >
                Captcha
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#505456",
                  fontFamily: "Inter_400Regular",
                  fontSize: 16,
                  paddingTop: "3%",
                }}
              >
                Confirm you're human 🤖 Answer this puzzle
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 25,
                  paddingTop: "3%",
                }}
              >
                {captcha}
              </Text>
              <TextInput
                style={{
                  height: 40,
                  width: "50%",
                  borderWidth: 1,
                  borderRadius: 10,
                  borderColor: "grey",
                  padding: 10,
                  alignSelf: "center",
                  marginTop: "3%",
                }}
                autoFocus
                value={userInput}
                placeholder={"Answer..."}
                onChangeText={setUserInput}
                keyboardType="number-pad"
                onKeyPress={(e) => e.key == "Enter" && checkCaptcha()}
              />
            </View>
          )}
        </View>
      )}
      {successfullySubmittedEmail ? (
        <Text
          style={{
            textAlign: "center",
            fontFamily: "Inter_400Regular",
            fontSize: 18,
            paddingTop: "5%",
          }}
        >
          Thanks for signing up for email updates!
        </Text>
      ) : (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            position: "absolute",
            bottom: 50,
            alignSelf: "center",
          }}
        >
          <SecondaryButton
            disabled={false}
            onPress={() => (
              props.setShowSignUp(false),
              setEmailValue(false),
              setValidEmail(false),
              setShowCaptcha(false)
            )}
            text={"Cancel"}
            showLoading={false}
          />
          <View style={{ width: "5%" }} />
          <MainButton
            disabled={false}
            onPress={() =>
              showCaptcha
                ? checkCaptcha()
                : validEmail
                ? setShowCaptcha(true)
                : alert("Please enter a valid email")
            }
            text={"Submit"}
            showLoading={false}
          />
        </View>
      )}
    </View>
  );
}
