export const Medicine_mask_detection = [
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask1.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask2.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask2.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask3.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask21.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask4.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask4.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask15.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask5.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask12.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask14.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask18.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask10.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask12.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask5.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask13.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask15.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask14.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask16.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask16.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask1.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask17.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask22.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask18.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask17.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask19.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask19.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask20.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask20.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/mask22.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Medicine_mask_detection/no_mask21.jpg"),
  },
];
