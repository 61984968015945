import React, { useState, useEffect, useRef, useContext } from "react";
import { colors } from "../Styles/colors.js";
import { Text, View, Dimensions } from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import { auth } from "../References/firebase";
import { AuthContext } from "../References/Auth";
import { deviceType } from "react-device-detect";

export default function Footer(props) {
  const { signInCheck, width, height } = useContext(AuthContext);
  const [premiumStatus, setPremiumStatus] = useState(false);

  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }
  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  const animation = useRef(null);

  if (width < 550) {
    return (
      <View //footer
        style={{
          height: height * 0.15,
          width: width,
          backgroundColor: colors.greySidebar,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "Inter_500Medium",
              fontSize: 12,
              color: colors.greyLightFont,
            }}
          >
            Made with ☕️ in NYC
          </Text>
        </View>
      </View>
    );
  }
  if (width < 900) {
    return (
      <View //footer
        style={{
          height: height * 0.15,
          width: width,
          backgroundColor: colors.greySidebar,
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            height: "100%",
            width: "25%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "Inter_500Medium",
              fontSize: 12,

              color: colors.greyLightFont,
            }}
          >
            Made with ☕️ in NYC by Marcus
          </Text>
        </View>
        <View style={{ height: "100%", width: "13%" }} />
        <View
          style={{
            height: "100%",
            width: "43%",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {signInCheck == "signed_out" && deviceType == "browser" && (
            <Pressable
              onPress={() => props.goTo("Demo")}
              style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 12,
                  color: colors.greyLightFont,
                }}
              >
                Demo
              </Text>
            </Pressable>
          )}
          {signInCheck == "signed_in" && deviceType == "browser" && (
            <Pressable
              onPress={() => props.goTo("Editor")}
              style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 12,
                  color: colors.greyLightFont,
                }}
              >
                Editor
              </Text>
            </Pressable>
          )}
          <Pressable
            onPress={() => props.goTo("About")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 12,
                color: colors.greyLightFont,
              }}
            >
              About
            </Text>
          </Pressable>
          <Pressable
            onPress={() => props.goTo("Privacy")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 12,
                color: colors.greyLightFont,
              }}
            >
              Privacy
            </Text>
          </Pressable>
          <Pressable
            onPress={() => props.goTo("Pricing")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 12,
                color: colors.greyLightFont,
              }}
            >
              Pricing
            </Text>
          </Pressable>
          <Pressable
            onPress={() => props.goTo("TermsConditions")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 12,
                color: colors.greyLightFont,
              }}
            >
              Terms & Conditions
            </Text>
          </Pressable>
        </View>
        <View style={{ height: "100%", width: "5%" }} />
      </View>
    );
  } else {
    return (
      <View //footer
        style={{
          height: height * 0.15,
          width: width,
          backgroundColor: colors.greySidebar,
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            height: "100%",
            width: "25%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontFamily: "Inter_500Medium",
              fontSize: 15,

              color: colors.greyLightFont,
            }}
          >
            Made with ☕️ in NYC
          </Text>
        </View>
        <View style={{ height: "100%", width: "35%" }} />
        <View
          style={{
            height: "100%",
            width: "30%",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            paddingRight: "5%",
          }}
        >
          {signInCheck == "signed_out" && deviceType == "browser" && (
            <Pressable
              onPress={() => props.goTo("Demo")}
              style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: colors.greyLightFont,
                }}
              >
                Demo
              </Text>
            </Pressable>
          )}
          {/*  {signInCheck == "signed_in" && deviceType == "browser" && (
            <Pressable
              onPress={() => props.goTo("Editor")}
              style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: colors.greyLightFont,
                }}
              >
                Editor
              </Text>
            </Pressable>
          )} */}
          <Pressable
            onPress={() => props.goTo("About")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 15,
                color: colors.greyLightFont,
              }}
            >
              About
            </Text>
          </Pressable>
          <Pressable
            onPress={() => props.goTo("Privacy")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 15,
                color: colors.greyLightFont,
              }}
            >
              Privacy
            </Text>
          </Pressable>
          {/* <Pressable
            onPress={() => props.goTo("Pricing")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 15,
                color: colors.greyLightFont,
              }}
            >
              Pricing
            </Text>
          </Pressable> */}
          <Pressable
            onPress={() => props.goTo("TermsConditions")}
            style={({ hovered }) => [{ borderRadius: 50, padding: 10 }]}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 15,
                color: colors.greyLightFont,
              }}
            >
              Terms & Conditions
            </Text>
          </Pressable>
        </View>
        <View style={{ height: "100%", width: "5%" }} />
      </View>
    );
  }
}
