export const Nature_weather = [
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow1.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain1.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain13.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning1.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow2.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain2.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain3.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning2.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow3.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow4.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning3.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain4.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow5.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning4.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning5.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning6.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain5.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow6.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain6.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain7.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow7.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning8.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow8.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain8.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow9.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain9.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain10.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning9.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow10.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning11.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow12.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow11.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain11.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain12.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning12.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow13.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain14.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning13.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow14.jpg") },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Nature_weather/lightning15.jpg"),
  },
  { label: "Unlabeled", image: require("../Images/Nature_weather/snow15.jpg") },
  { label: "Unlabeled", image: require("../Images/Nature_weather/rain15.jpg") },
];
