import {
  collection,
  addDoc,
  onSnapshot,
  getDoc,
  doc,
} from "firebase/firestore";
import { db, auth } from "../References/firebase.js";
import * as WebBrowser from "expo-web-browser";
export async function createCheckoutSession(uid, priceID, success, cancel) {
  //Create a new checkout session in the subcollection inside this users document

  const docRef = await addDoc(
    collection(db, `users/${uid}/checkout_sessions`),
    {
      price: priceID,
      success_url: "https://boltlabel.com/subscribed",
      cancel_url: "https://boltlabel.com/home",
    }
  );

  // Wait for the CheckoutSession to get attached by the extension
  onSnapshot(docRef, async (snap) => {
    const { url } = await snap.data();
    console.log(url);
    if (url) {
      WebBrowser.openBrowserAsync(url);
    }
  });
}
