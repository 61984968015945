//sign up / sign in

import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import {
  Switch,
  TextInput,
  FlatList,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
  button,
} from "react-native";
import { Input, Overlay } from "react-native-elements";
import * as ImageManipulator from "expo-image-manipulator";
import * as DocumentPicker from "expo-document-picker";
import * as tf from "@tensorflow/tfjs";
import * as mobilenet from "@tensorflow-models/mobilenet";
import * as knnClassifier from "@tensorflow-models/knn-classifier";
import * as jpeg from "jpeg-js";
import * as png from "fast-png";
import AppLoading from "expo-app-loading";
import { Feather, Ionicons } from "@expo/vector-icons";
import { Hoverable, Pressable } from "react-native-web-hover";
import * as Animatable from "react-native-animatable";
import LottieView from "react-native-web-lottie";
import * as ImagePicker from "expo-image-picker";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Slider } from "@sharcoux/slider";
import { Picker } from "@react-native-picker/picker";
import { colors } from "../Styles/colors";
import SignUp_Editor from "../Components/SignUp_Editor";
import LogIn_Editor from "../Components/LogIn_Editor";
import ProfileInfo from "../Components/ProfileInfo";
import HelpInfo from "../Components/HelpInfo";
import { AuthContext } from "../References/Auth";
import { createCheckoutSession } from "../Stripe/createCheckoutSession";
import Annotator from "../Components/annotator";
import { ReactPictureAnnotation } from "react-picture-annotation";
import Dropdown from "../Components/dropdown";

import {
  ENV_ENCR_KEY,
  ENV_POST_SUBSCR,
  ENV_POST_CANCEL_SUBSCR,
  ENV_ST_MO,
} from "@env";

import {
  getAuth,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getIdToken,
  getIdTokenResult,
  onAuthStateChanged,
} from "firebase/auth";

var cryptorjs = require("cryptorjs");
var myCryptor = new cryptorjs(ENV_ENCR_KEY);

const borderColors = ["blue", "green", "orange", "pink", "purple"];
const scalingFactor = 280 / 900; // image display size / actual image size

import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/inter";
import { LinearGradient } from "expo-linear-gradient";
import render from "react-native-web/dist/cjs/exports/render";

export default function ObjectDetection({ navigation }) {
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });

  const [beginPowerLabel, setBeginPowerLabel] = useState(false);
  const [category1, setCategory1] = useState([]);
  const [category2, setCategory2] = useState([]);
  const [category3, setCategory3] = useState([]);
  const [category4, setCategory4] = useState([]);
  const [category5, setCategory5] = useState([]);
  const [category1Count, setCategory1Count] = useState(0);
  const [category2Count, setCategory2Count] = useState(0);
  const [category3Count, setCategory3Count] = useState(0);
  const [category4Count, setCategory4Count] = useState(0);
  const [category5Count, setCategory5Count] = useState(0);
  const [dataset, setDataset] = useState(null);
  const [donePowerLabelCollect, setDonePowerLabelCollect] = useState(false);
  const [duplicateImage, setDuplicateImage] = useState(false);
  const [errorLoadingModel, setErrorLoadingModel] = useState(false);
  const [file, setFile] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [goHome, setGoHome] = useState(false);
  const [goToLabels, setGoToLabels] = useState(false);
  const [hasPermission, setHasPermission] = useState(null);
  const [imageIssue, setImageIssue] = useState(false);
  const [imagesAreUploading, setImagesAreUploading] = useState(false);
  const [imagesSuccessfullyUploaded, setImagesSuccessfullyUploaded] =
    useState(false);
  const [imagesUploaded, setImagesUploaded] = useState(null);
  const [index, setIndex] = useState(0);
  const [isTfReady, setIsTfReady] = useState(false);
  const [isPredicting, setIsPredicting] = useState(false);
  const [knnClassifierModel, setKnnClassifierModel] = useState(null);
  const [loadedModel, setLoadedModel] = useState(false);
  const [mobilenetModel, setMobilenetModel] = useState(null);
  const [detectionModel, setDetectionModel] = useState(null);

  const [noMoreImages, setNoMoreImages] = useState(false);
  const [numberOfFeatures, setNumberOfFeatures] = useState(0);
  const [numberOfImages, setNumberOfImages] = useState(0);
  const [numberOfImagesInModel, setNumberOfImagesInModel] = useState(0);
  const [powerLabelPredictionImages, setPowerLabelPredictionImages] = useState(
    []
  );
  const [powerLabelSelection, setPowerLabelSelection] = useState("All");
  const [prediction, setPrediction] = useState({
    label: "No Results",
    confidence: {},
  });
  const [predictionConfidence, setPredictionConfidence] = useState(0);
  const [pressedPowerLabel, setPressedPowerLabel] = useState(false);
  const [pressedRestart, setPressedRestart] = useState(false);
  const [processingClick, setProcessingClick] = useState(false);
  const ref = useRef(null);

  const [selectingCategories, setSelectingCategories] = useState(true); //set to true
  const [showImagesLoadedSuccess, setShowImagesLoadedSuccess] = useState(false);
  const [showLoadedModelSuccess, setShowLoadedModelSuccess] = useState(false);
  const [showPrediction, setShowPrediction] = useState(true);
  const [startDownload, setStartDownload] = useState(false);
  const [sliderMax, setSliderMax] = useState(0);
  const [sliderValue, setSliderValue] = useState(1);
  const [status, setStatus] = useState("Preparing Model...");
  const [streak, setStreak] = useState(0);
  const [strength, setStrength] = useState("Weak");
  const [tasks, setTasks] = useState([]);
  const refTasks = useRef(tasks);
  const [tasksRow1, setTasksRow1] = useState([]);
  const [tasksRow2, setTasksRow2] = useState([]);
  const [powerLabelChangePicker, setPowerLabelChangePicker] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogIn, setShowLogIn] = useState(false);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const { signInCheck, dimensions } = useContext(AuthContext);
  const [finishedPowerLabel, setFinishedPowerLabel] = useState(false);
  const [showingCheckout, setShowingCheckout] = useState(false);
  const [showingCheckoutInfo, setShowingCheckoutInfo] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const auth = getAuth();
  const addTask = (task) => {
    if (task == null) return;
    setTasks([...tasks, task]);
  };
  const window = Dimensions.get("window");
  const refNumberImages = useRef(numberOfImagesInModel);
  const [startedReVerify, setStartedReVerify] = useState(false);
  const [passwordText1, setPasswordText1] = useState("");
  const [finishedVerification, setFinishedVerification] = useState(false);
  const [disabledLoadModel, setDisabledLoadModel] = useState(false);
  const [disabledPowerLabel, setDisabledPowerLabel] = useState(false);
  const [freeInfo, setFreeInfo] = useState(false);
  const [notLoggedIn, setNotLoggedIn] = useState(false);
  const [imagesForModel, setImagesForModel] = useState(null);
  const [powerLabelError, setPowerLabelError] = useState(null);
  const [premiumStatus, setPremiumStatus] = useState(false);
  const [confidenceThreshold, setConfidenceThreshold] = useState(50);
  const [imageToAnalyze, setImageToAnalyze] = useState(null);
  const [predictions, setPredictions] = useState(null);

  const [labeled, setLabeled] = useState(0);
  const [labeled1, setLabeled1] = useState(0);
  const [labeled2, setLabeled2] = useState(0);
  const [labeled3, setLabeled3] = useState(0);
  const [labeled4, setLabeled4] = useState(0);
  const [labeled5, setLabeled5] = useState(0);

  const [boxHeight, setBoxHeight] = useState(0);
  const [boxWidth, setBoxWidth] = useState(0);
  const [boxX, setBoxX] = useState(0);
  const [boxY, setBoxY] = useState(0);

  useEffect(() => {
    refNumberImages.current = numberOfImagesInModel;
    refTasks.current = tasks;
  });

  //calculate embedding similarity with each label press
  useEffect(() => {
    console.log("run embedding");
    console.log("x: ", boxX);
    console.log("y: ", boxY);
    console.log("w: ", boxWidth);
    console.log("h: ", boxHeight);

    async function getCroppedEmbeddings() {
      const response = await fetch(
        imagesForModel[0].image,
        {},
        { isBinary: true }
      );
      const rawImageData = await response.arrayBuffer();

      //get full image tensor
      const imageTensor = imageToTensor(rawImageData);

      // Crop and resize the image to a specific size (e.g. 224x224)
      const croppedTensor = tf.image.cropAndResize(
        imageTensor.expandDims(),
        [
          [
            boxY / 500, //img height
            boxX / 700, //img width
            (boxY + boxHeight) / 500, //img height
            (boxX + boxWidth) / 700, //img width
          ],
        ],
        [0],
        [224, 224]
      );

      // Normalize the pixel values to be between -1 and 1
      const normalizedTensor = croppedTensor.div(127.5).sub(1);

      // Get the embeddings from the pre-trained MobileNet model
      const embeddings1 = await mobilenetModel.infer(normalizedTensor);
      const embeddingData = await embeddings1.data();

      // Do something with the embeddings
      console.log("1st Embeddings:", embeddings1);

      // Get the object proposals for the image
      const detectionResults = await detectionModel.detect(imageTensor);
      const proposals = detectionResults.map((result) => {
        [
          11.558215618133545, 0.7157886028289795, 59.60574388504028,
          116.56074643135071,
        ];
        return {
          bbox: [
            result.bbox[0],
            result.bbox[1],
            result.bbox[2],
            result.bbox[3],
          ],
          score: result.score,
        };
      });
      console.log("check 1");
      console.log("proposals: ", proposals);
      // Compute the embeddings for each proposal in the second image
      const embeddings2 = tf.tensor([], [proposals.length, 128]);
      console.log("embeddings2: ", embeddings2);

      for (let i = 0; i < proposals.length; i++) {
        // Crop the image patch
        const bbox2 = proposals[i].bbox;
        const img2Crop = tf.image.cropAndResize(
          imageTensor.expandDims(),
          [bbox2],
          [0],
          [224, 224]
        );
        // Get the embeddings for the cropped region of the second image
        embeddings2[i] = await mobilenetModel.infer(img2Crop);
      }
      // Compute the similarity between the embeddings for the first image and each proposal in the second image
      const similarities = tf.linalg
        .dot(embeddings1, embeddings2.transpose())
        .dataSync();

      // Find the proposal with the highest similarity score
      let maxSim = -1;
      let maxSimIndex = -1;
      for (let i = 0; i < proposals.length; i++) {
        if (similarities[i] > maxSim) {
          maxSim = similarities[i];
          maxSimIndex = i;
        }
      }
      // Return the image patch and bounding box corresponding to the proposal with the highest similarity score
      const bbox3 = proposals[maxSimIndex].bbox;
      const img2Crop2 = tf.image.cropAndResize(
        imageTensor.expandDims(),
        [bbox3],
        [0],
        [224, 224]
      );
      const result = {
        image: img2Crop2,
        bbox: bbox3,
        score: proposals[maxSimIndex].score,
      };
      console.log("final result of proposed area: ", result);
    }
    boxX + boxY + boxWidth + boxHeight != 0 && getCroppedEmbeddings();
  }, [labeled1, labeled2, labeled3, labeled4, labeled5]);

  ///////////////////////////////////////////////////////////
  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }

  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });
  function closeProfile() {
    setShowProfileInfo(false);
  }

  useEffect(() => {
    showProfileInfo && setShowProfileInfo(false);
    showLogIn && setShowLogIn(false);
    showSignUp && setShowSignUp(false);
    // signInCheck == "signed_out" && navigation.navigate("SignUp");
    signInCheck == "signed_out" && setNotLoggedIn(true);
    signInCheck == "signed_in" && setNotLoggedIn(false);
  }, [signInCheck]);

  useEffect(() => {
    powerFilter(powerLabelSelection);
  }, [powerLabelChangePicker]);

  useEffect(() => {
    ref.current?.scrollToIndex({
      index,
      animated: false,
      viewPosition: 0,
    });
  }, [index]);

  const width = dimensions.window.width;
  const height = dimensions.window.height;

  //load tensorflow
  useEffect(() => {
    async function startup() {
      if (!isTfReady) {
        setHasPermission(true);
        await tf.ready();
        setIsTfReady(true);
        setMobilenetModel(await mobilenet.load()); //load classification model
        setKnnClassifierModel(await knnClassifier.create()); //create classifier
      }
    }
    startup();
  }, [isTfReady]);

  Array.prototype.max = function () {
    return Math.max.apply(null, this);
  };
  function base64ImageToTensor(base64) {
    //Function to convert jpeg image to tensors
    const TO_UINT8ARRAY = true;
    const { width, height, data } = jpeg.decode(base64, TO_UINT8ARRAY);
    // Drop the alpha channel info for cocossd
    const buffer = new Uint8Array(width * height * 3);
    let offset = 0; // offset into original data
    for (let i = 0; i < buffer.length; i += 3) {
      buffer[i] = data[offset];
      buffer[i + 1] = data[offset + 1];
      buffer[i + 2] = data[offset + 2];
      offset += 4;
    }
    return tf.tensor3d(buffer, [height, width, 3]);
  }
  const changePicker = (index, newValue) => {
    const newList = powerLabelPredictionImages.map((item) => {
      if (item.index === index) {
        const updatedItem = {
          ...item,
          prediction: newValue,
        };
        return updatedItem;
      }

      return item;
    });
    setPowerLabelPredictionImages(newList);
    setPowerLabelChangePicker(!powerLabelChangePicker);
  };
  const desktopGetPredictions = async (specificImage, tasks) => {
    setStatus("Analysing Photo...");
    try {
      setIsPredicting(true);
      const response = await fetch(specificImage, {}, { isBinary: true });
      const rawImageData = await response.arrayBuffer();
      const imageTensor = imageToTensor(rawImageData);
      const newPredictions = await model.current.detect(imageTensor);
      //let embeddings = await mobilenetModel.infer(imageTensor, true);
      setPredictions(newPredictions);

      setPredictionConfidence(
        (Object.values(prediction.confidences).max() * 100).toFixed(0)
      );
      setNumberOfFeatures(Object.values(prediction.confidences).length);
      setIsPredicting(false);
    } catch (e) {
      setIsPredicting(false);
    }
  };
  const imageToTensor = (rawImageData) => {
    const TO_UINT8ARRAY = true;
    try {
      const { width, height, data } = jpeg.decode(rawImageData, TO_UINT8ARRAY);
      // Drop the alpha channel info for cocossd
      const buffer = new Uint8Array(width * height * 3);
      let offset = 0; // offset into original data
      for (let i = 0; i < buffer.length; i += 3) {
        buffer[i] = data[offset];
        buffer[i + 1] = data[offset + 1];
        buffer[i + 2] = data[offset + 2];
        offset += 4;
      }

      return tf.tensor3d(buffer, [height, width, 3]);
    } catch {
      const { width, height, data } = png.decode(rawImageData, TO_UINT8ARRAY);
      // Drop the alpha channel info for cocossd
      const buffer = new Uint8Array(width * height * 3);
      let offset = 0; // offset into original data
      for (let i = 0; i < buffer.length; i += 3) {
        buffer[i] = data[offset];
        buffer[i + 1] = data[offset + 1];
        buffer[i + 2] = data[offset + 2];
        offset += 4;
      }

      return tf.tensor3d(buffer, [height, width, 3]);
    }
  };
  const loadKnnClassifierModel = async () => {
    setStatus("Loading Model...");
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: "text/plain",
      });
      const base64url = require("base64-url");

      let stringDataset_base64 = result.uri;
      let stringDataset_full = myCryptor.decode(
        base64url.decode(stringDataset_base64).substring(14)
      );

      const loadedStrength = stringDataset_full.slice(
        0,
        stringDataset_full.search(" ")
      );
      const cat1count = stringDataset_full.slice(
        stringDataset_full.search(" ") + 1,
        stringDataset_full.search(";")
      );
      cat1count.length > 0
        ? setCategory1Count(Number(cat1count))
        : setCategory1Count(0);
      const cat2count = stringDataset_full.slice(
        stringDataset_full.search(";") + 1,
        stringDataset_full.search("#")
      );
      cat2count.length > 0
        ? setCategory2Count(Number(cat2count))
        : setCategory2Count(0);
      const cat3count = stringDataset_full.slice(
        stringDataset_full.search("#") + 1,
        stringDataset_full.search("@")
      );
      cat3count.length > 0
        ? setCategory3Count(Number(cat3count))
        : setCategory3Count(0);
      const cat4count = stringDataset_full.slice(
        stringDataset_full.search("@") + 1,
        stringDataset_full.search("!")
      );
      cat4count.length > 0
        ? setCategory4Count(Number(cat4count))
        : setCategory4Count(0);
      const cat5count = stringDataset_full.slice(
        stringDataset_full.search("!") + 1,
        stringDataset_full.search(":")
      );
      cat5count.length > 0
        ? setCategory5Count(Number(cat5count))
        : setCategory5Count(0);

      let stringDataset = stringDataset_full.substring(
        stringDataset_full.search("_") + 1
      );
      let tempModel = knnClassifier.create();
      //(Part 2) 4. Convert dataset format to "JSON" using JSON.parse
      tempModel.setClassifierDataset(
        Object.fromEntries(
          JSON.parse(stringDataset).map(([label, data, shape]) => [
            label,
            tf.tensor(data, shape),
          ])
        )
      );
      const labels = JSON.parse(stringDataset).map(
        ([label, data, shape]) => label
      );
      const shape = JSON.parse(stringDataset).map(
        ([label, data, shape]) => shape
      );
      const shape_list = [];
      for (let i = 0; i < shape.length; i++) {
        shape_list.push(shape[i][0]);
      }
      const modelNumberImages = shape_list.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      setStrength(loadedStrength);
      const labels_list = [];
      for (let i = 0; i < labels.length; i++) {
        labels_list.push(labels[i]);
      }

      setTasks(labels_list);
      //(Part 2) 5. Load model
      setKnnClassifierModel(tempModel);
      setDataset(stringDataset);
      setLoadedModel(true);
      setShowLoadedModelSuccess(true);
    } catch {
      setErrorLoadingModel(true);
    }
  };
  const pickImage = () => {
    ImagePicker.launchImageLibraryAsync({
      allowsMultipleSelection: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      orderedSelection: true,
      aspect: [4, 3],
      quality: 1,
    }).then((result) => {
      const array = [];
      const imagesList = [];
      if (imagesUploaded == null) {
        const imagesList = [];
      } else {
        for (var i = 0; i < numberOfImages.length; i++) {
          imagesList.push(numberOfImages[i].image);
        }
      }

      for (var i = 0; i < result.selected.length; i++) {
        const object = {};
        object.index = i;
        object.image = result.selected[i].uri;
        imagesUploaded != null && imagesList.includes(result.selected[i].uri)
          ? (setDuplicateImage(true),
            setTimeout(() => {
              setDuplicateImage(false);
            }, 4000))
          : array.push(object);
      }

      if (!result.cancelled) {
        const oldImages =
          imagesUploaded == null ? array : numberOfImages.concat(array);
        const newImages =
          imagesUploaded == null
            ? array
            : numberOfImages.length - numberOfImagesInModel == 0
            ? array
            : imagesUploaded.concat(array);
        const modelImages =
          imagesForModel == null
            ? array
            : numberOfImages.length - numberOfImagesInModel == 0
            ? array
            : imagesForModel.concat(array);
        imagesUploaded != null &&
        numberOfImages.length - numberOfImagesInModel == 0
          ? setIndex(0)
          : setIndex(index);
        setImagesUploaded(newImages); //track this number so list does not go out of index
        setImagesForModel(modelImages); //used in model (needs to be separate so Power Label functions)
        setNumberOfImages(oldImages); //used in metric tracking
        setPrediction({
          label: "No Results",
          confidence: {},
        });
        setPredictionConfidence(0);
        numberOfImages.length - numberOfImagesInModel == 0 &&
          setSliderMax(numberOfImages.length - numberOfImagesInModel);
        setImagesSuccessfullyUploaded(true);
        setShowImagesLoadedSuccess(true);
        setNoMoreImages(false);
      }
    });
  };
  const powerLabelCollectData = async (powerLabelPrediction_Output) => {
    try {
      const array = [];
      setIsPredicting(true);
      const label1List = [];
      const label2List = [];
      const label3List = [];
      const label4List = [];
      const label5List = [];

      for (let i = 0; i < powerLabelPrediction_Output.length; i++) {
        try {
          const imageFromList = powerLabelPrediction_Output[i].image;
          const className = powerLabelPrediction_Output[i].prediction;

          const response = await fetch(imageFromList, {}, { isBinary: true });
          const rawImageData = await response.arrayBuffer();
          const imageTensor = imageToTensor(rawImageData);

          let embeddings = await mobilenetModel.infer(imageTensor, true); //get embeddings from cocossd
          knnClassifierModel.addExample(embeddings, className); //train knn classifier
          className == tasks[0] && label1List.push(className);
          className == tasks[1] && label2List.push(className);
          className == tasks[2] && label3List.push(className);
          className == tasks[3] && label4List.push(className);
          className == tasks[4] && label5List.push(className);

          className == tasks[0] &&
            (setCategory1((prevState) => [...prevState, imageFromList]),
            setCategory1Count(category1Count + label1List.length));

          className == tasks[1] &&
            (setCategory2((prevState) => [...prevState, imageFromList]),
            setCategory2Count(category2Count + label2List.length));

          className == tasks[2] &&
            (setCategory3((prevState) => [...prevState, imageFromList]),
            setCategory3Count(category3Count + label3List.length));

          className == tasks[3] &&
            (setCategory4((prevState) => [...prevState, imageFromList]),
            setCategory4Count(category4Count + label4List.length));

          className == tasks[4] &&
            (setCategory5((prevState) => [...prevState, imageFromList]),
            setCategory5Count(category5Count + label5List.length));
        } catch (e) {}
      }
      const newImages = imagesForModel.slice(
        index + sliderValue,
        imagesForModel.length
      );
      setImagesForModel(newImages);
      setNumberOfImagesInModel(refNumberImages.current + sliderValue);
      setIndex(0);
      setTimeout(() => {
        setSliderValue(1);
      }, 250);
      setTimeout(() => {
        numberOfImages.length - numberOfImagesInModel > 0 &&
          desktopGetPredictions(imagesUploaded[index].image, tasks);
      }, 500);
      setDonePowerLabelCollect(true);
    } catch (e) {}
  };
  const powerLabelPredictions = async (image_list) => {
    const array = [];
    const powerLabelErrorList = [];
    !donePowerLabelCollect && setIsPredicting(true);
    try {
      for (let i = 0; i < image_list.length; i++) {
        try {
          const imageFromList = image_list[i].image;
          const response = await fetch(imageFromList, {}, { isBinary: true });
          const rawImageData = await response.arrayBuffer();
          const imageTensor = await imageToTensor(rawImageData);
          //get embeddings from cocossd
          let embeddings = await mobilenetModel.infer(imageTensor, true);
          //predict with knn classifier
          let prediction = await knnClassifierModel.predictClass(embeddings);
          const object = {};
          object.index = i;
          object.image = image_list[i].image;
          object.prediction = prediction.label;
          array.push(object);
          setPowerLabelPredictionImages(array);
        } catch (e) {
          const object = {};
          object.index = i;
          object.image = image_list[i].image;
          object.prediction = "Image Issue";
          array.push(object);
          powerLabelErrorList.push(prediction.label);
          setPowerLabelError(powerLabelErrorList);
          setPowerLabelPredictionImages(array);
        }
      }
    } catch (e) {}
    !donePowerLabelCollect && setIsPredicting(false);
  };
  const pressedBegin = () => {
    setNumberOfImages(numberOfImages);
    setSelectingCategories(false);
    setGoToLabels(false);
  };
  const resetKnnClassifierModel = async () => {
    console.log("[+] Resetting Model");
    setSelectingCategories(true);
    setImagesSuccessfullyUploaded(false);
    setIndex(0);
    setFinishedPowerLabel(false);
    setNoMoreImages(false);
    setSliderValue(0);
    setNumberOfImagesInModel(0);
    setStreak(0);
    setImagesForModel(null);
    setStrength("Weak");
    setNumberOfFeatures(0);
    setPressedRestart(false);
    setNumberOfImages(0);
    setTasks([]);
    setImagesUploaded();
    setCategory1([]);
    setCategory2([]);
    setCategory3([]);
    setCategory4([]);
    setCategory5([]);
    setCategory1Count(0);
    setCategory2Count(0);
    setCategory3Count(0);
    setCategory4Count(0);
    setCategory5Count(0);

    try {
      await knnClassifierModel.clearAllClasses();
    } catch {
      console.log("Unable to reset model");
    }
  };
  async function resizeImage(imageUrl, width, height) {
    const actions = [
      {
        resize: {
          width,
          height,
        },
      },
    ];
    const saveOptions = {
      compress: 0.75,
      format: ImageManipulator.SaveFormat.JPEG,
      base64: true,
    };
    const res = await ImageManipulator.manipulateAsync(
      imageUrl,
      actions,
      saveOptions
    );
    return res;
  }
  const saveKnnClassifierModel = async () => {
    try {
      setStartDownload(true);
      //Need to first convert uri to blob
      function dataURItoBlob(dataURI) {
        // Convert Base64 to raw binary data held in a string.

        var byteString = atob(dataURI.split(",")[1]);

        // Separate the MIME component.
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // Write the bytes of the string to an ArrayBuffer.
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        // Write the ArrayBuffer to a BLOB and you're done.
        var bb = new Blob([ab]);

        return bb;
      }

      //Then Zip
      const zip = new JSZip();

      const modelFolder = premiumStatus == "premium" && zip.folder("Amp_Model");
      const remainderFolder =
        numberOfImages.length - numberOfImagesInModel > 0
          ? zip.folder("Unlabeled_images")
          : "not used";
      const folder1 = zip.folder(tasks[0] + "_images");
      const folder2 = zip.folder(tasks[1] + "_images");
      const folder3 =
        tasks.length > 2 ? zip.folder(tasks[2] + "_images") : "not used";
      const folder4 =
        tasks.length > 3 ? zip.folder(tasks[3] + "_images") : "not used";
      const folder5 =
        tasks.length > 4 ? zip.folder(tasks[4] + "_images") : "not used";

      let dataset = knnClassifierModel.getClassifierDataset();
      let stringDataset = JSON.stringify(
        Object.entries(dataset).map(([label, data]) => [
          label,
          Array.from(data.dataSync()),
          data.shape,
        ])
      );
      setDataset(stringDataset);

      const countOfCat1 = category1.length > 0 ? category1.length + ";" : "";
      const countOfCat2 = category2.length > 0 ? category2.length + "#" : "";
      const countOfCat3 = category3.length > 0 ? category3.length + "@" : "";
      const countOfCat4 = category4.length > 0 ? category4.length + "!" : "";
      const countOfCat5 = category5.length > 0 ? category5.length + ":" : "";

      {
        premiumStatus == "premium" &&
          modelFolder.file(
            "Amp_Model.txt",
            myCryptor.encode(
              strength +
                " " +
                countOfCat1 +
                countOfCat2 +
                countOfCat3 +
                countOfCat4 +
                countOfCat5 +
                "_" +
                stringDataset
            )
          );
      }

      for (var i = index; i < numberOfImages.length; i++) {
        numberOfImages.length - numberOfImagesInModel > 0 &&
          remainderFolder.file(
            "Unlabeled_" + i + ".jpg",
            dataURItoBlob(numberOfImages[i].image)
          );
      }
      for (var i = 0; i < category1.length; i++) {
        folder1.file(tasks[0] + "_" + i + ".jpg", dataURItoBlob(category1[i]));
      }

      for (var i = 0; i < category2.length; i++) {
        folder2.file(tasks[1] + "_" + i + ".jpg", dataURItoBlob(category2[i]));
      }

      for (var i = 0; i < category3.length; i++) {
        folder3.file(tasks[2] + "_" + i + ".jpg", dataURItoBlob(category3[i]));
      }

      for (var i = 0; i < category4.length; i++) {
        folder4.file(tasks[3] + "_" + i + ".jpg", dataURItoBlob(category4[i]));
      }

      for (var i = 0; i < category5.length; i++) {
        folder5.file(tasks[4] + "_" + i + ".jpg", dataURItoBlob(category5[i]));
      }

      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "Amp_Classification.zip");
      });
      setTimeout(() => {
        setStartDownload(false);
      }, 2000);
    } catch (e) {
      setTimeout(() => {
        setStartDownload(false);
      }, 2000);
    }
  };
  const getLabelCount = (data, labels) => {
    const array = [];
    for (let i = 0; i < data.length; i++) {
      data[i].comment && array.push(data[i].comment);
    }
    setLabeled1(array.filter((c) => c === labels[0]).length);
    setLabeled2(array.filter((c) => c === labels[1]).length);
    setLabeled3(array.filter((c) => c === labels[2]).length);
    setLabeled4(array.filter((c) => c === labels[3]).length);
    setLabeled5(array.filter((c) => c === labels[4]).length);
  };
  const getBoxStats = (data) => {
    setBoxHeight(data[data.length - 1]?.mark?.height);
    setBoxWidth(data[data.length - 1]?.mark?.width);
    setBoxX(data[data.length - 1]?.mark?.x);
    setBoxY(data[data.length - 1]?.mark?.y);
  };
  const Item = ({ image }) => (
    <Annotator
      width={700}
      height={500}
      labels={refTasks.current}
      image={image}
      getLabelCount={getLabelCount}
      getBoxStats={getBoxStats}
    />
  );
  const renderItem = ({ item }) => (
    <View style={{ height: 500, width: 600 }}>
      {predictions &&
        predictions.map((p, index) => {
          return (
            <View
              key={index}
              style={{
                zIndex: 1,
                elevation: 1,
                left: p.bbox[0] * scalingFactor,
                top: p.bbox[1] * scalingFactor,
                width: p.bbox[2] * scalingFactor,
                height: p.bbox[3] * scalingFactor,
                borderWidth: 2,
                borderColor: borderColors[index % 5],
                backgroundColor: "red",
                position: "absolute",
              }}
            />
          );
        })}
      <Item image={item.image} />
    </View>
  );
  const PowerLabelItem = ({ imageDetail, prediction, powerIndex }) => (
    <View>
      <Image
        style={{ height: 200, width: 200, borderRadius: 5, marginBottom: 5 }}
        source={{ uri: imageDetail }}
      />
      <Picker
        selectedValue={prediction}
        style={{
          color:
            prediction == tasks[0]
              ? colors.greenLabelBorder
              : prediction == tasks[1]
              ? colors.redLabelBorder
              : prediction == tasks[2]
              ? colors.blueLabelBorder
              : prediction == tasks[3]
              ? colors.purpleLabelBorder
              : prediction == tasks[4]
              ? colors.orangeLabelBorder
              : "black",
          textAlign: "center",
          fontFamily: "Inter_700Bold",
          borderColor:
            prediction == tasks[0]
              ? colors.greenLabel
              : prediction == tasks[1]
              ? colors.redLabel
              : prediction == tasks[2]
              ? colors.blueLabel
              : prediction == tasks[3]
              ? colors.purpleLabel
              : prediction == tasks[4]
              ? colors.orangeLabel
              : "black",
          backgroundColor:
            prediction == tasks[0]
              ? colors.greenLabel
              : prediction == tasks[1]
              ? colors.redLabel
              : prediction == tasks[2]
              ? colors.blueLabel
              : prediction == tasks[3]
              ? colors.purpleLabel
              : prediction == tasks[4]
              ? colors.orangeLabel
              : "transparent",
          borderRadius: 5,
        }}
        onValueChange={(itemValue) => changePicker(powerIndex, itemValue)}
      >
        <Picker.Item
          label={tasks[0]}
          value={tasks[0]}
          fontFamily={"Inter_500Medium"}
        />
        <Picker.Item
          label={tasks[1]}
          value={tasks[1]}
          fontFamily={"Inter_500Medium"}
        />
        <Picker.Item
          label={"Image Issue"}
          value={"Image Issue"}
          enabled={false}
        />
        {tasks.length > 2 && (
          <Picker.Item
            label={tasks[2]}
            value={tasks[2]}
            fontFamily={"Inter_500Medium"}
          />
        )}
        {tasks.length > 3 && (
          <Picker.Item
            label={tasks[3]}
            value={tasks[3]}
            fontFamily={"Inter_500Medium"}
          />
        )}
        {tasks.length > 4 && (
          <Picker.Item
            label={tasks[4]}
            value={tasks[4]}
            fontFamily={"Inter_500Medium"}
          />
        )}
      </Picker>
    </View>
  );
  const memorizedValue = useMemo(() => renderItem, [imagesForModel]);

  const renderPowerLabelItem = ({ item }) => (
    <View
      style={{ margin: 10, alignItems: "center", justifyContent: "center" }}
    >
      <PowerLabelItem
        imageDetail={item.image}
        prediction={item.prediction}
        powerIndex={item.index}
      />
    </View>
  );
  const finishPowerLabel = () => {
    const array = [];
    for (let i = 0; i < powerLabelPredictionImages.length; i++) {
      array.push(powerLabelPredictionImages[i].prediction);
    }

    array.includes("Image Issue")
      ? setImageIssue(true)
      : (powerLabelCollectData(powerLabelPredictionImages),
        setPowerLabelError(null));
    setPowerLabelSelection("All");
    setFilteredData([]);
    setFinishedPowerLabel(true);
  };

  useEffect(() => {
    tasks.length < 2 && setTasksRow1(tasks.slice(0, 2));
    tasks.length > 2 && setTasksRow1(tasks.slice(0, 3));
    tasks.length == 4 && setTasksRow2(tasks[3]);
    tasks.length == 5 && setTasksRow2(tasks.slice(4, 5));
  }, [tasks]);
  const deleteTask = (deleteIndex) => {
    setTasks(tasks.filter((value, index) => index != deleteIndex));
  };
  const animation = useRef(null);

  const desktopGetPredictions_Button = async (specificImage, tasks) => {
    console.log("[+] Analysing Photo");
    setStatus("Analysing Photo...");
    try {
      setIsPredicting(true);
      const response = await fetch(specificImage, {}, { isBinary: true });
      const rawImageData = await response.arrayBuffer();
      const imageTensor = imageToTensor(rawImageData);
      //get embeddings from cocossd
      let embeddings = await mobilenetModel.infer(imageTensor, true);
      //predict with knn classifier
      let prediction = await knnClassifierModel.predictClass(embeddings);
      //    console.log("[+] Prediction: ",JSON.stringify(prediction))
      setPrediction(prediction);

      setPredictionConfidence(
        (Object.values(prediction.confidences).max() * 100).toFixed(0)
      );
      setNumberOfFeatures(Object.values(prediction.confidences).length);
      setIsPredicting(false);
    } catch (e) {
      console.log("[-] No Camera", e);
      setIsPredicting(false);
    }
    console.log("[+] Photo Analysed");
  };
  const desktopCollectData = async (className, specificImage) => {
    try {
      console.log(`[+] Class ${className} selected`);
      setStatus("Training Model...");

      const response = await fetch(specificImage, {}, { isBinary: true });
      const rawImageData = await response.arrayBuffer();
      const imageTensor = imageToTensor(rawImageData);

      let embeddings = await mobilenetModel.infer(imageTensor, true); //get embeddings from cocossd
      knnClassifierModel.addExample(embeddings, className); //train knn classifier
      //      updateCount(knnClassifierModel)
      console.log("[+] Class Added");
    } catch (e) {
      console.log("[-] No Camera", e);
    }
  };
  const pressLabel = (props) => {
    setProcessingClick(true);
    numberOfImages.length - numberOfImagesInModel != 1 && setIndex(index + 1);
    numberOfImages.length - numberOfImagesInModel == 1 && setNoMoreImages(true);

    setNumberOfImagesInModel(numberOfImagesInModel + 1);

    desktopCollectData(props.task, props.uploadedImages[index].image);
    numberOfImages.length - numberOfImagesInModel != 1 &&
      desktopGetPredictions_Button(
        props.uploadedImages[index + 1].image,
        props.alltasks
      );
    //!collectingData &&
    setTimeout(() => {
      setProcessingClick(false);
    }, 100);
    setFinishedPowerLabel(false);
  };
  const TaskItem = (props) => {
    return (
      <View style={{ width: "100%", flexDirection: "row" }}>
        <View
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: 5,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
            borderWidth: 1,
            borderColor:
              props.index == 1
                ? colors.greenLabelBorder
                : props.index == 2
                ? colors.redLabelBorder
                : props.index == 3
                ? colors.blueLabelBorder
                : props.index == 4
                ? colors.purpleLabelBorder
                : colors.orangeLabelBorder,
            shadowColor: "black",
            shadowOpacity: 0, //was .1
            shadowRadius: 5,
            shadowOffset: {
              height: 1,
              width: 1,
            },
            paddingHorizontal: 10,
            paddingVertical: 5,
            minHeight: 50,
          }}
        >
          <Text
            style={{
              color:
                props.index == 1
                  ? colors.greenLabelBorder
                  : props.index == 2
                  ? colors.redLabelBorder
                  : props.index == 3
                  ? colors.blueLabelBorder
                  : props.index == 4
                  ? colors.purpleLabelBorder
                  : colors.orangeLabelBorder,
              width: "90%",
              fontFamily: "Inter_600SemiBold",
              fontSize: 16,
            }}
          >
            {props.task}
          </Text>
          <TouchableOpacity onPress={() => props.deleteTask()}>
            <Feather
              style={{ marginLeft: 10 }}
              name="trash-2"
              size={18}
              color={
                props.index == 1
                  ? colors.greenLabelBorder
                  : props.index == 2
                  ? colors.redLabelBorder
                  : props.index == 3
                  ? colors.blueLabelBorder
                  : props.index == 4
                  ? colors.purpleLabelBorder
                  : colors.orangeLabelBorder
              }
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  const TaskInputField = (props) => {
    const [task, setTask] = useState();

    const handleAddTask = (value) => {
      props.addTask(value);
      setTask(null);
    };
    function handleKeyPress(task) {
      !tasks.includes(task) && handleAddTask(task);
    }

    return (
      <View
        style={{
          flexDirection: "row",
          position: "absolute",
          marginBottom: -100,
          bottom: 0,
        }}
      >
        <View
          style={{
            borderColor: colors.greyDark,
            backgroundColor: "white",
            borderWidth: 1,
            width: 360,
            borderRadius: 5,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Ionicons
            name="create-outline"
            size={24}
            color={colors.greyDark}
            style={{ paddingHorizontal: 20, marginRight: -20 }}
          />
          <TextInput
            style={{
              color: "black",
              height: 50,
              flex: 1,
              marginLeft: -44,
              paddingLeft: 66,
              paddingHorizontal: 20,
            }}
            value={task}
            onChangeText={(text) => setTask(text)}
            placeholder={"Input Category Here..."}
            placeholderTextColor={"grey"}
            maxLength={10}
            multiline={false}
            autoFocus
            onKeyPress={(e) => e.key == "Enter" && handleKeyPress(task)}
          />
        </View>
        <View style={{ width: 10 }} />
        <Pressable
          style={({ hovered }) => [
            {
              height: 50,
              width: 50,
              borderWidth: 1,
              borderColor: colors.greyBorder,
              borderRadius: 5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: hovered ? colors.greyBorder : colors.greySidebar,
            },
          ]}
          onPress={() => !tasks.includes(task) && handleAddTask(task)}
        >
          <Text
            style={{
              color: "black",
              fontSize: 20,
              fontFamily: "Inter_600SemiBold",
            }}
          >
            +
          </Text>
        </Pressable>
      </View>
    );
  };

  const LabelTracker = (props) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <Text
          style={{
            fontSize: 16,
            fontFamily: "Inter_700Bold",
            color: colors.greyDark,
          }}
        >
          {props.task}:
        </Text>
        <Text
          style={{
            fontSize: 15,
            fontFamily: "Inter_500Medium",
            color: colors.greyDark,
          }}
        >
          {props.task == props.tasks[0]
            ? labeled1
            : props.task == props.tasks[1]
            ? labeled2
            : props.task == props.tasks[2]
            ? labeled3
            : props.task == props.tasks[3]
            ? labeled4
            : labeled5}
        </Text>
      </View>
    );
  };
  const CategoryButton = (props) => {
    if (!fontsLoaded) {
      return <AppLoading />;
    } else {
      return (
        <Hoverable>
          {({ hovered }) => (
            <TouchableOpacity
              disabled={processingClick}
              onPress={() => !processingClick && pressLabel(props)}
              style={{
                alignItems: "center",
                alignSelf: "center",
                backgroundColor:
                  props.index == 1
                    ? hovered
                      ? colors.greenLabel
                      : "white"
                    : props.index == 2
                    ? hovered
                      ? colors.redLabel
                      : "white"
                    : props.index == 3
                    ? hovered
                      ? colors.blueLabel
                      : "white"
                    : props.index == 4
                    ? hovered
                      ? colors.purpleLabel
                      : "white"
                    : hovered
                    ? colors.orangeLabel
                    : "white",

                shadowColor: "black",
                shadowOpacity: 0, //was .1
                shadowRadius: 5,
                shadowOffset: {
                  height: 1,
                  width: 1,
                },
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
                borderWidth: 1,
                borderColor:
                  props.index == 1
                    ? colors.greenLabelBorder
                    : props.index == 2
                    ? colors.redLabelBorder
                    : props.index == 3
                    ? colors.blueLabelBorder
                    : props.index == 4
                    ? colors.purpleLabelBorder
                    : colors.orangeLabelBorder,
                paddingHorizontal: 10,
                paddingVertical: 5,
                height: 40,
                width: "90%", //was 150
                marginVertical: 5,
              }}
            >
              <Text
                style={{
                  color:
                    props.index == 1
                      ? colors.greenLabelBorder
                      : props.index == 2
                      ? colors.redLabelBorder
                      : props.index == 3
                      ? colors.blueLabelBorder
                      : props.index == 4
                      ? colors.purpleLabelBorder
                      : colors.orangeLabelBorder,
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                  selectable: false,
                  suppressHighlighting: true,
                }}
              >
                {props.task}
              </Text>

              <Text
                style={{
                  color:
                    props.index == 1
                      ? colors.greenLabelBorder
                      : props.index == 2
                      ? colors.redLabelBorder
                      : props.index == 3
                      ? colors.blueLabelBorder
                      : props.index == 4
                      ? colors.purpleLabelBorder
                      : colors.orangeLabelBorder,
                  fontSize: 15,
                  fontFamily: "Inter_500Medium",
                }}
              >
                (
                {props.index == 1
                  ? category1Count
                  : props.index == 2
                  ? category2Count
                  : props.index == 3
                  ? category3Count
                  : props.index == 4
                  ? category4Count
                  : category5Count}
                )
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>
      );
    }
  };
  const PowerLabelButton = (props) => {
    if (!fontsLoaded) {
      return <AppLoading />;
    } else {
      return (
        <TouchableOpacity
          onPress={() => (
            setPowerLabelSelection(props.task), powerFilter(props.task)
          )}
          style={{ alignItems: "center" }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              backgroundColor:
                powerLabelSelection == props.task ? "white" : "transparent",
              paddingHorizontal: 10,
              paddingVertical: 5,
              borderRadius: powerLabelSelection == props.task ? 10 : 0,
              minHeight: 30,
              width: 120,
              shadowColor: "black",
              shadowOpacity: powerLabelSelection == props.task ? 0.1 : 0,
              shadowRadius: 5,
              shadowOffset: {
                height: 1,
                width: 1,
              },
            }}
          >
            <Text
              style={{
                fontSize: 14,
                color: colors.greyDark,
                fontFamily:
                  powerLabelSelection == props.task
                    ? "Inter_600SemiBold"
                    : "Inter_500Medium",
              }}
            >
              {props.task}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }
  };
  const flatListRef = React.useRef();
  const toTop = () => {
    // use current
    flatListRef.current?.scrollToOffset({ animated: false, offset: 0 });
  };
  const powerFilter = (filter) => {
    let filteredData = powerLabelPredictionImages.filter(function (item) {
      if (filter == "All") {
        return item;
      } else {
        return item.prediction.includes(filter);
      }
    });
    setFilteredData(filteredData);
    toTop();
  };
  const reVerify = () => {
    setStartedReVerify(true);
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      passwordText1
    );
    reauthenticateWithCredential(auth.currentUser, credential)
      .then(() => {
        checkIfPremium();
        setPasswordText1("");
        setFinishedVerification(true);
      })
      .catch((error) => {
        if (error.code == "auth/wrong-password") {
          alert("This email or password is incorrect");
        } else {
          alert(error.code);
        }
      });
    setStartedReVerify(false);
  };

  return (
    <View style={{ width: width, height: height }}>
      <Overlay //Not Logged In
        isVisible={notLoggedIn}
        fullScreen={false}
        overlayStyle={{
          alignItems: "center",
          zIndex: 102,
          justifyContent: "center",
        }}
      >
        <Overlay //Open Log In
          isVisible={signInCheck == "signed_in" ? false : showLogIn}
          fullScreen={false}
          onBackdropPress={() => setShowLogIn(false)}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 105,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: height * 0.9,
              width: width * 0.9,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowLogIn(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 100,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <LogIn_Editor />
          </View>
        </Overlay>

        <Overlay //Open Sign Up
          isVisible={signInCheck == "signed_in" ? false : showSignUp}
          fullScreen={false}
          onBackdropPress={() => setShowSignUp(false)}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 105,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: height * 0.9,
              width: width * 0.9,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowSignUp(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 100,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <SignUp_Editor />
          </View>
        </Overlay>

        <View
          style={{
            backgroundColor: "white",
            height: height,
            width: width,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingHorizontal: "10%",
            }}
          >
            Please login to access the editor
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              height: "35%",
              width: "100%",
              marginTop: 20,
            }}
          >
            <Pressable
              onPress={() => setShowSignUp(true)}
              style={({ hovered }) => [
                {
                  width: 200,
                  backgroundColor: hovered ? colors.redHover : colors.red,
                  height: 40,
                  borderRadius: 5,
                  alignItems: "center",
                  justifyContent: "center",
                },
              ]}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: "white",
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Sign Up
              </Text>
            </Pressable>
            <View style={{ width: 20, height: 50 }} />
            <Pressable
              onPress={() => setShowLogIn(true)}
              style={({ hovered }) => [
                {
                  width: 200,
                  backgroundColor: hovered ? colors.greyDisabled : "white",
                  borderColor: hovered
                    ? colors.greyDarkHover
                    : colors.greyLightFont,
                  height: 40,
                  borderRadius: 5,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                },
              ]}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: colors.greyDark,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Log in
              </Text>
            </Pressable>
          </View>
        </View>
      </Overlay>

      <Overlay //Help Prompt
        isVisible={showHelp}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: height * 0.9,
            width: width * 0.8,
            backgroundColor: "white",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => setShowHelp(false)}
            style={{
              zIndex: 100,
              position: "absolute",
              borderRadius: 100,
              height: 40,
              width: 40,
              backgroundColor: colors.greyDisabled,
              right: 15,
              top: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "Inter_800ExtraBold",
                fontSize: 16,
                color: colors.greyDark,
              }}
            >
              X
            </Text>
          </TouchableOpacity>
          <HelpInfo />
        </View>
      </Overlay>

      <Overlay //Loaded Model Successfully
        isVisible={showLoadedModelSuccess}
        fullScreen={false}
        overlayStyle={{
          alignItems: "center",
          zIndex: 100,
          justifyContent: "center",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            height: width * 0.3,
            width: width * 0.3,
            borderRadius: 5,
            padding: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LottieView
            ref={animation}
            style={{
              width: 250,
              height: 250,
            }}
            speed={1}
            autoPlay={true}
            loop={false}
            onAnimationFinish={() => setShowLoadedModelSuccess(false)}
            source={require("../assets/Checkmark.json")}
          />
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_700Bold",
              paddingHorizontal: "10%",
            }}
          >
            Loaded model successfully!
          </Text>
        </View>
      </Overlay>

      <Overlay //Loaded Images Successfully
        isVisible={showImagesLoadedSuccess}
        fullScreen={false}
        overlayStyle={{
          alignItems: "center",
          zIndex: 100,
          justifyContent: "center",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            height: width * 0.3,
            width: width * 0.3,
            borderRadius: 5,
            padding: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LottieView
            ref={animation}
            style={{
              width: 250,
              height: 250,
            }}
            speed={1}
            autoPlay={true}
            loop={false}
            onAnimationFinish={() => setShowImagesLoadedSuccess(false)}
            source={require("../assets/Checkmark.json")}
          />
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingHorizontal: "10%",
            }}
          >
            Loaded image(s) successfully!
          </Text>
          {duplicateImage && (
            <Text
              style={{
                fontSize: 16,
                marginTop: 10,
                fontFamily: "Inter_500Medium",
                color: colors.redError,
              }}
            >
              Duplicate images removed
            </Text>
          )}
        </View>
      </Overlay>

      <Overlay //Profile Info
        isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: 550,
            width: 1000,
            backgroundColor: "white",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => setShowProfileInfo(false)}
            style={{
              zIndex: 100,
              position: "absolute",
              borderRadius: 100,
              height: 40,
              width: 40,
              backgroundColor: colors.greyDisabled,
              right: 15,
              top: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "Inter_800ExtraBold",
                fontSize: 16,
                color: colors.greyDark,
              }}
            >
              X
            </Text>
          </TouchableOpacity>
          <ProfileInfo closeProfile={closeProfile} />
        </View>
      </Overlay>

      <Overlay //Restart Prompt
        isVisible={pressedRestart}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: width * 0.3,
            width: width * 0.4,
            backgroundColor: "white",
            borderRadius: 5,
            padding: 20,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingTop: "10%",
            }}
          >
            Are you sure you want to restart?
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Restarting will remove the current model and remove all images
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "35%",
            }}
          >
            <Pressable
              onPress={() => setPressedRestart(false)}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered
                    ? colors.greyDisabledHover
                    : colors.greyDisabled,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                Cancel
              </Text>
            </Pressable>
            <View style={{ width: "5%" }} />
            <Pressable
              onPress={() => resetKnnClassifierModel()}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered ? colors.redHover : colors.red,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "white",
                }}
              >
                Restart
              </Text>
            </Pressable>
          </View>
        </View>
      </Overlay>

      <Overlay //Info Free Prompt
        isVisible={freeInfo}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: width * 0.3,
            width: width * 0.4,
            backgroundColor: "white",
            borderRadius: 5,
            padding: 20,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingTop: "10%",
            }}
          >
            Premium Features
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            The AmpLabel ML features are only available for Premium users
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "35%",
            }}
          >
            <Pressable
              onPress={() => setFreeInfo(false)}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered
                    ? colors.greyDisabledHover
                    : colors.greyDisabled,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                Cancel
              </Text>
            </Pressable>
            <View style={{ width: "5%" }} />
            <Pressable
              disabled={showingCheckoutInfo}
              onPress={() => {
                setShowingCheckoutInfo(true),
                  createCheckoutSession(
                    auth.currentUser?.uid,
                    ENV_ST_MO,
                    ENV_POST_SUBSCR,
                    ENV_POST_CANCEL_SUBSCR
                  ),
                  setTimeout(() => {
                    setShowingCheckoutInfo(false);
                  }, 5000);
              }}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered ? colors.redHover : colors.red,
                },
              ]}
            >
              {!showingCheckoutInfo && (
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Upgrade
                </Text>
              )}
              {showingCheckoutInfo && (
                <LottieView
                  ref={animation}
                  style={{
                    width: 40,
                    height: 15,
                  }}
                  autoPlay={true}
                  loop={true}
                  source={require("../assets/loadingAnimation.json")}
                />
              )}
            </Pressable>
          </View>
        </View>
      </Overlay>

      <Overlay //Error Loading Model
        isVisible={errorLoadingModel}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: width * 0.3,
            width: width * 0.4,
            backgroundColor: "white",
            borderRadius: 5,
            padding: 20,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingTop: "10%",
            }}
          >
            Issue loading model
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Make sure you are loading Project Amp's model file ("Amp_Model.txt")
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "35%",
            }}
          >
            <Pressable
              onPress={() => setErrorLoadingModel(false)}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered ? colors.redHover : colors.red,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "white",
                }}
              >
                Ok
              </Text>
            </Pressable>
          </View>
        </View>
      </Overlay>

      <Overlay //Issue with an Image
        isVisible={imageIssue}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: width * 0.3,
            width: width * 0.4,
            backgroundColor: "white",
            borderRadius: 5,
            padding: 20,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingTop: "10%",
            }}
          >
            Images are missing labels
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Looks like some of your image files couldn't be read by the model.
            Correct any images marked "Image Issue"
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "35%",
            }}
          >
            <Pressable
              onPress={() => setImageIssue(false)}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered ? colors.redHover : colors.red,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "white",
                }}
              >
                Go Back
              </Text>
            </Pressable>
          </View>
        </View>
      </Overlay>

      {!imageIssue && (
        <Overlay //Began Power Label
          isVisible={pressedPowerLabel}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!beginPowerLabel && (
            <View
              style={{
                height: width * 0.5,
                width: width * 0.5,
                backgroundColor: "white",
                borderRadius: 5,
                padding: 40,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: colors.greyDark,
                  fontSize: 20,
                  fontFamily: "Inter_700Bold",
                  paddingTop: "10%",
                  paddingHorizontal: "10%",
                }}
              >
                Power Label allows your model to immediately label multiple
                images
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: colors.greyDark,
                  fontSize: 16,
                  fontFamily: "Inter_500Medium",
                  paddingTop: "15%",
                }}
              >
                How many images to Power Label? (max of 500)
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: colors.blueLabelBorder,
                  fontSize: 40,
                  fontFamily: "Inter_700Bold",
                  paddingTop: "5%",
                }}
              >
                {sliderValue}
              </Text>
              <View style={{ width: "75%", alignSelf: "center" }}>
                <Slider
                  value={0}
                  step={1}
                  thumbTintColor={colors.blueLabelBorder}
                  minimumTrackTintColor={colors.greyDark}
                  maximumTrackTintColor={colors.greyCancel}
                  onValueChange={(value) => setSliderValue(value)}
                  minimumValue={1}
                  maximumValue={sliderMax}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: "35%",
                }}
              >
                <Pressable
                  onPress={() => (
                    setPressedPowerLabel(false), setSliderValue(1)
                  )}
                  style={({ hovered }) => [
                    {
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      width: 200,
                      height: 50,
                      backgroundColor: hovered
                        ? colors.greyDisabledHover
                        : colors.greyDisabled,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      fontSize: 16,
                      color: colors.greyDark,
                    }}
                  >
                    Cancel
                  </Text>
                </Pressable>
                <View style={{ width: "5%" }} />
                <Pressable
                  onPress={() => (
                    setBeginPowerLabel(true),
                    numberOfImages.length - numberOfImagesInModel == 1
                      ? powerLabelPredictions(imagesForModel.slice(-1), tasks)
                      : powerLabelPredictions(
                          imagesForModel.slice(index, sliderValue + index),
                          tasks
                        )
                  )}
                  style={({ hovered }) => [
                    {
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      width: 200,
                      height: 50,
                      backgroundColor: hovered ? colors.redHover : colors.red,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      fontSize: 16,
                      color: "white",
                    }}
                  >
                    Continue
                  </Text>
                </Pressable>
              </View>
            </View>
          )}
          {beginPowerLabel && (
            <Animatable.View
              animation={"zoomIn"}
              duration={300}
              style={{
                height: height * 0.9,
                width: width * 0.9,
                backgroundColor: "white",
                borderRadius: 10,
                padding: 40,
              }}
            >
              {!donePowerLabelCollect && !isPredicting && (
                <Text
                  style={{
                    textAlign: "center",
                    color: colors.greyDark,
                    fontSize: 20,
                    fontFamily: "Inter_700Bold",
                    paddingTop: "2%",
                  }}
                >
                  Modify labels as needed:
                </Text>
              )}
              {!donePowerLabelCollect && !isPredicting && (
                <View
                  style={{
                    flexDirection: "row",
                    width:
                      powerLabelError != null
                        ? (tasks.length + 1) * 120 + 120
                        : (tasks.length + 1) * 120,
                    alignSelf: "center",
                    backgroundColor: colors.greyDisabled,
                    borderColor: colors.greyBorder,
                    borderWidth: 1,
                    borderRadius: 10,
                    alignItems: "center",
                    justifyContent: "center",
                    marginVertical: 10,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => (
                      setPowerLabelSelection("All"), powerFilter("All")
                    )}
                    style={{ alignItems: "center" }}
                  >
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        backgroundColor:
                          powerLabelSelection == "All"
                            ? "white"
                            : "transparent",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: powerLabelSelection == "All" ? 10 : 0,
                        minHeight: 30,
                        width: 120,
                        shadowColor: "black",
                        shadowOpacity: powerLabelSelection == "All" ? 0.1 : 0,
                        shadowRadius: 5,
                        shadowOffset: {
                          height: 1,
                          width: 1,
                        },
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 14,
                          color: colors.greyDark,
                          fontFamily:
                            powerLabelSelection == "All"
                              ? "Inter_600SemiBold"
                              : "Inter_500Medium",
                        }}
                      >
                        All
                      </Text>
                    </View>
                  </TouchableOpacity>
                  {powerLabelError != null && powerLabelError.length > 0 && (
                    <TouchableOpacity
                      onPress={() => (
                        setPowerLabelSelection("Image Issue"),
                        powerFilter("Image Issue")
                      )}
                      style={{ alignItems: "center" }}
                    >
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          flex: 1,
                          backgroundColor:
                            powerLabelSelection == "Image Issue"
                              ? "white"
                              : "transparent",
                          paddingHorizontal: 10,
                          paddingVertical: 5,
                          borderRadius:
                            powerLabelSelection == "Image Issue" ? 10 : 0,
                          minHeight: 30,
                          width: 120,
                          shadowColor: "black",
                          shadowOpacity:
                            powerLabelSelection == "Image Issue" ? 0.1 : 0,
                          shadowRadius: 5,
                          shadowOffset: {
                            height: 1,
                            width: 1,
                          },
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            color: colors.greyDark,
                            fontFamily:
                              powerLabelSelection == "Image Issue"
                                ? "Inter_600SemiBold"
                                : "Inter_500Medium",
                          }}
                        >
                          Image Issue
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                  {tasks.map((task, index) => {
                    return (
                      <View key={index}>
                        <PowerLabelButton task={task} alltasks={tasks} />
                      </View>
                    );
                  })}
                </View>
              )}
              <View
                style={{
                  height: "75%",
                  width: "90%",
                  paddingTop: "3`12%",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                {isPredicting && !donePowerLabelCollect && (
                  <ActivityIndicator
                    style={{ paddingTop: "20%" }}
                    size="large"
                    color={colors.blueLabelBorder}
                  />
                )}
                {isPredicting &&
                  !donePowerLabelCollect &&
                  sliderValue > 100 &&
                  sliderValue < 300 && (
                    <Text
                      style={{
                        color: colors.blueLabelBorder,
                        fontFamily: "Inter_600SemiBold",
                        paddingTop: 20,
                      }}
                    >
                      Est. processing time = 5 - 10 seconds
                    </Text>
                  )}
                {isPredicting &&
                  !donePowerLabelCollect &&
                  sliderValue > 300 &&
                  sliderValue < 400 && (
                    <Text
                      style={{
                        color: colors.blueLabelBorder,
                        fontFamily: "Inter_600SemiBold",
                        paddingTop: 20,
                      }}
                    >
                      Est. processing time = 10 - 15 seconds
                    </Text>
                  )}
                {isPredicting &&
                  !donePowerLabelCollect &&
                  sliderValue > 400 &&
                  sliderValue < 501 && (
                    <Text
                      style={{
                        color: colors.blueLabelBorder,
                        fontFamily: "Inter_600SemiBold",
                        paddingTop: 20,
                      }}
                    >
                      Est. processing time = 15 - 25 seconds
                    </Text>
                  )}
                {filteredData.length == 0 && powerLabelSelection != "All" ? (
                  <View
                    style={{
                      height: "90%",
                      width: "50%",
                      backgroundColor: colors.greyBackground,
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LottieView
                      ref={animation}
                      style={{
                        width: 320,
                        height: 320,
                        marginTop: "-5%",
                      }}
                      speed={1}
                      autoPlay={true}
                      loop={true}
                      source={require("../assets/No_Images.json")}
                    />
                    <Text
                      style={{
                        fontSize: 16,
                        fontFamily: "Inter_700Bold",
                        color: colors.greyDark,
                      }}
                    >
                      No Images!
                    </Text>
                  </View>
                ) : (
                  !isPredicting &&
                  !donePowerLabelCollect && (
                    <FlatList
                      ref={flatListRef}
                      initialScrollIndex={0}
                      data={
                        filteredData.length > 0
                          ? imagesSuccessfullyUploaded.length == 1
                            ? [filteredData]
                            : filteredData
                          : imagesSuccessfullyUploaded.length == 1
                          ? [powerLabelPredictionImages]
                          : powerLabelPredictionImages
                      }
                      renderItem={renderPowerLabelItem}
                      keyExtractor={(item) => item.index.toString()}
                      scrollEnabled={true}
                      numColumns={4}
                    />
                  )
                )}
                {donePowerLabelCollect && (
                  <LottieView
                    ref={animation}
                    style={{
                      marginTop: "13%",
                      width: 320,
                      height: 320,
                    }}
                    speed={1}
                    autoPlay={true}
                    loop={false}
                    onAnimationFinish={() => (
                      setIsPredicting(false),
                      setDonePowerLabelCollect(false),
                      setPressedPowerLabel(false),
                      setBeginPowerLabel(false)
                    )}
                    source={require("../assets/Lightning.json")}
                  />
                )}
              </View>
              {!isPredicting && !donePowerLabelCollect && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingTop: 15,
                  }}
                >
                  <Pressable
                    onPress={() => (
                      setPressedPowerLabel(false),
                      setBeginPowerLabel(false),
                      setSliderValue(1),
                      setPowerLabelSelection("All"),
                      setFilteredData([]),
                      setPowerLabelError(null),
                      setPowerLabelPredictionImages([])
                    )}
                    style={({ hovered }) => [
                      {
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 200,
                        height: 50,
                        backgroundColor: hovered
                          ? colors.greyCancelHover
                          : colors.greyCancel,
                      },
                    ]}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 16,
                        color: colors.greyDark,
                      }}
                    >
                      Cancel
                    </Text>
                  </Pressable>
                  <View style={{ width: "5%" }} />
                  <Pressable
                    onPress={() => finishPowerLabel()}
                    style={({ hovered }) => [
                      {
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        width: 200,
                        height: 50,
                        backgroundColor: hovered ? colors.redHover : colors.red,
                      },
                    ]}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 16,
                        color: "white",
                      }}
                    >
                      Finish
                    </Text>
                  </Pressable>
                </View>
              )}
            </Animatable.View>
          )}
        </Overlay>
      )}

      <Overlay //Selecting Images
        isVisible={selectingCategories}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: colors.greySidebar,
          zIndex: 100,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              backgroundColor: colors.greySidebar,
              borderRightWidth: 1,
              borderColor: colors.greyBorder,
              height: height,
              width: 280,
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => navigation.navigate("Home")}
              style={{
                flexDirection: "row",
                marginLeft: 30,
                alignItems: "center",
                paddingTop: "10%",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  borderRadius: 100,
                  width: 30,
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 1,
                  borderColor: colors.greyBorder,
                }}
              >
                <Ionicons
                  name="chevron-back-outline"
                  size={13}
                  color="#7a8185"
                />
              </View>
              <Text
                style={{
                  paddingLeft: 10,
                  color: colors.greyLightFont,
                  fontSize: 13,
                  fontFamily: "Inter_500Medium",
                }}
              >
                Back to Home Page
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                marginVertical: "10%",
                marginBottom: "20%",
                color: colors.greyDark,
                fontSize: 20,
                fontFamily: "Inter_700Bold",
                marginLeft: 30,
              }}
            >
              Add Images
            </Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={{
                  marginLeft: 30,
                  borderRadius: 100,
                  width: 40,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: colors.greyDark,
                  borderWidth: 1,
                  backgroundColor: "white",
                }}
              >
                <View
                  style={{
                    borderRadius: 100,
                    width: 30,
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: colors.greyDark,
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    1
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  paddingLeft: 10,
                  fontSize: 15,
                  fontFamily: "Inter_500Medium",
                  color: colors.greyDark,
                }}
              >
                Select Images
              </Text>
            </View>
            <View
              style={{
                height: "10%",
                width: 5,
                marginLeft: 30 + 20,
                borderLeftWidth: 1,
                borderColor: colors.greyLightFont,
              }}
            />
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={{
                  marginLeft: 30,
                  borderRadius: 100,
                  width: 40,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: colors.greyLightFont,
                  borderWidth: 1,
                  backgroundColor: "white",
                }}
              >
                <View
                  style={{
                    borderRadius: 100,
                    width: 30,
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                >
                  <Text
                    style={{
                      color: colors.greyLightFont,
                      fontSize: 16,
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    2
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  paddingLeft: 10,
                  fontSize: 15,
                  fontFamily: "Inter_500Medium",
                  color: colors.greyDark,
                }}
              >
                Write Labels
              </Text>
            </View>
          </View>
          <View
            style={{
              backgroundColor: "#fcfcfc",
              height: height,
              width: width - 280,
            }}
          >
            <Text
              style={{
                marginTop: "5%",
                paddingLeft: "5%",
                color: "black",
                fontSize: 20,
                fontFamily: "Inter_700Bold",
              }}
            >
              Select Images
            </Text>
            <Text
              style={{
                marginTop: "1%",
                paddingLeft: "5%",
                color: "black",
                opacity: 0.6,
                fontSize: 15,
                fontFamily: "Inter_500Medium",
              }}
            >
              Upload images for classification
            </Text>

            <View
              style={{
                backgroundColor: "#fcfcfc",
                height: "100%",
                width: width - 280,
                padding: "5%",
                paddingTop: "2.5%",
              }}
            >
              <View
                style={{
                  width: width * 0.3,
                  height: height * 0.3,
                  borderRadius: 20,
                  alignItems: "center",
                }}
              >
                {imagesUploaded && (
                  <TouchableOpacity
                    disabled={imagesAreUploading}
                    onPress={() => setImagesUploaded()}
                    style={{
                      zIndex: 100,
                      position: "absolute",
                      borderRadius: 100,
                      height: 40,
                      width: 40,
                      backgroundColor: colors.greyDisabled,
                      right: 15,
                      top: 15,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "Inter_800ExtraBold",
                        fontSize: 16,
                        color: colors.greyDark,
                      }}
                    >
                      X
                    </Text>
                  </TouchableOpacity>
                )}

                <Pressable
                  disabled={imagesAreUploading}
                  onPress={() => pickImage()}
                  style={({ hovered }) => [
                    {
                      borderColor: hovered ? "black" : colors.greyLightFont,
                      borderWidth: 2,
                      alignItems: "center",
                      justifyContent: "center",
                      borderStyle: "dashed",
                      width: width * 0.3,
                      height: height * 0.3,
                      borderRadius: 10,
                      backgroundColor: "white",
                    },
                  ]}
                >
                  {imagesUploaded == null && (
                    <View style={{ alignItems: "center" }}>
                      <Text
                        style={{
                          fontSize: 16,
                          fontFamily: "Inter_500Medium",
                          color: "black",
                        }}
                      >
                        Click to Select Images
                      </Text>
                      <Text
                        style={{
                          fontSize: 14,
                          paddingTop: 10,
                          fontFamily: "Inter_500Medium",
                          color: colors.greyLightFont,
                          textAlign: "center",
                        }}
                      >
                        This may take a few seconds to upload
                      </Text>
                    </View>
                  )}

                  {imagesUploaded != null && (
                    <View style={{ paddingTop: 10, alignItems: "center" }}>
                      <Text
                        style={{
                          fontSize: 16,
                          fontFamily: "Inter_500Medium",
                          color: colors.greyDark,
                        }}
                      >
                        {imagesUploaded.length} images uploaded
                      </Text>

                      {duplicateImage && (
                        <Text
                          style={{
                            fontSize: 16,
                            marginTop: 10,
                            fontFamily: "Inter_500Medium",
                            color: colors.redError,
                          }}
                        >
                          Duplicate images removed
                        </Text>
                      )}
                    </View>
                  )}
                </Pressable>
              </View>
              <View
                style={{
                  marginLeft: width * 0.15 - 87.5,
                  paddingTop: "3%",
                }}
              >
                <Pressable
                  disabled={
                    imagesAreUploading
                      ? true
                      : imagesUploaded == null
                      ? true
                      : imagesUploaded == 0
                      ? true
                      : false
                  }
                  style={({ hovered }) => [
                    {
                      height: 50,
                      width: 200,
                      borderRadius: 5,
                      backgroundColor:
                        imagesUploaded == null
                          ? colors.greyDisabled
                          : imagesUploaded == 0
                          ? colors.greyDisabled
                          : hovered
                          ? colors.redHover
                          : colors.red,
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    },
                  ]}
                  onPress={() => (
                    setTimeout(() => {
                      setGoToLabels(true);
                    }, 2000),
                    setImagesAreUploading(true),
                    setTimeout(() => {
                      setImagesAreUploading(false);
                    }, 2000)
                  )}
                >
                  <Text
                    style={{
                      color:
                        imagesUploaded == null
                          ? colors.greyLightFont
                          : imagesUploaded == 0
                          ? colors.greyLightFont
                          : "white",
                      fontSize: 16,
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    Continue
                  </Text>
                  {imagesAreUploading && (
                    <ActivityIndicator
                      style={{ paddingLeft: 10 }}
                      size="small"
                      color="white"
                    />
                  )}
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      </Overlay>

      <Overlay //Writing Labels
        isVisible={goToLabels}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: colors.greySidebar,
          zIndex: 100,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => navigation.navigate("Home")}
          style={{
            marginLeft: 45,
            justifyContent: "center",
            position: "absolute",
            left: 10,
            top: 30,
          }}
        >
          <Image
            source={require("../assets/AmpLabel.png")}
            style={{ width: 100, height: 30 }}
          />
        </TouchableOpacity>
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              backgroundColor: colors.greySidebar,
              borderRightWidth: 1,
              borderColor: colors.greyBorder,
              height: height,
              width: 280,
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => navigation.navigate("Home")}
              style={{
                flexDirection: "row",
                marginLeft: 30,
                alignItems: "center",
                paddingTop: "10%",
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  borderRadius: 100,
                  width: 30,
                  height: 30,
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 1,
                  borderColor: colors.greyBorder,
                }}
              >
                <Ionicons
                  name="chevron-back-outline"
                  size={13}
                  color={colors.greyLightFont}
                />
              </View>
              <Text
                style={{
                  paddingLeft: 10,
                  color: colors.greyLightFont,
                  fontSize: 13,
                  fontFamily: "Inter_500Medium",
                }}
              >
                Back to Home Page
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                marginVertical: "10%",
                marginBottom: "20%",
                color: colors.greyDark,
                fontSize: 20,
                fontFamily: "Inter_700Bold",
                marginLeft: 30,
              }}
            >
              Add Images
            </Text>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={{
                  marginLeft: 30,
                  borderRadius: 100,
                  width: 40,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: colors.blueLabelBorder,
                  borderWidth: 1,
                  backgroundColor: "white",
                }}
              >
                <View
                  style={{
                    borderRadius: 100,
                    width: 30,
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: colors.blueLabelBorder,
                  }}
                >
                  <Ionicons
                    name="checkmark-outline"
                    size={20}
                    color={"white"}
                  />
                </View>
              </View>
              <Text
                style={{
                  paddingLeft: 10,
                  fontSize: 15,
                  fontFamily: "Inter_500Medium",
                  color: colors.greyDark,
                }}
              >
                Select Images
              </Text>
            </View>
            <View
              style={{
                height: "10%",
                width: 5,
                marginLeft: 30 + 20,
                borderLeftWidth: 1,
                borderColor: colors.greyDark,
              }}
            />
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View
                style={{
                  marginLeft: 30,
                  borderRadius: 100,
                  width: 40,
                  height: 40,
                  alignItems: "center",
                  justifyContent: "center",
                  borderColor: colors.greyDark,
                  borderWidth: 1,
                  backgroundColor: "white",
                }}
              >
                <View
                  style={{
                    borderRadius: 100,
                    width: 30,
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: colors.greyDark,
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    2
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  paddingLeft: 10,
                  fontSize: 15,
                  fontFamily: "Inter_500Medium",
                  color: colors.greyDark,
                }}
              >
                Write Labels
              </Text>
            </View>
          </View>
          <View
            style={{
              height: height,
              width: width - 280,
              backgroundColor: "#fcfcfc",
            }}
          >
            <Text
              style={{
                marginTop: "5%",
                paddingLeft: "5%",
                color: "black",
                fontSize: 20,
                fontFamily: "Inter_700Bold",
              }}
            >
              Write Labels
            </Text>
            <Text
              style={{
                marginTop: "1%",
                paddingLeft: "5%",
                color: "black",
                opacity: 0.6,
                fontSize: 15,
                fontFamily: "Inter_500Medium",
              }}
            >
              Input your categories for training (up to 5)
            </Text>

            <View
              style={{
                backgroundColor: "#fcfcfc",
                height: "100%",
                width: width - 280,
                padding: "2.5%",
                paddingTop: "2.5%",
              }}
            >
              <View
                style={{
                  width: width * 0.3,
                  height: height * 0.3,
                  borderRadius: 20,
                  alignItems: "center",
                }}
              >
                <View style={{ width: "100%", marginLeft: "15%" }}>
                  {tasks.length < 5 && <TaskInputField addTask={addTask} />}
                  <View
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {tasks.slice(0, 2).map((task, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            flex: 1,
                            width: "100%",
                            marginHorizontal: tasks.length > 1 ? 10 : 0,
                            marginVertical: 5,
                          }}
                        >
                          <TaskItem
                            index={index + 1}
                            task={task}
                            deleteTask={() => deleteTask(index)}
                          />
                        </View>
                      );
                    })}
                  </View>
                  <View
                    style={{
                      marginBottom: 0,
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {tasks.slice(2, 4).map((task, index) => {
                      return (
                        <View
                          key={index + 2}
                          style={{
                            flex: 1,
                            width: "50%",
                            marginHorizontal: 10,
                            marginVertical: 5,
                          }}
                        >
                          <TaskItem
                            index={index + 3}
                            task={task}
                            deleteTask={() => deleteTask(index + 2)}
                          />
                        </View>
                      );
                    })}
                  </View>
                  <View
                    style={{
                      marginBottom: 0,
                      width: "50%",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {tasks.slice(4, 5).map((task, index) => {
                      return (
                        <View
                          key={index + 4}
                          style={{
                            flex: 1,
                            width: "40%",
                            marginHorizontal: 10,
                            marginVertical: 5,
                          }}
                        >
                          <TaskItem
                            index={index + 5}
                            task={task}
                            deleteTask={() => deleteTask(index + 4)}
                          />
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: width * 0.075,
                  paddingTop: "3%",
                }}
              >
                <Pressable
                  style={({ hovered }) => [
                    {
                      height: 50,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 10,
                      flexDirection: "row",
                    },
                  ]}
                  onPress={() => setGoToLabels(false)}
                >
                  <Ionicons
                    name="arrow-back-outline"
                    size={16}
                    color={colors.greyDark}
                  />
                  <Text
                    style={{
                      color: colors.greyDarkHover,
                      fontSize: 16,
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    Back
                  </Text>
                </Pressable>
                <View style={{ width: 15 }} />
                <Pressable
                  disabled={
                    imagesUploaded == null
                      ? true
                      : imagesUploaded == 0
                      ? true
                      : tasks.length < 2
                      ? true
                      : false
                  }
                  style={({ hovered }) => [
                    {
                      height: 50,
                      width: 175,
                      borderRadius: 5,
                      backgroundColor:
                        imagesUploaded == null
                          ? colors.greyDisabled
                          : imagesUploaded == 0
                          ? colors.greyDisabled
                          : tasks.length < 2
                          ? colors.greyDisabled
                          : hovered
                          ? colors.redHover
                          : colors.red,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  ]}
                  onPress={() => pressedBegin()}
                >
                  <Text
                    style={{
                      color:
                        imagesUploaded == null
                          ? colors.greyLightFont
                          : imagesUploaded == 0
                          ? colors.greyLightFont
                          : tasks.length < 2
                          ? colors.greyLightFont
                          : "white",
                      fontSize: 16,
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    Get Started
                  </Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      </Overlay>

      <Overlay //Go Home Prompt
        isVisible={goHome}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: width * 0.3,
            width: width * 0.4,
            backgroundColor: "white",
            borderRadius: 5,
            padding: 20,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingTop: "10%",
            }}
          >
            Are you sure you want to go to the home page?
          </Text>
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Navigating back to the home page will delete all progress
          </Text>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "35%",
            }}
          >
            <Pressable
              onPress={() => setGoHome(false)}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered
                    ? colors.greyDisabledHover
                    : colors.greyDisabled,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                Cancel
              </Text>
            </Pressable>
            <View style={{ width: "5%" }} />
            <Pressable
              onPress={() => (setGoHome(false), navigation.navigate("Home"))}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered ? colors.redHover : colors.red,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 15,
                  color: "white",
                }}
              >
                Go Home
              </Text>
            </Pressable>
          </View>
        </View>
      </Overlay>

      <View
        style={{
          flexDirection: "row",
          shadowColor: "rgba(179, 179, 179, 1)",
        }}
      >
        <View //Sidebar Panel
          style={{
            backgroundColor: colors.greySidebar,
            height: height,
            width: 280,
            borderRightWidth: 1,
            borderColor: colors.greyBorder,
          }}
        >
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => setGoHome(true)}
            style={{
              zIndex: 100,
              marginLeft: 20,
              justifyContent: "center",
              position: "absolute",
              left: 10,
              top: 30,
            }}
          >
            <Image
              source={require("../assets/AmpLabel.png")}
              style={{ width: 100, height: 30 }}
            />
          </TouchableOpacity>
          <View style={{ height: "15%" }} />
          <View style={{ height: "45%", width: "100%" }}>
            <View style={{ height: 5 }} />

            <Hoverable //Add Images
              style={{}}
            >
              {({ hovered }) => (
                <TouchableOpacity
                  onPress={() => pickImage()}
                  style={{
                    flexDirection: "row",
                    borderRadius: 10,
                    backgroundColor: hovered && "#e7f2fd",
                    paddingHorizontal: 10,
                    marginHorizontal: 20,
                    height: 45,
                    alignItems: "center",
                  }}
                >
                  <Ionicons
                    name="image-outline"
                    size={25}
                    color={hovered ? colors.blueLabelBorder : colors.greyDark}
                  />
                  <Text
                    style={{
                      paddingLeft: 10,
                      fontSize: 15,
                      fontFamily: "Inter_500Medium",
                      color: hovered ? colors.blueLabelBorder : colors.greyDark,
                    }}
                  >
                    Add Images
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>

            <View style={{ height: 5 }} />
            {premiumStatus != "premium" && (
              <Hoverable
                onMouseEnter={() => setDisabledLoadModel(true)}
                onMouseLeave={() => setDisabledLoadModel(false)}
              >
                {({ hovered }) => (
                  <TouchableOpacity //Load Model
                    disabled={premiumStatus != "premium" && true}
                    onPress={() => loadKnnClassifierModel()}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "rgba(255, 0, 0,.05)",
                      paddingHorizontal: 10,
                      marginHorizontal: 20,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="push-outline"
                      size={25}
                      color={hovered ? colors.redError : colors.greyDark}
                    />
                    {!disabledLoadModel && (
                      <Text
                        style={{
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: colors.greyDark,
                        }}
                      >
                        Load Model
                      </Text>
                    )}
                    {disabledLoadModel && (
                      <Text
                        style={{
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: colors.redError,
                        }}
                      >
                        Premium Feature
                      </Text>
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            {premiumStatus == "premium" && (
              <Hoverable //Load Model
                style={{}}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => loadKnnClassifierModel()}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "#e7f2fd",
                      paddingHorizontal: 10,
                      marginHorizontal: 20,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="push-outline"
                      size={25}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                    />
                    {!disabledLoadModel && (
                      <Text
                        style={{
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: hovered
                            ? colors.blueLabelBorder
                            : colors.greyDark,
                        }}
                      >
                        Load Model
                      </Text>
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}

            <View style={{ height: 5 }} />

            <Hoverable //Restart
              style={{}}
            >
              {({ hovered }) => (
                <TouchableOpacity
                  onPress={() => setPressedRestart(true)}
                  style={{
                    flexDirection: "row",
                    borderRadius: 10,
                    backgroundColor: hovered && "#e7f2fd",
                    paddingHorizontal: 10,
                    marginHorizontal: 20,
                    height: 45,
                    alignItems: "center",
                  }}
                >
                  <Ionicons
                    name="sync-outline"
                    size={25}
                    color={hovered ? colors.blueLabelBorder : colors.greyDark}
                  />
                  <Text
                    style={{
                      paddingLeft: 10,
                      fontSize: 15,
                      fontFamily: "Inter_500Medium",
                      color: hovered ? colors.blueLabelBorder : colors.greyDark,
                    }}
                  >
                    Restart
                  </Text>
                </TouchableOpacity>
              )}
            </Hoverable>

            <View style={{ height: 5 }} />

            <Hoverable //Export
              style={{}}
            >
              {({ hovered }) => (
                <TouchableOpacity
                  disabled={startDownload ? true : false}
                  onPress={() => saveKnnClassifierModel()}
                  style={{
                    flexDirection: "row",
                    borderRadius: 10,
                    backgroundColor: hovered && "#e7f2fd",
                    paddingHorizontal: 10,
                    marginHorizontal: 20,
                    height: 45,
                    alignItems: "center",
                  }}
                >
                  <Ionicons
                    name="download-outline"
                    size={25}
                    color={hovered ? colors.blueLabelBorder : colors.greyDark}
                  />
                  <Text
                    style={{
                      paddingLeft: 10,
                      fontSize: 15,
                      fontFamily: "Inter_500Medium",
                      color: hovered ? colors.blueLabelBorder : colors.greyDark,
                    }}
                  >
                    Export
                  </Text>
                  {startDownload && (
                    <ActivityIndicator
                      style={{ paddingLeft: 10 }}
                      size="small"
                      color={colors.blueLabelBorder}
                    />
                  )}
                </TouchableOpacity>
              )}
            </Hoverable>

            <View style={{ height: 5 }} />

            <Hoverable //Predictions Toggle
              style={{}}
            >
              {({ hovered }) => (
                <TouchableOpacity
                  onPress={() => setShowPrediction(!showPrediction)}
                  style={{
                    flexDirection: "row",
                    borderRadius: 10,
                    backgroundColor: hovered && "#e7f2fd",
                    paddingHorizontal: 10,
                    marginHorizontal: 20,
                    height: 45,
                    alignItems: "center",
                  }}
                >
                  {!showPrediction && (
                    <Ionicons
                      name="flash-outline"
                      size={25}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                    />
                  )}
                  {showPrediction && (
                    <Ionicons
                      name="flash-off-outline"
                      size={25}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                    />
                  )}
                  {!showPrediction && (
                    <Text
                      style={{
                        paddingLeft: 10,
                        fontSize: 15,
                        fontFamily: "Inter_500Medium",
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Show Predictions
                    </Text>
                  )}
                  {showPrediction && (
                    <Text
                      style={{
                        paddingLeft: 10,
                        fontSize: 15,
                        fontFamily: "Inter_500Medium",
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Hide Predictions
                    </Text>
                  )}
                </TouchableOpacity>
              )}
            </Hoverable>
          </View>
          <View
            style={{
              height: "40%",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            {premiumStatus != "premium" && (
              <Hoverable //Upgrade
                style={{ marginBottom: "5%" }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={showingCheckout}
                    onPress={() => {
                      setShowingCheckout(true),
                        createCheckoutSession(
                          auth.currentUser?.uid,
                          ENV_ST_MO,
                          ENV_POST_SUBSCR,
                          ENV_POST_CANCEL_SUBSCR
                        ),
                        setTimeout(() => {
                          setShowingCheckout(false);
                        }, 5000);
                    }}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      alignItems: "center",
                      alignSelf: "center",
                      justifyContent: "center",
                      height: 55,
                      width: "75%",
                      alignItems: "center",
                      shadowColor: "black",
                      shadowOpacity: hovered ? 0.3 : 0.1,
                      shadowRadius: 25,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    }}
                  >
                    <LinearGradient
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 10,

                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 0.5, y: 0.5 }}
                      colors={[colors.red, colors.purpleLabelBorder]}
                    >
                      {!showingCheckout && (
                        <Text
                          style={{
                            fontSize: 16,
                            color: "white",
                            fontFamily: "Inter_700Bold",
                            textAlign: "center",
                          }}
                        >
                          Upgrade
                        </Text>
                      )}
                      {showingCheckout && (
                        <LottieView
                          ref={animation}
                          style={{
                            width: 40,
                            height: 15,
                          }}
                          autoPlay={true}
                          loop={true}
                          source={require("../assets/loadingAnimation.json")}
                        />
                      )}
                    </LinearGradient>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            <View style={{ height: 10 }} />

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                height: 55,
                borderTopWidth: 1,
                borderColor: colors.greyBorder,
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: "Inter_600SemiBold",
                  color: colors.greyLightFont,
                }}
              >
                Status:
              </Text>
              {premiumStatus != "premium" && (
                <Text
                  style={{
                    paddingLeft: 10,
                    fontSize: 14,
                    fontFamily: "Inter_500Medium",
                    color: colors.greyLightFont,
                  }}
                >
                  Free-tier
                </Text>
              )}
              {premiumStatus == "premium" && (
                <Text
                  style={{
                    paddingLeft: 10,
                    fontSize: 14,
                    fontFamily: "Inter_500Medium",
                    color: colors.greyLightFont,
                  }}
                >
                  Premium
                </Text>
              )}
            </View>
          </View>
        </View>

        <View
          style={{ flexDirection: "row", height: height, width: width - 280 }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: height,
              width: "70%",
            }}
          >
            <View style={{ height: height, width: "100%" }}>
              {imagesSuccessfullyUploaded &&
                numberOfImages.length - numberOfImagesInModel == 0 && (
                  <View
                    style={{
                      height: 450,
                      width: 600,
                      borderRadius: 10,
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Pressable
                      onPress={() => pickImage()}
                      style={({ hovered }) => [
                        {
                          borderColor: hovered ? "black" : colors.greyLightFont,
                          borderWidth: 2,
                          alignItems: "center",
                          justifyContent: "center",
                          borderStyle: "dashed",
                          width: 500,
                          height: 250,
                          borderRadius: 10,
                        },
                      ]}
                    >
                      <View style={{ alignItems: "center" }}>
                        <Text
                          style={{
                            fontSize: 17,
                            fontFamily: "Inter_500Medium",
                            color: colors.greyLightFont,
                          }}
                        >
                          Click to Add More Images!
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                )}
              {imagesSuccessfullyUploaded &&
                numberOfImages.length - numberOfImagesInModel != 0 && (
                  <View //Image List Panel
                    style={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "white",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          height: 450,
                          width: 600,
                          alignSelf: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FlatList
                          ref={ref}
                          onScrollToIndexFailed={(info) => {
                            const wait = new Promise((resolve) =>
                              setTimeout(resolve, 500)
                            );
                            wait.then(() => {
                              ref.current?.scrollToIndex({
                                index,
                                animated: false,
                              });
                            });
                          }}
                          initialScrollIndex={index}
                          data={
                            imagesSuccessfullyUploaded.length == 1
                              ? [imagesForModel]
                              : imagesForModel
                          }
                          renderItem={memorizedValue}
                          keyExtractor={(item) => item.image}
                          scrollEnabled={false}
                        />
                      </View>
                      <Image
                        style={{ height: 300, width: 200 }}
                        source={imagesForModel[0].image}
                      />
                    </View>
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: 250,
                        height: 50,
                        borderRadius: 5,
                        marginTop: 35,
                        flexDirection: "row",
                        backgroundColor: colors.red,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontFamily: "Inter_600SemiBold",
                        }}
                      >
                        Next Image
                      </Text>
                      <Ionicons
                        name="arrow-forward-outline"
                        size={25}
                        color={"white"}
                      />
                    </TouchableOpacity>
                    <Text style={{ marginTop: 50 }}>{boxHeight}</Text>
                    <Text style={{ marginTop: 10 }}>{boxWidth}</Text>
                    <Text style={{ marginTop: 10 }}>{boxX}</Text>
                    <Text style={{ marginTop: 10 }}>{boxY}</Text>
                  </View>
                )}
            </View>
          </View>

          <View //Right Sidebar
            style={{
              backgroundColor: "white",
              borderLeftWidth: 1,
              borderColor: colors.greyBorder,
              height: height,
              width: "30%",
              shadowColor: "rgba(179, 179, 179, 1)",
              shadowOpacity: 0.2, //was set to .2
              shadowRadius: 15,
              shadowOffset: {
                height: 0,
                width: -2,
              },
            }}
          >
            <View //Help & Profile
              style={{
                flexDirection: "row",
                width: "100%",
                paddingRight: "3%",
                justifyContent: "flex-end",
                paddingTop: 20,
                marginBottom: -20,
                zIndex: 100,
              }}
            >
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowHelp(true)}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered ? colors.greySidebar : "white",
                      paddingHorizontal: 20,
                      marginHorizontal: 2.5,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="help-circle-outline"
                      size={25}
                      color={colors.greyDark}
                    />
                    <Text
                      style={{
                        paddingLeft: 10,
                        fontSize: 15,
                        fontFamily: "Inter_500Medium",
                        color: colors.greyDark,
                      }}
                    >
                      Help
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowProfileInfo(true)}
                    style={{
                      borderRadius: 10,
                      backgroundColor: hovered ? colors.greySidebar : "white",
                      paddingHorizontal: 20,
                      marginHorizontal: 2.5,
                      height: 45,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Ionicons
                      name="person-outline"
                      size={25}
                      color={colors.greyDark}
                    />
                    <Text
                      style={{
                        paddingLeft: 10,
                        fontSize: 15,
                        fontFamily: "Inter_500Medium",
                        color: colors.greyDark,
                      }}
                    >
                      Profile
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "13%",
              }}
            ></View>
            {showPrediction && (
              <View
                style={{
                  marginTop: "-10%",
                  alignItems: "flex-start",
                  paddingHorizontal: "7%",
                  paddingBottom: "5%",
                }}
              >
                <View style={{ width: "100%" }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 16,
                        color: colors.greyDark,
                      }}
                    >
                      Confidence Threshold:
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 16,
                        color: colors.blueLabelBorder,
                        marginLeft: 5,
                      }}
                    >
                      {confidenceThreshold}
                    </Text>
                  </View>
                  <Slider
                    value={50}
                    step={1}
                    thumbTintColor={colors.blueLabelBorder}
                    minimumTrackTintColor={colors.greyDark}
                    maximumTrackTintColor={colors.greyCancel}
                    onValueChange={(value) => setConfidenceThreshold(value)}
                    minimumValue={1}
                    maximumValue={100}
                  />
                </View>

                <View
                  style={{
                    marginTop: 10,
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Hoverable
                    style={{ width: "100%" }}
                    onMouseEnter={() =>
                      premiumStatus != "premium" && setDisabledPowerLabel(true)
                    }
                    onMouseLeave={() => setDisabledPowerLabel(false)}
                  >
                    {({ hovered }) => (
                      <Pressable
                        disabled={
                          premiumStatus != "premium"
                            ? true
                            : strength == "Weak"
                            ? true
                            : numberOfImages.length - numberOfImagesInModel == 0
                            ? true
                            : false
                        }
                        onPress={() => (
                          setPressedPowerLabel(true),
                          setSliderMax(
                            numberOfImages.length - numberOfImagesInModel > 499
                              ? 500
                              : numberOfImages.length - numberOfImagesInModel
                          )
                        )}
                        style={{
                          height: 50,
                          borderRadius: 5,
                          alignItems: "center",
                          justifyContent: "center",
                          shadowColor: "black",
                          shadowOpacity:
                            strength == "Weak"
                              ? 0
                              : numberOfImages.length - numberOfImagesInModel ==
                                0
                              ? 0
                              : hovered
                              ? 0.3
                              : 0.1,
                          shadowRadius: 25,
                          shadowOffset: {
                            height: 7,
                            width: 1,
                          },
                        }}
                      >
                        <LinearGradient
                          style={{
                            height: "100%",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 5,
                          }}
                          start={{ x: 0, y: 0 }}
                          end={{ x: 0.5, y: 0.5 }}
                          colors={
                            strength == "Weak"
                              ? [colors.greyDisabled, colors.greyDisabled]
                              : numberOfImages.length - numberOfImagesInModel ==
                                0
                              ? [colors.greyDisabled, colors.greyDisabled]
                              : [colors.red, colors.purpleLabelBorder]
                          }
                        >
                          {premiumStatus == "premium" && strength == "Weak" ? (
                            <Text //Power Label deactive weak (Premium)
                              style={{
                                fontFamily: "Inter_700Bold",
                                fontSize: 16,
                                color: colors.greyLightFont,
                              }}
                            >
                              Power Label
                            </Text>
                          ) : premiumStatus == "premium" &&
                            numberOfImages.length - numberOfImagesInModel ==
                              0 ? (
                            <Text //Power Label deactive no images (Premium)
                              style={{
                                fontFamily: "Inter_700Bold",
                                fontSize: 16,
                                color: colors.greyLightFont,
                              }}
                            >
                              Power Label
                            </Text>
                          ) : (
                            premiumStatus == "premium" && (
                              <Text //Power label active (Premium)
                                style={{
                                  fontFamily: "Inter_700Bold",
                                  fontSize: 16,
                                  color: "white",
                                }}
                              >
                                Power Label ⚡️
                              </Text>
                            )
                          )}
                          {premiumStatus != "premium" &&
                            !disabledPowerLabel && (
                              <Text //Power Label deactive weak (Premium)
                                style={{
                                  fontFamily: "Inter_700Bold",
                                  fontSize: 16,
                                  color: colors.greyLightFont,
                                }}
                              >
                                Power Label (disabled)
                              </Text>
                            )}
                          {premiumStatus != "premium" && disabledPowerLabel && (
                            <Text //Power Label deactive weak (Premium)
                              style={{
                                fontFamily: "Inter_700Bold",
                                fontSize: 16,
                                color: colors.redError,
                              }}
                            >
                              Premium Feature
                            </Text>
                          )}
                        </LinearGradient>
                      </Pressable>
                    )}
                  </Hoverable>
                </View>
              </View>
            )}
            <View
              style={{ height: "25%", padding: "7%", alignItems: "center" }}
            >
              <View
                style={{
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      backgroundColor: colors.greyDisabled,
                      height: "100%",
                      width: "47.5%",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      shadowColor: "rgba(179, 179, 179, 1)",
                      shadowOpacity: 0,
                      shadowRadius: 5,
                      shadowOffset: { height: 2, width: 2 },
                    }}
                  >
                    {imagesUploaded != null && (
                      <Text
                        style={{
                          fontFamily: "Inter_700Bold",
                          fontSize: 30,
                          color: colors.greyDark,
                        }}
                      >
                        {numberOfImages.length}
                      </Text>
                    )}
                    <Text
                      style={{
                        fontFamily: "Inter_500Medium",
                        fontSize: 15,
                        color: colors.greyDark,
                      }}
                    >
                      Total Images
                    </Text>
                  </View>
                  <View style={{ width: "5%" }} />
                  <View
                    style={{
                      backgroundColor: colors.greyDisabled,
                      height: "100%",
                      width: "47.5%",
                      borderRadius: 5,
                      justifyContent: "center",
                      alignItems: "center",
                      shadowColor: "rgba(179, 179, 179, 1)",
                      shadowOpacity: 0,
                      shadowRadius: 5,
                      shadowOffset: { height: 2, width: 2 },
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 30,
                        color: colors.greyDark,
                      }}
                    >
                      {numberOfImagesInModel}
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter_500Medium",
                        fontSize: 15,
                        color: colors.greyDark,
                      }}
                    >
                      Labeled
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ height: 15 }} />
              <View
                style={{
                  backgroundColor: colors.greyDisabled,
                  height: "100%",
                  width: "100%",
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  shadowColor: "rgba(179, 179, 179, 1)",
                  shadowOpacity: 0,
                  shadowRadius: 5,
                  shadowOffset: { height: 2, width: 2 },
                }}
              >
                {imagesUploaded != null && (
                  <Text
                    style={{
                      fontFamily: "Inter_700Bold",
                      fontSize: 30,
                      color: colors.greyDark,
                    }}
                  >
                    {numberOfImages.length - numberOfImagesInModel}
                  </Text>
                )}
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: colors.greyDark,
                  }}
                >
                  Remaining
                </Text>
              </View>
              <View
                style={{
                  paddingTop: 20,
                  width: "100%",
                }}
              >
                {tasks.map((task, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        width: "100%",
                        alignItems: "flex-start",
                        marginVertical: 3,
                      }}
                    >
                      <LabelTracker task={task} tasks={tasks} />
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
