import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
  useContext,
} from "react";
import {
  FlatList,
  Text,
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
  TextInput,
  Dimensions,
} from "react-native";

import { Overlay } from "react-native-elements";
import * as ImageManipulator from "expo-image-manipulator";
import * as tf from "@tensorflow/tfjs";
import * as mobilenet from "@tensorflow-models/mobilenet";
//import * as use from "@tensorflow-models/universal-sentence-encoder";
import * as knnClassifier from "@tensorflow-models/knn-classifier";
import * as jpeg from "jpeg-js";
import * as png from "fast-png";
import { Ionicons, Feather } from "@expo/vector-icons";
import { Hoverable, Pressable } from "react-native-web-hover";
import * as Animatable from "react-native-animatable";
import LottieView from "react-native-web-lottie";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Slider } from "@sharcoux/slider";
import { Picker } from "@react-native-picker/picker";
import { colors } from "../Styles/colors";
import { labelStrongColors } from "../Styles/LabelStrongColors";
import { labelWeakColors } from "../Styles/LabelWeakColors";
import { AuthContext } from "../References/Auth";
import ProfileInfo from "../Components/ProfileInfo";
import SignUp_Editor from "../Components/SignUp_Editor";
import LogIn_Editor from "../Components/LogIn_Editor";
import HelpInfo from "../Components/HelpInfo";
import { createCheckoutSession } from "../Stripe/createCheckoutSession";
import {
  ENV_ENCR_KEY,
  ENV_POST_SUBSCR,
  ENV_POST_CANCEL_SUBSCR,
  ENV_ST_MO,
} from "@env";
import hexToRgba from "hex-to-rgba";
//import { pipeline, env } from "@xenova/transformers";

import { LinearGradient } from "expo-linear-gradient";
import { deviceType } from "react-device-detect";
import { Vehicles_bike_skateboard_surfboard } from "../DemoImages/ListReferences/Vehicles_bike_skateboard_surfboard";
import { Agriculture_ripe_fruit } from "../DemoImages/ListReferences/Agriculture_ripe_fruit";
import { Medicine_mask_detection } from "../DemoImages/ListReferences/Medicine_mask_detection";
import { Animals_dog_cat } from "../DemoImages/ListReferences/Animals_dog_cat";
import { Vehicle_street_signs } from "../DemoImages/ListReferences/Vehicle_street_signs";
import { Photography_smile_detection } from "../DemoImages/ListReferences/Photography_smile_detection";
import { Nature_weather } from "../DemoImages/ListReferences/Nature_weather";
import { Manufacturing_defects } from "../DemoImages/ListReferences/Manufacturing_defects";
import { Agriculture_pest_detection } from "../DemoImages/ListReferences/Agriculture_pest_detection";
import { Medicine_hair_loss } from "../DemoImages/ListReferences/Medicine_hair_loss";

import SmallPopUp from "../Components/SmallPopUp";
import MainButton from "../Components/MainButton";
import SecondaryButton from "../Components/SecondaryButton";

import {
  boxesIntersect,
  useSelectionContainer,
} from "@air/react-drag-to-select";

var cryptorjs = require("cryptorjs");
var myCryptor = new cryptorjs(ENV_ENCR_KEY);

import { auth, db } from "../References/firebase";
import { set } from "date-fns";

const { width: width } = Dimensions.get("window");
const { height: height } = Dimensions.get("window");

export default function Demo({ navigation }) {
  const [isTfReady, setIsTfReady] = useState(false);
  const [mobilenetModel, setMobilenetModel] = useState(null);
  const [useModel, setUseModel] = useState(null);

  const [knnClassifierModel, setKnnClassifierModel] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [prediction, setPrediction] = useState({
    label: "No Results",
    confidence: {},
  });
  const [predictionConfidence, setPredictionConfidence] = useState(0);
  const [isPredicting, setIsPredicting] = useState(false);
  const [showPrediction, setShowPrediction] = useState(true);
  const [file, setFile] = useState(null);
  const [category1, setCategory1] = useState([]);
  const [category2, setCategory2] = useState([]);
  const [category3, setCategory3] = useState([]);
  const [category4, setCategory4] = useState([]);
  const [category5, setCategory5] = useState([]);
  const [category1Count, setCategory1Count] = useState(0);
  const [category2Count, setCategory2Count] = useState(0);
  const [category3Count, setCategory3Count] = useState(0);
  const [category4Count, setCategory4Count] = useState(0);
  const [category5Count, setCategory5Count] = useState(0);
  const [loadedModel, setLoadedModel] = useState(false);
  const [powerLabelPredictionImages, setPowerLabelPredictionImages] = useState(
    []
  );
  const [index, setIndex] = useState(0);
  const [numberOfImagesInModel, setNumberOfImagesInModel] = useState(0);
  const [streak, setStreak] = useState(0);
  const [strength, setStrength] = useState("Weak"); //set to weak
  const [numberOfFeatures, setNumberOfFeatures] = useState(0);
  const [noMoreImages, setNoMoreImages] = useState(false);
  const [pressedRestart, setPressedRestart] = useState(false);
  const [numberOfImages, setNumberOfImages] = useState(
    Agriculture_pest_detection.length
  );
  const [processingClick, setProcessingClick] = useState(false);
  const [pressedPowerLabel, setPressedPowerLabel] = useState(false);
  const [sliderValue, setSliderValue] = useState(1);
  const [sliderMax, setSliderMax] = useState(0);
  const [beginPowerLabel, setBeginPowerLabel] = useState(false);
  const [imageIssue, setImageIssue] = useState(false);
  const ref = useRef(null);
  const [donePowerLabelCollect, setDonePowerLabelCollect] = useState(false);
  const [labels, setLabels] = useState(["Normal", "Pest_Damage"]);
  const [goHome, setGoHome] = useState(false);
  const [disabledAddImages, setDisabledAddImages] = useState(false);
  const [disabledLoadModel, setDisabledLoadModel] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const [demoLoading, setDemoLoading] = useState(true); //set to true
  const { signInCheck } = useContext(AuthContext);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogIn, setShowLogIn] = useState(false);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [finishedPowerLabel, setFinishedPowerLabel] = useState(false);
  const [screenSizeError, setScreenSizeError] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showingCheckout, setShowingCheckout] = useState(false);
  const [premiumStatus, setPremiumStatus] = useState(false);
  const [showHelpIndicator, setShowHelpIndicator] = useState(true);
  const [showLabelIndicator, setShowLabelIndicator] = useState(true);
  const [showSpeedLabel, setShowSpeedLabel] = useState(false);
  const [selectedSpeedLabel, setSelectedSpeedLabel] = useState("-");
  const [notBrowser, setNotBrowser] = useState(false);
  const [changingUseCase, setChangingUseCase] = useState(false);
  const [speedLabelImages, setSpeedLabelImages] = useState([]);
  const [fastLabelCategory1, setFastLabelCategory1] = useState([]);
  const [fastLabelCategory2, setFastLabelCategory2] = useState([]);
  const [fastLabelCategory3, setFastLabelCategory3] = useState([]);
  const [fastLabelCategory4, setFastLabelCategory4] = useState([]);
  const [fastLabelCategory5, setFastLabelCategory5] = useState([]);
  const refFastLabelCategory1 = useRef(fastLabelCategory1);
  const refFastLabelCategory2 = useRef(fastLabelCategory2);
  const refFastLabelCategory3 = useRef(fastLabelCategory3);
  const refFastLabelCategory4 = useRef(fastLabelCategory4);
  const refFastLabelCategory5 = useRef(fastLabelCategory5);
  const [imagesSuccessfullyUploaded, setImagesSuccessfullyUploaded] =
    useState(true);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState(
    Agriculture_pest_detection
  );
  const [imagesForModel, setImagesForModel] = useState(
    Agriculture_pest_detection
  );
  const [completedSpeedLabel, setCompletedSpeedLabel] = useState(false);
  const [totalLabeledInSpeedLabel, setTotalLabeledInSpeedLabel] = useState(0);
  const [delayedTotalLabeledInSpeedLabel, setDelayedTotalLabeledInSpeedLabel] =
    useState(0); //used to determine if additional images were labeled
  const [processedEmbeddings, setProcessedEmbeddings] = useState([]);
  const [selectedSpeedLabelColor, setSelectedSpeedLabelColor] = useState(
    colors.greyDark
  );
  const [speedLabelImagesUploaded, setSpeedLabelImagesUploaded] =
    useState(false);
  const [useCasePicker, setUseCasePicker] = useState(
    Agriculture_pest_detection
  );
  const allow_search = false; //hide search
  const refNumberImages = useRef(numberOfImagesInModel);
  const [selectionBox, setSelectionBox] = useState();
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const refSelectedIndexes = useRef(selectedIndexes);
  const selectableItems = useRef([]);
  const elementsContainerRef = useRef();
  const [pressedReviewImages, setPressedReviewImages] = useState(false);
  const [condensedSidebar, setCondensedSidebar] = useState(false);
  const [sidebarIsMoving, setSidebarIsMoving] = useState(false); //sidebar is expanding or collapsing
  const [remainingCount, setRemainingCount] = useState(imagesForModel?.length);
  const [currentReviewImageFilter, setCurrentReviewImageFilter] =
    useState("All");
  const [currentPowerLabelFilter, setCurrentPowerLabelFilter] = useState("All");
  const [reviewImagesSearch, setReviewImagesSearch] = useState(""); //search value in Review Images
  const [madeASearch, setMadeASearch] = useState(false); //flag to reset the image order when finished searching
  const [executingImageSearch, setExecutingImageSearch] = useState(false); //show loading while searching
  const [restarting, setRestarting] = useState(false); //detect if editor is restarting
  const [dontShowPrediction, setDontShowPrediction] = useState(false); //dont show prediction when sim sort or review images is used
  const [imageForDeletion, setImageForDeletion] = useState(""); //this is the image that will be deleted (via Review Images)
  const [deleteImagePrompt, setDeleteImagePrompt] = useState(false); //this is the popup prompt to confirm image deletion (via Review Images)

  //track metrics everytime the main image dataset is updated
  useEffect(() => {
    const count1 =
      imagesForModel.filter((image) => image.label === labels[0])?.length > 0
        ? imagesForModel.filter((image) => image.label === labels[0])?.length
        : 0;
    const count2 =
      imagesForModel.filter((image) => image.label === labels[1])?.length > 0
        ? imagesForModel.filter((image) => image.label === labels[1])?.length
        : 0;
    const count3 =
      imagesForModel.filter((image) => image.label === labels[2])?.length > 0
        ? imagesForModel.filter((image) => image.label === labels[2])?.length
        : 0;
    const count4 =
      imagesForModel.filter((image) => image.label === labels[3])?.length > 0
        ? imagesForModel.filter((image) => image.label === labels[3])?.length
        : 0;
    const count5 =
      imagesForModel.filter((image) => image.label === labels[4])?.length > 0
        ? imagesForModel.filter((image) => image.label === labels[4])?.length
        : 0;

    !restarting && !changingUseCase && setCategory1Count(count1);
    !restarting && !changingUseCase && setCategory2Count(count2);
    !restarting && !changingUseCase && setCategory3Count(count3);
    !restarting && !changingUseCase && setCategory4Count(count4);
    !restarting && !changingUseCase && setCategory5Count(count5);
    !restarting &&
      !changingUseCase &&
      setNumberOfImagesInModel(count1 + count2 + count3 + count4 + count5);
    !restarting &&
      !changingUseCase &&
      setRemainingCount(
        numberOfImages - (count1 + count2 + count3 + count4 + count5)
      );
  }, [imagesForModel]);

  //delete selected images (used in Review Images)
  function deleteImageFromArray(inputValue) {
    const newArray = imagesForModel.filter((item) => item.image !== inputValue);
    setImagesForModel(newArray);
    setNumberOfImages((prevCount) => prevCount - 1);
    setDeleteImagePrompt(false);
  }

  //reset the image data after searching
  useEffect(() => {
    !pressedReviewImages && setReviewImagesSearch("");
    !pressedReviewImages && setMadeASearch(false);
  }, [pressedReviewImages]);
  //Animations
  const [sidebarAnimation] = useState(new Animated.Value(0));
  const sidebarCondenseOrExpand = sidebarAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [280, 85],
  });
  const sidebarButtonWidth = sidebarAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [280, 45],
  });
  const centerPanelCondenseOrExpand = sidebarAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [width - 280, width - 85],
  });
  const sidebarOpacity = sidebarAnimation.interpolate({
    inputRange: [0, 0.5],
    outputRange: [1, 0],
  });
  const reverseSidebarOpacity = sidebarAnimation.interpolate({
    inputRange: [0, 0.5],
    outputRange: [0, 1],
  });
  const startAnimation = () => {
    setSidebarIsMoving(true);
    Animated.timing(sidebarAnimation, {
      toValue: condensedSidebar ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
    setTimeout(() => {
      setSidebarIsMoving(false);
      setCondensedSidebar(!condensedSidebar);
    }, 300);
  };
  //Initial ref to fade out loading
  const viewRef = useRef(null);
  //main ref to fade out loading
  const handleViewRef = (ref) => {
    viewRef.current = ref;
  };
  //function to fade out loading
  const fadeOut = () => {
    viewRef.current.fadeOut(500);
  };

  //Review images press
  useEffect(() => {
    function dataToReviewImages() {
      const images = [];
      for (let i = 0; i < imagesForModel?.length; i++) {
        const object = {};
        const image = imagesForModel[i].image;
        object.image = image;
      }
    }
    pressedReviewImages && dataToReviewImages; //only run if the Review Image overlay is open
  }, [pressedReviewImages]);

  const searchImages = async (searchText) => {
    setExecutingImageSearch(true);
    setMadeASearch(true);
    // Encode the text query using the Universal Sentence Encoder
    const textEmbeddings = await useModel.embed(searchText);

    for (let i = 0; i < imagesForModel?.length; i++) {
      imagesForModel[i].searchSimilarity == null &&
        recordOriginalDataLoadedOrder(i, i); //record the original order of images
      const response =
        imagesForModel[i].embeddings == null &&
        (await fetch(imagesForModel[i].image, { isBinary: true }));
      const rawImageData =
        imagesForModel[i].embeddings == null && (await response.arrayBuffer());
      const imageTensor =
        imagesForModel[i].embeddings == null && imageToTensor(rawImageData);
      let embeddings =
        imagesForModel[i].embeddings == null &&
        (await mobilenetModel.infer(imageTensor, true).slice([0, 0], [1, 512]));
      const cosineSimilarity =
        imagesForModel[i].embeddings == null
          ? await tf.losses.cosineDistance(textEmbeddings, embeddings).data()
          : await tf.losses
              .cosineDistance(textEmbeddings, imagesForModel[i].embeddings)
              .data();
      imagesForModel[i].embeddings == null &&
        changeEmbeddingsInDataset(embeddings, i); //update the similarity score
      changeSemanticSearchScoreInDataset(cosineSimilarity[0], i); //update the similarity score
    }
    setExecutingImageSearch(false);
  };

  //search images in Review Images
  const searchImages2 = async (searchText) => {
    setExecutingImageSearch(true);
    setMadeASearch(true);

    // Encode the text query using the Universal Sentence Encoder
    const textEmbeddings = await useModel.embed(searchText);

    // Allocate a pipeline for sentiment-analysis
    let pipe = await pipeline("image-to-text");

    const images = await Promise.all(
      imagesForModel.map(async (item) => {
        const label = await pipe(await geturi(item.image), {
          max_new_tokens: 40,
          num_beams: 2,
          num_return_sequences: 2,
          top_k: 0,
          do_sample: false,
        }).then(function (result) {
          console.log(result[0].generated_text);
        });
      })
    );

    setExecutingImageSearch(false);
    // console.log(imagesForModel.sort(dynamicSort("-searchSimilarity")));
  };

  //search images in Review Images
  const searchImages3 = async (searchText) => {
    setExecutingImageSearch(true);
    setMadeASearch(true);

    // Encode the text query using the Universal Sentence Encoder
    const textEmbeddings = await useModel.embed(searchText);
    const zeroTensor = tf.zeros([1, 512]);

    for (let i = 0; i < imagesForModel?.length; i++) {
      recordOriginalDataLoadedOrder(i, i); //record the original order of images
      const response = await fetch(imagesForModel[i].image, { isBinary: true });
      const rawImageData = await response.arrayBuffer();
      const imageTensor = imageToTensor(rawImageData);
      let embeddings = await mobilenetModel.infer(imageTensor, true);

      // Reduce the dimensions of the embeddings from MobileNet
      embeddings = embeddings.slice([0, 0], [1, 512]);

      // Concatenate the text embeddings and the reduced image embeddings
      const concatenatedEmbeddings = tf.concat(
        [textEmbeddings, zeroTensor.add(embeddings)],
        1
      );

      const cosineSimilarity = await tf.losses
        .cosineDistance(concatenatedEmbeddings, concatenatedEmbeddings)
        .data();
      //console.log(cosineSimilarity);
      changeEmbeddingsInDataset(embeddings, i); //update the similarity score
      changeSemanticSearchScoreInDataset(cosineSimilarity[0], i); //update the similarity score
    }

    setExecutingImageSearch(false);
  };

  //Draggable box
  const { DragSelection } = useSelectionContainer({
    onSelectionChange: (box) => {
      setSelectionBox(box);
      const indexesToSelect = [];
      selectableItems.current.forEach((item, index) => {
        if (boxesIntersect(box, item)) {
          indexesToSelect.push(index);
        }
      });
      setSelectedIndexes(indexesToSelect);
    },
    onSelectionEnd: () => {
      {
        const labeledImages = [];
        for (let i = 0; i < selectedIndexes?.length; i++) {
          labeledImages.push(speedLabelImages[selectedIndexes[i]]?.image);
        }

        selectedIndexes?.length > 0 &&
          dragSelectSimSort(selectedSpeedLabel, labeledImages);
      }
      setSelectedIndexes([]);
    },
    selectionProps: {
      style: {
        borderRadius: 4,
        backgroundColor: hexToRgba(colors.greyDark, "0.4"),
        opacity: 0.5,
      },
    },
    isEnabled: showSpeedLabel,
  });
  //detect the location of each image in Sim Sort
  useEffect(() => {
    if (elementsContainerRef.current) {
      Array.from(elementsContainerRef.current.children).forEach((item) => {
        const { left, top, width, height } = item.getBoundingClientRect();
        selectableItems.current.push({
          left,
          top,
          width,
          height,
        });
      });
    }
  }, [speedLabelImagesUploaded]);

  // Sort function to arrange the Similarity Sort images
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  // Multi-field sort function to arrange the Similarity Sort images
  function dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
      var i = 0,
        result = 0,
        numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while (result === 0 && i < numberOfProperties) {
        result = dynamicSort(props[i])(obj1, obj2);
        i++;
      }
      return result;
    };
  }
  function getHighestScoringLabel(objects) {
    let highestScore = -Infinity;
    let highestScoringLabel = "";

    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (object.score > highestScore) {
        highestScore = object.score;
        highestScoringLabel = object.label;
      }
    }

    return highestScoringLabel;
  }
  function getHighestScore(objects) {
    let highestScore = -Infinity;
    let highestScoringLabel = "";

    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      if (object.score > highestScore) {
        highestScore = object.score;
        highestScoringLabel = object.label;
      }
    }

    return highestScore;
  }
  //compare embeddings for Sim Sort
  function cosineSimilarity(embedding1, embedding2) {
    const dotProduct = tf.dot(embedding1, embedding2);
    const norm1 = tf.norm(embedding1);
    const norm2 = tf.norm(embedding2);
    const cosineSimilarity = dotProduct.div(norm1.mul(norm2));
    return cosineSimilarity.dataSync()[0];
  }

  //change the use case
  const changeUseCase = (imageData, relevantLabels) => {
    const executeUseCaseChange = async () => {
      setImagesForModel([]);
      setImagesForModel(imageData);
      setChangingUseCase(true);
      setSpeedLabelImages([]);
      setStreak(0);
      setNoMoreImages(false);
      setSliderValue(1);
      setStrength("Weak");
      setFinishedPowerLabel(false);
      setNumberOfFeatures(0);
      setPressedRestart(false);
      setCategory1([]);
      setCategory2([]);
      setCategory3([]);
      setCategory4([]);
      setCategory5([]);
      setCategory1Count(0);
      setCategory2Count(0);
      setCategory3Count(0);
      setCategory4Count(0);
      setCategory5Count(0);
      setFastLabelCategory1([]);
      setFastLabelCategory2([]);
      setFastLabelCategory3([]);
      setFastLabelCategory4([]);
      setFastLabelCategory5([]);
      setNumberOfImagesInModel(0);

      setImagesUploaded(imageData);
      setLabels(relevantLabels);
      setNumberOfImages(imageData.length);
      setRemainingCount(imageData.length);

      try {
        await knnClassifierModel.clearAllClasses();
      } catch {}

      setTimeout(() => {
        setChangingUseCase(false);
      }, 500);
    };
    executeUseCaseChange();
  };

  //change the images and labels (and reset metrics) when the use case changes
  useEffect(() => {
    useCasePicker == "Vehicles (bike, skateboard, surfboard)" &&
      changeUseCase(Vehicles_bike_skateboard_surfboard, [
        "Bicycle",
        "Skateboard",
        "Surfboard",
      ]);

    useCasePicker == "Agriculture (ripe fruit)" &&
      changeUseCase(Agriculture_ripe_fruit, ["Green", "Ripe", "Aged"]);

    useCasePicker == "Medicine (mask detection)" &&
      changeUseCase(Medicine_mask_detection, ["Mask", "No_Mask"]);

    useCasePicker == "Animals (dog, cat)" &&
      changeUseCase(Animals_dog_cat, ["Dog", "Cat"]);

    useCasePicker == "Vehicles (street signs)" &&
      changeUseCase(Vehicle_street_signs, ["Speed_Limit", "Stop", "Dead_End"]);

    useCasePicker == "Photography (smile detection)" &&
      changeUseCase(Photography_smile_detection, ["Smile", "No_Smile"]);

    useCasePicker == "Nature (weather)" &&
      changeUseCase(Nature_weather, ["Snow", "Rain", "Lightning"]);

    useCasePicker == "Manufacturing (defects)" &&
      changeUseCase(Manufacturing_defects, ["OK", "Defective"]);

    useCasePicker == "Medicine (hair loss)" &&
      changeUseCase(Medicine_hair_loss, ["No_Hairloss", "Hairloss"]);

    useCasePicker == "Agriculture (pest detection)" &&
      changeUseCase(Agriculture_pest_detection, ["Normal", "Pest_Damage"]);
  }, [useCasePicker]);

  //check if the user is premium
  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch {
      console.log();
    }
  }
  //run premium check
  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });
  //close the profile window
  function closeProfile() {
    setShowProfileInfo(false);
  }
  //create current state ref
  useEffect(() => {
    refNumberImages.current = numberOfImagesInModel;
    refFastLabelCategory1.current = fastLabelCategory1;
    refFastLabelCategory2.current = fastLabelCategory2;
    refFastLabelCategory3.current = fastLabelCategory3;
    refFastLabelCategory4.current = fastLabelCategory4;
    refFastLabelCategory5.current = fastLabelCategory5;
    refSelectedIndexes.current = selectedIndexes;
  });

  //check if user is signed in
  useEffect(() => {
    showProfileInfo && setShowProfileInfo(false);
    showLogIn && setShowLogIn(false);
    showSignUp && setShowSignUp(false);
  }, [signInCheck]);

  //get the uri of an image
  async function geturi(data) {
    const allData = await fetch(data);
    const blob = await allData.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const preresolve = reader.result;
        resolve(preresolve);
      };
    });
  }

  //load tensorflow
  useEffect(() => {
    async function startup() {
      if (!isTfReady) {
        await tf.ready();
        setIsTfReady(true);
        setMobilenetModel(await mobilenet.load()); //load image model
        //setUseModel(await use.load({ outputEmbeddingDimension: 1024 })); //load text encoding model (make sure the model embeddings are the same size as mobilenet - 1024)
        setKnnClassifierModel(await knnClassifier.create()); //create classifier
      }
    }
    startup();
  }, [isTfReady]);

  //reduce image size
  async function compress(uri, width, height) {
    const actions = [
      {
        resize: {
          width,
          height,
        },
      },
    ];
    const saveOptions = {
      compress: 0.5,
      format: ImageManipulator.SaveFormat.JPEG,
      base64: true,
    };
    const res = await ImageManipulator.manipulateAsync(
      uri,
      actions,
      saveOptions
    );
    return res;
  }

  Array.prototype.max = function () {
    return Math.max.apply(null, this);
  };

  //function to convert image to tensor
  const imageToTensor = (rawImageData) => {
    const TO_UINT8ARRAY = true;
    try {
      const { width, height, data } = jpeg.decode(rawImageData, TO_UINT8ARRAY);
      // Drop the alpha channel info for mobilenet
      const buffer = new Uint8Array(width * height * 3);
      let offset = 0; // offset into original data
      for (let i = 0; i < buffer.length; i += 3) {
        buffer[i] = data[offset];
        buffer[i + 1] = data[offset + 1];
        buffer[i + 2] = data[offset + 2];

        offset += 4;
      }

      return tf.tensor3d(buffer, [height, width, 3]);
    } catch {
      const { width, height, data } = png.decode(rawImageData, TO_UINT8ARRAY);
      // Drop the alpha channel info for mobilenet
      const buffer = new Uint8Array(width * height * 3);
      let offset = 0; // offset into original data
      for (let i = 0; i < buffer.length; i += 3) {
        buffer[i] = data[offset];
        buffer[i + 1] = data[offset + 1];
        buffer[i + 2] = data[offset + 2];

        offset += 4;
      }

      return tf.tensor3d(buffer, [height, width, 3]);
    }
  };

  const desktopGetPredictions = async (specificImage, labels) => {
    try {
      setIsPredicting(true);
      const response = await fetch(specificImage, {}, { isBinary: true }); //prep image for tensor
      const rawImageData = await response.arrayBuffer(); //prep image for tensor
      const imageTensor = imageToTensor(rawImageData); //get tensor
      let embeddings = await mobilenetModel.infer(imageTensor, true); //get embeddings from mobilenet
      let prediction = await knnClassifierModel.predictClass(embeddings); //predict with knn classifier

      setPrediction(prediction);

      setPredictionConfidence(
        (Object.values(prediction.confidences).max() * 100).toFixed(0)
      );
      setNumberOfFeatures(Object.values(prediction.confidences).length);
      setIsPredicting(false);
    } catch (e) {
      setIsPredicting(false);
    }
  };
  const changePicker = (image, newValue) => {
    const newList = powerLabelPredictionImages.map((item) => {
      if (item.image === image) {
        const updatedItem = {
          ...item,
          prediction: newValue,
        };
        return updatedItem;
      }

      return item;
    });
    setPowerLabelPredictionImages(newList);
  };

  //powerlabel: add images to the model
  const powerLabelCollectData = async (powerLabelPrediction_Output) => {
    try {
      setIsPredicting(true);

      for (let i = 0; i < powerLabelPrediction_Output.length; i++) {
        try {
          const imageFromList = powerLabelPrediction_Output[i].image; //current image
          const className = powerLabelPrediction_Output[i].prediction; //current prediction
          const response = await fetch(imageFromList, {}, { isBinary: true }); //prep image for tensor
          const rawImageData = await response.arrayBuffer(); //prep image for tensor
          const imageTensor = imageToTensor(rawImageData); //get tensor
          let embeddings = await mobilenetModel.infer(imageTensor, true); //get embeddings from mobilenet
          knnClassifierModel.addExample(embeddings, className); //train knn classifier

          //change the label in the image dataset to the selected label
          changeLabelInDataset(
            className,
            imagesForModel.indexOf(
              imagesForModel.filter((image) => image.image === imageFromList)[0]
            )
          );
        } catch (e) {}
      }

      setTimeout(() => {
        setSliderValue(1);
      }, 250);
      setTimeout(() => {
        remainingCount > 0 &&
          desktopGetPredictions(
            imagesForModel.filter((image) => image.label === "Unlabeled")[0]
              ?.image,
            labels
          );
      }, 500);
      setDonePowerLabelCollect(true);
    } catch (e) {}
  };

  //powerlabel: make predictions with the model
  const powerLabelPredictions = async (image_list, labels) => {
    const array = [];
    !donePowerLabelCollect && setIsPredicting(true);
    try {
      for (let i = 0; i < image_list.length; i++) {
        try {
          const imageFromList = image_list[i].image;
          const response = await fetch(imageFromList, {}, { isBinary: true });
          const rawImageData = await response.arrayBuffer();
          const imageTensor = await imageToTensor(rawImageData);
          //get embeddings from mobilenet
          let embeddings = await mobilenetModel.infer(imageTensor, true);
          //predict with knn classifier
          let prediction = await knnClassifierModel.predictClass(embeddings);
          const object = {};
          object.image = image_list[i].image;
          object.prediction = prediction.label;
          array.push(object);
          setPowerLabelPredictionImages(array);
        } catch (e) {
          const object = {};
          object.image = image_list[i].image;
          object.prediction = "Image Issue";
          array.push(object);
          setPowerLabelPredictionImages(array);
        }
      }
    } catch (e) {}
    !donePowerLabelCollect && setIsPredicting(false);
  };

  //reset the editor
  const resetKnnClassifierModel = async () => {
    window.location.reload();
  };

  //export
  const saveKnnClassifierModel = async () => {
    try {
      setStartDownload(true);
      //Need to first convert uri to blob
      function dataURItoBlob(dataURI) {
        // Convert Base64 to raw binary data held in a string.

        var byteString = atob(dataURI.split(",")[1]);
        // Separate the MIME component.
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // Write the bytes of the string to an ArrayBuffer.
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        // Write the ArrayBuffer to a BLOB and you're done.
        var bb = new Blob([ab]);

        return bb;
      }

      //Then Zip
      const zip = new JSZip();

      const modelFolder = zip.folder("Bolt_Model");
      const remainderFolder =
        remainingCount > 0 ? zip.folder("Unlabeled_images") : "not used";
      const folder1 = zip.folder(labels[0] + "_images");
      const folder2 = zip.folder(labels[1] + "_images");
      const folder3 =
        labels.length > 2 ? zip.folder(labels[2] + "_images") : "not used";
      const folder4 =
        labels.length > 3 ? zip.folder(labels[3] + "_images") : "not used";
      const folder5 =
        labels.length > 4 ? zip.folder(labels[4] + "_images") : "not used";

      let dataset = knnClassifierModel.getClassifierDataset();
      let stringDataset = JSON.stringify(
        Object.entries(dataset).map(([label, data]) => [
          label,
          Array.from(data.dataSync()),
          data.shape,
        ])
      );
      setDataset(stringDataset);

      const countOfCat1 =
        imagesForModel.filter((image) => image.label === labels[0])?.length > 0
          ? imagesForModel.filter((image) => image.label === labels[0])
              ?.length + ";"
          : "";
      const countOfCat2 =
        imagesForModel.filter((image) => image.label === labels[1])?.length > 0
          ? imagesForModel.filter((image) => image.label === labels[1])
              ?.length + "#"
          : "";
      const countOfCat3 =
        imagesForModel.filter((image) => image.label === labels[2])?.length > 0
          ? imagesForModel.filter((image) => image.label === labels[2])
              ?.length + "@"
          : "";
      const countOfCat4 =
        imagesForModel.filter((image) => image.label === labels[3])?.length > 0
          ? imagesForModel.filter((image) => image.label === labels[3])
              ?.length + "!"
          : "";
      const countOfCat5 =
        imagesForModel.filter((image) => image.label === labels[4])?.length > 0
          ? imagesForModel.filter((image) => image.label === labels[4])
              ?.length + ":"
          : "";

      modelFolder.file(
        "Bolt_Model.txt",
        myCryptor.encode(
          strength +
            " " +
            countOfCat1 +
            countOfCat2 +
            countOfCat3 +
            countOfCat4 +
            countOfCat5 +
            "_" +
            stringDataset
        )
      );

      for (var i = numberOfImagesInModel; i < numberOfImages; i++) {
        let result = await geturi(imagesUploaded[i].image);
        remainingCount > 0 &&
          remainderFolder.file(
            "Unlabeled_" + i + ".jpg",
            dataURItoBlob(result)
          );
      }

      for (
        var i = 0;
        i < imagesForModel.filter((image) => image.label === labels[0])?.length;
        i++
      ) {
        folder1.file(
          labels[0] + "_" + i + ".jpg",
          dataURItoBlob(
            await geturi(
              imagesForModel.filter((image) => image.label === labels[0])[i]
                .image
            )
          )
        );
      }

      for (
        var i = 0;
        i < imagesForModel.filter((image) => image.label === labels[1])?.length;
        i++
      ) {
        folder2.file(
          labels[1] + "_" + i + ".jpg",
          dataURItoBlob(
            await geturi(
              imagesForModel.filter((image) => image.label === labels[1])[i]
                .image
            )
          )
        );
      }

      for (
        var i = 0;
        i < imagesForModel.filter((image) => image.label === labels[2])?.length;
        i++
      ) {
        folder3.file(
          labels[2] + "_" + i + ".jpg",
          dataURItoBlob(
            await geturi(
              imagesForModel.filter((image) => image.label === labels[2])[i]
                .image
            )
          )
        );
      }

      for (
        var i = 0;
        i < imagesForModel.filter((image) => image.label === labels[3])?.length;
        i++
      ) {
        folder4.file(
          labels[3] + "_" + i + ".jpg",
          dataURItoBlob(
            await geturi(
              imagesForModel.filter((image) => image.label === labels[3])[i]
                .image
            )
          )
        );
      }

      for (
        var i = 0;
        i < imagesForModel.filter((image) => image.label === labels[4])?.length;
        i++
      ) {
        folder5.file(
          labels[4] + "_" + i + ".jpg",
          dataURItoBlob(
            await geturi(
              imagesForModel.filter((image) => image.label === labels[4])[i]
                .image
            )
          )
        );
      }

      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "Boltlabel_Classification.zip");
      });
      setTimeout(() => {
        setStartDownload(false);
      }, 2000);
    } catch (e) {
      console.log(e);
      setStartDownload(false);
    }
  };

  //main editor image subitem (for flatlist)
  const Item = ({ imageDetail }) => (
    <Image
      style={{
        height: "100%",
        width: "100%",
      }}
      resizeMode={"cover"}
      source={{ uri: imageDetail }}
    />
  );

  //main editor image item (for flatlist)
  const renderItem = ({ item }) => (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: 500,
        width: 500,
      }}
    >
      <Item imageDetail={item.image} />
    </View>
  );

  //used in main editor flatlist to prevent flickering with each label
  const memorizedValue = useMemo(() => renderItem, [imagesUploaded]);

  //images and dropdown for each image in powerlabel (subitem for flatlist)
  const PowerLabelItem = ({ imageDetail, prediction, powerIndex }) => (
    <View>
      <Image
        style={{ height: 200, width: 200, borderRadius: 5, marginBottom: 5 }}
        source={{ uri: imageDetail }}
      />

      <Picker
        selectedValue={prediction}
        style={{
          zIndex: 2,
          paddingTop: 205,
          paddingBottom: 5,
          marginTop: -205,
          justifyContent: "flex-end",
          borderWidth: 1,
          color:
            prediction == labels[0]
              ? "#00b300"
              : prediction == labels[1]
              ? "#b30047"
              : prediction == labels[2]
              ? "#007acc"
              : prediction == labels[3]
              ? "#9933ff"
              : prediction == labels[4]
              ? "#ff9900"
              : "black",
          textAlign: "center",
          fontFamily: "Inter_700Bold",
          borderColor:
            prediction == labels[0]
              ? colors.greenLabelBorder
              : prediction == labels[1]
              ? colors.redLabelBorder
              : prediction == labels[2]
              ? colors.blueLabelBorder
              : prediction == labels[3]
              ? colors.purpleLabelBorder
              : prediction == labels[4]
              ? colors.orangeLabelBorder
              : "black",
          backgroundColor:
            prediction == labels[0]
              ? "rgba(230, 255, 230,.3)"
              : prediction == labels[1]
              ? "rgba(255, 204, 224,.3)"
              : prediction == labels[2]
              ? "rgba(204, 235, 255,.3)"
              : prediction == labels[3]
              ? "rgba(242, 230, 255,.3)"
              : prediction == labels[4]
              ? "rgba(255, 235, 204,.3)"
              : "transparent",
          borderRadius: 5,
        }}
        onValueChange={(itemValue) => changePicker(imageDetail, itemValue)}
      >
        <Picker.Item
          label={labels[0]}
          value={labels[0]}
          fontFamily={"Inter_500Medium"}
        />
        <Picker.Item
          label={labels[1]}
          value={labels[1]}
          fontFamily={"Inter_500Medium"}
        />
        <Picker.Item
          label={"Image Issue"}
          value={"Image Issue"}
          enabled={false}
        />
        {labels.length > 2 && (
          <Picker.Item
            label={labels[2]}
            value={labels[2]}
            fontFamily={"Inter_500Medium"}
          />
        )}
        {labels.length > 3 && (
          <Picker.Item
            label={labels[3]}
            value={labels[3]}
            fontFamily={"Inter_500Medium"}
          />
        )}
        {labels.length > 4 && (
          <Picker.Item
            label={labels[4]}
            value={labels[4]}
            fontFamily={"Inter_500Medium"}
          />
        )}
      </Picker>
    </View>
  );

  //images and dropdown for each image in powerlabel (item for flatlist)
  const renderPowerLabelItem = ({ item }) => (
    <View
      style={{ margin: 10, alignItems: "center", justifyContent: "center" }}
    >
      <PowerLabelItem
        imageDetail={item.image}
        prediction={item.prediction}
        powerIndex={item.index}
      />
    </View>
  );

  //update the image dataset with a new label
  const changeLabelInDataset = (label, index) => {
    setImagesForModel((prevImages) => {
      const newImages = [...prevImages];
      newImages[index].label = label;
      return newImages;
    });
  };

  //update the text search similarity (for Semantic Search)
  const changeSemanticSearchScoreInDataset = (similarityScore, index) => {
    setImagesForModel((prevImages) => {
      const newImages = [...prevImages];
      newImages[index].searchSimilarity = similarityScore;
      return newImages;
    });
  };
  //update the text search similarity (for Semantic Search)
  const changeEmbeddingsInDataset = (embedding, index) => {
    setImagesForModel((prevImages) => {
      const newImages = [...prevImages];
      newImages[index].embeddings = embedding;
      return newImages;
    });
  };

  //record the original order of images
  const recordOriginalDataLoadedOrder = (loadedOrder, index) => {
    setImagesForModel((prevImages) => {
      const newImages = [...prevImages];
      newImages[index].loadedOrder = loadedOrder;
      return newImages;
    });
  };

  //images and dropdown for each image in review images (item for flatlist)
  const ReviewImagesItem = ({ imageDetail, label }) => (
    <View>
      <Image
        style={{
          height: 120,
          width: 120,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          marginBottom: 5,
        }}
        source={{ uri: imageDetail }}
      />
      <Hoverable
        style={{
          position: "absolute",
          zIndex: 3,
          top: 2,
          right: 2,
          borderRadius: 5,
        }}
      >
        {({ hovered }) => (
          <TouchableOpacity
            style={{
              padding: 5,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              backgroundColor: hovered ? "white" : hexToRgba("#ffffff", 0.7),
            }}
            onPress={() => (
              setImageForDeletion(imageDetail), setDeleteImagePrompt(true)
            )}
          >
            <Feather name="trash-2" size={14} color={"red"} />
          </TouchableOpacity>
        )}
      </Hoverable>
      <Picker
        selectedValue={label}
        style={{
          zIndex: 2,
          paddingTop: 125,
          width: 120,
          paddingBottom: 5,
          marginTop: -125,
          justifyContent: "flex-end",
          borderWidth: 1,
          color:
            label == labels[0]
              ? "#00b300"
              : label == labels[1]
              ? "#b30047"
              : label == labels[2]
              ? "#007acc"
              : label == labels[3]
              ? "#9933ff"
              : label == labels[4]
              ? "#ff9900"
              : colors.greyLightFont,
          textAlign: "center",
          fontFamily: "Inter_700Bold",
          borderColor:
            label == labels[0]
              ? colors.greenLabelBorder
              : label == labels[1]
              ? colors.redLabelBorder
              : label == labels[2]
              ? colors.blueLabelBorder
              : label == labels[3]
              ? colors.purpleLabelBorder
              : label == labels[4]
              ? colors.orangeLabelBorder
              : colors.greyLightFont,
          backgroundColor:
            label == labels[0]
              ? "rgba(230, 255, 230,.3)"
              : label == labels[1]
              ? "rgba(255, 204, 224,.3)"
              : label == labels[2]
              ? "rgba(204, 235, 255,.3)"
              : label == labels[3]
              ? "rgba(242, 230, 255,.3)"
              : label == labels[4]
              ? "rgba(255, 235, 204,.3)"
              : "transparent",
          borderRadius: 5,
        }}
        onValueChange={(itemValue) => (
          reviewImagesDropdown(imageDetail, itemValue, labels),
          setDontShowPrediction(true)
        )}
      >
        <Picker.Item
          label={labels[0]}
          value={labels[0]}
          fontFamily={"Inter_500Medium"}
        />
        <Picker.Item
          label={labels[1]}
          value={labels[1]}
          fontFamily={"Inter_500Medium"}
        />
        {labels.length > 2 && (
          <Picker.Item
            label={labels[2]}
            value={labels[2]}
            fontFamily={"Inter_500Medium"}
          />
        )}
        {labels.length > 3 && (
          <Picker.Item
            label={labels[3]}
            value={labels[3]}
            fontFamily={"Inter_500Medium"}
          />
        )}
        {labels.length > 4 && (
          <Picker.Item
            label={labels[4]}
            value={labels[4]}
            fontFamily={"Inter_500Medium"}
          />
        )}
        <Picker.Item label={"Unlabeled"} value={"Unlabeled"} />
      </Picker>
    </View>
  );

  //flatlist item for Review Images
  const renderReviewImagesItem = ({ item }) => (
    <View
      style={{ margin: 10, alignItems: "center", justifyContent: "center" }}
    >
      <ReviewImagesItem
        imageDetail={item.image}
        label={item.label}
        index={imagesForModel.indexOf(item)}
      />
    </View>
  );

  //used in search for Review Images to prevent flickering
  const memorizedValueReviewImages = useMemo(
    () => renderReviewImagesItem,
    [imagesForModel.sort(dynamicSort("loadedOrder"))]
  );

  //execute sim sort
  const pressedSimSort = async (data) => {
    setSpeedLabelImagesUploaded(false);
    const targetEmbedding = Math.floor(Math.random() * (data.length - 1));
    try {
      const images = await Promise.all(
        data.map(async (item) => {
          const response = await fetch(item.image, {}, { isBinary: true }); //prep image for tensor
          const rawImageData = await response.arrayBuffer(); //prep image for tensor
          const imageTensor = imageToTensor(rawImageData); //get tensor
          let embeddings = await mobilenetModel.infer(imageTensor, true); //get embeddings from mobilenet
          const embeddingData = await embeddings.data(); //get embeddings data for similarity comparison
          const newItem = {
            image: item.image,
            embeddings: embeddingData,
            label: "Unlabeled",
          };
          return newItem;
        })
      );
      setProcessedEmbeddings(images);
      for (let i = 0; i < images.length; i++) {
        const similarity = cosineSimilarity(
          images[targetEmbedding].embeddings,
          images[i].embeddings
        );
        images[i].similarity = similarity;
      }
      setSpeedLabelImages(images.sort(dynamicSort("-similarity")));
      setSpeedLabelImagesUploaded(true);
    } catch {}
  };

  //Sim Sort: try to sort again
  const sortImagesBySimilarity = () => {
    const targetEmbedding = Math.floor(
      Math.random() * (processedEmbeddings.length - 1)
    );
    setSpeedLabelImagesUploaded(false);
    for (let i = 0; i < processedEmbeddings?.length; i++) {
      const similarity = cosineSimilarity(
        processedEmbeddings[targetEmbedding].embeddings,
        processedEmbeddings[i].embeddings
      );
      processedEmbeddings[i].similarity = similarity;
    }
    setSpeedLabelImages(processedEmbeddings.sort(dynamicSort("-similarity")));
    setTimeout(() => {
      setSpeedLabelImagesUploaded(true);
    }, 500);
  };

  //add the labeled images from Similarity Sort to the corresponding label list
  const dragSelectSimSort = (selectedState, images) => {
    for (let i = 0; i < images.length; i++) {
      let updatedState1 = refFastLabelCategory1.current.filter(
        (item) => item !== images[i]
      );
      let updatedState2 = refFastLabelCategory2.current.filter(
        (item) => item !== images[i]
      );
      let updatedState3 = refFastLabelCategory3.current.filter(
        (item) => item !== images[i]
      );
      let updatedState4 = refFastLabelCategory4.current.filter(
        (item) => item !== images[i]
      );
      let updatedState5 = refFastLabelCategory5.current.filter(
        (item) => item !== images[i]
      );

      if (selectedState === labels[0]) {
        updatedState1 = [...updatedState1, images[i]];
      } else if (selectedState === labels[1]) {
        updatedState2 = [...updatedState2, images[i]];
      } else if (selectedState === labels[2]) {
        updatedState3 = [...updatedState3, images[i]];
      } else if (selectedState === labels[3]) {
        updatedState4 = [...updatedState4, images[i]];
      } else {
        updatedState5 = [...updatedState5, images[i]];
      }

      setFastLabelCategory1(updatedState1);
      setFastLabelCategory2(updatedState2);
      setFastLabelCategory3(updatedState3);
      setFastLabelCategory4(updatedState4);
      setFastLabelCategory5(updatedState5);
    }
  };

  //unlabel an image in Sim Sort
  const removeLabelSimSort = (image) => {
    let updatedState1 = refFastLabelCategory1.current.filter(
      (item) => item !== image
    );
    let updatedState2 = refFastLabelCategory2.current.filter(
      (item) => item !== image
    );
    let updatedState3 = refFastLabelCategory3.current.filter(
      (item) => item !== image
    );
    let updatedState4 = refFastLabelCategory4.current.filter(
      (item) => item !== image
    );
    let updatedState5 = refFastLabelCategory5.current.filter(
      (item) => item !== image
    );

    setFastLabelCategory1(updatedState1);
    setFastLabelCategory2(updatedState2);
    setFastLabelCategory3(updatedState3);
    setFastLabelCategory4(updatedState4);
    setFastLabelCategory5(updatedState5);
  };

  //submit selected labels from Sim Sort
  const submitSimSort = async () => {
    setDontShowPrediction(true);
    setSpeedLabelImagesUploaded(false);
    //count the number of images that were labeled
    const allLabeledImages = fastLabelCategory1
      .filter((item) => item !== undefined)
      .concat(
        fastLabelCategory2.filter((item) => item !== undefined),
        fastLabelCategory3.filter((item) => item !== undefined),
        fastLabelCategory4.filter((item) => item !== undefined),
        fastLabelCategory5.filter((item) => item !== undefined)
      );

    //change the label in the image dataset to the selected label

    for (
      let i = 0;
      i < fastLabelCategory1.filter((item) => item !== undefined)?.length;
      i++
    ) {
      changeLabelInDataset(
        labels[0],
        imagesForModel.indexOf(
          imagesForModel.filter(
            (image) => image.image === fastLabelCategory1[i]
          )[0]
        )
      );
    }

    for (
      let i = 0;
      i < fastLabelCategory2.filter((item) => item !== undefined)?.length;
      i++
    ) {
      changeLabelInDataset(
        labels[1],
        imagesForModel.indexOf(
          imagesForModel.filter(
            (image) => image.image === fastLabelCategory2[i]
          )[0]
        )
      );
    }

    for (
      let i = 0;
      i < fastLabelCategory3.filter((item) => item !== undefined)?.length;
      i++
    ) {
      changeLabelInDataset(
        labels[2],
        imagesForModel.indexOf(
          imagesForModel.filter(
            (image) => image.image === fastLabelCategory3[i]
          )[0]
        )
      );
    }
    for (
      let i = 0;
      i < fastLabelCategory4.filter((item) => item !== undefined)?.length;
      i++
    ) {
      changeLabelInDataset(
        labels[3],
        imagesForModel.indexOf(
          imagesForModel.filter(
            (image) => image.image === fastLabelCategory4[i]
          )[0]
        )
      );
    }
    for (
      let i = 0;
      i < fastLabelCategory5.filter((item) => item !== undefined)?.length;
      i++
    ) {
      changeLabelInDataset(
        labels[4],
        imagesForModel.indexOf(
          imagesForModel.filter(
            (image) => image.image === fastLabelCategory5[i]
          )[0]
        )
      );
    }
    setTotalLabeledInSpeedLabel(allLabeledImages.length);

    //serve up the next images for speed label
    (await imagesForModel.length) == allLabeledImages.length
      ? submitAndFinishSimSort()
      : pressedSimSort(
          imagesForModel
            .filter((image) => image?.label === "Unlabeled")
            .slice(0, 27)
        );
  };

  //finish Sim Sort, update metrics, and model
  const submitAndFinishSimSort = async () => {
    //show the loader
    setCompletedSpeedLabel(false);
    setSpeedLabelImagesUploaded(false);

    //convert the images to base64 (for export), save to state, and add to the model
    const cat1 = [];
    const cat2 = [];
    const cat3 = [];
    const cat4 = [];
    const cat5 = [];

    for (
      let i = 0;
      i < fastLabelCategory1.filter((item) => item !== undefined)?.length;
      i++
    ) {
      let cat1Result = await geturi(fastLabelCategory1[i]);
      cat1.push(cat1Result);
      desktopCollectData(labels[0], fastLabelCategory1[i]);
    }
    for (
      let i = 0;
      i < fastLabelCategory2.filter((item) => item !== undefined)?.length;
      i++
    ) {
      let cat2Result = await geturi(fastLabelCategory2[i]);
      cat2.push(cat2Result);
      desktopCollectData(labels[1], fastLabelCategory2[i]);
    }
    for (
      let i = 0;
      i < fastLabelCategory3.filter((item) => item !== undefined)?.length;
      i++
    ) {
      let cat3Result = await geturi(fastLabelCategory3[i]);
      cat3.push(cat3Result);
      desktopCollectData(labels[2], fastLabelCategory3[i]);
    }
    for (
      let i = 0;
      i < fastLabelCategory4.filter((item) => item !== undefined)?.length;
      i++
    ) {
      let cat4Result = await geturi(fastLabelCategory4[i]);
      cat4.push(cat4Result);
      desktopCollectData(labels[3], fastLabelCategory4[i]);
    }
    for (
      let i = 0;
      i < fastLabelCategory5.filter((item) => item !== undefined)?.length;
      i++
    ) {
      let cat5Result = await geturi(fastLabelCategory5[i]);
      cat5.push(cat5Result);
      desktopCollectData(labels[4], fastLabelCategory5[i]);
    }

    //clear out the fast label states
    setFastLabelCategory1([]);
    setFastLabelCategory2([]);
    setFastLabelCategory3([]);
    setFastLabelCategory4([]);
    setFastLabelCategory5([]);

    setSelectedSpeedLabelColor(colors.greyDark),
      setSelectedSpeedLabel("-"),
      setSpeedLabelImagesUploaded(false);

    //close the overlay
    setCompletedSpeedLabel(true);

    //make a record of the number of images recorded (so we know when to update the modal or just close speed label)
    setDelayedTotalLabeledInSpeedLabel(totalLabeledInSpeedLabel);
  };

  //finish Sim Sort (no updates)
  const JustCancelSimSort = () => {
    //clear out the fast label states
    setFastLabelCategory1([]);
    setFastLabelCategory2([]);
    setFastLabelCategory3([]);
    setFastLabelCategory4([]);
    setFastLabelCategory5([]);

    setSelectedSpeedLabelColor(colors.greyDark),
      setSelectedSpeedLabel("-"),
      setSpeedLabelImagesUploaded(false);

    //close the overlay
    setShowSpeedLabel(false);
  };
  const renderFastLabelLabels = ({ item, index }) => (
    <Hoverable style={{ marginHorizontal: 5 }}>
      {({ hovered }) => (
        <TouchableOpacity
          onPress={() => (
            setSelectedSpeedLabel(labels[index]),
            setSelectedSpeedLabelColor(
              labelStrongColors[Object.keys(labelStrongColors)[index]]
            )
          )}
          style={{
            width: 150,
            height: 30,
            borderRadius: 5,
            borderWidth: 1,
            borderColor:
              labelStrongColors[Object.keys(labelStrongColors)[index]],
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: hovered
              ? labelWeakColors[Object.keys(labelWeakColors)[index]]
              : item == selectedSpeedLabel
              ? labelWeakColors[Object.keys(labelWeakColors)[index]]
              : "white",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontFamily: "Inter_600SemiBold",
              selectable: false,
              suppressHighlighting: true,
              color: labelStrongColors[Object.keys(labelStrongColors)[index]],
            }}
          >
            {item}
          </Text>
        </TouchableOpacity>
      )}
    </Hoverable>
  );
  const finishPowerLabel = () => {
    const array = [];
    for (let i = 0; i < powerLabelPredictionImages.length; i++) {
      array.push(powerLabelPredictionImages[i].prediction);
    }

    array.includes("Image Issue")
      ? setImageIssue(true)
      : powerLabelCollectData(powerLabelPredictionImages);
    setCurrentPowerLabelFilter("All");
    setFinishedPowerLabel(true);
  };

  const animation = useRef(null);
  const desktopGetPredictions_Button = async (specificImage, labels) => {
    try {
      setIsPredicting(true);
      const response = await fetch(specificImage, {}, { isBinary: true });
      const rawImageData = await response.arrayBuffer();
      const imageTensor = imageToTensor(rawImageData);
      //get embeddings from mobilenet
      let embeddings = await mobilenetModel.infer(imageTensor, true);
      //predict with knn classifier
      let prediction = await knnClassifierModel.predictClass(embeddings);

      setPrediction(prediction);

      setPredictionConfidence(
        (Object.values(prediction.confidences).max() * 100).toFixed(0)
      );
      setNumberOfFeatures(Object.values(prediction.confidences).length);
      setIsPredicting(false);
    } catch (e) {
      setIsPredicting(false);
    }
  };
  const desktopCollectData = async (className, specificImage) => {
    try {
      const response = await fetch(specificImage, {}, { isBinary: true });
      const rawImageData = await response.arrayBuffer();
      const imageTensor = imageToTensor(rawImageData);
      let embeddings = await mobilenetModel.infer(imageTensor, true); //get embeddings from mobilenet
      knnClassifierModel.addExample(embeddings, className); //train knn classifier
    } catch (e) {}
  };

  //Label in Review Images
  async function reviewImagesDropdown(imageDetail, label, labels) {
    //add this image to the model
    {
      label != "Unlabeled" && desktopCollectData(label, imageDetail);
    }

    //change the label in the image dataset to the selected label
    changeLabelInDataset(
      label,
      imagesForModel.indexOf(
        imagesForModel.filter((image) => image.image === imageDetail)[0]
      )
    );
  }
  //Manually label an image
  async function pressLabel(props) {
    setDontShowPrediction(false);

    setProcessingClick(true); //prevent multiple clicks too fast

    setShowLabelIndicator(false); //dismiss help bubble

    remainingCount == 1 && setNoMoreImages(true); //prevent further labeling if there are no more images

    setStreak(prediction.label == props.label ? streak + 1 : 0); //track how many the model gets in a row

    //model strength logic
    strength != "Medium" &&
      strength != "Strong" &&
      strength != "Very Strong" &&
      streak < 15 &&
      setStrength("Weak");
    props.allLabels.length == numberOfFeatures &&
      strength != "Strong" &&
      strength != "Very Strong" &&
      15 < streak &&
      streak < 50 &&
      setStrength("Medium");
    props.allLabels.length == numberOfFeatures &&
      50 < streak &&
      streak < 100 &&
      setStrength("Strong");
    props.allLabels.length == numberOfFeatures &&
      100 < streak &&
      setStrength("Very Strong");

    //add this image to the model
    desktopCollectData(
      props.label,
      imagesForModel.filter((image) => image.label === "Unlabeled")[0].image
    );

    //get the next image's prediction
    remainingCount != 1 &&
      desktopGetPredictions_Button(
        imagesForModel.filter((image) => image.label === "Unlabeled")[1].image,
        props.allLabels
      );
    //change the label in the image dataset to the selected label
    changeLabelInDataset(
      props.label,
      imagesForModel.indexOf(
        imagesForModel.filter((image) => image.label === "Unlabeled")[0]
      )
    );
    //set a delay to prevent rapid clicking
    setTimeout(() => {
      setProcessingClick(false);
    }, 100);
    setFinishedPowerLabel(false);
  }
  const CategoryButton = (props) => {
    return (
      <Hoverable>
        {({ hovered }) => (
          <TouchableOpacity
            disabled={processingClick}
            onPress={() => !processingClick && pressLabel(props)}
            style={{
              alignItems: "center",
              alignSelf: "center",
              backgroundColor:
                props.index == 1
                  ? hovered
                    ? colors.greenLabel
                    : "white"
                  : props.index == 2
                  ? hovered
                    ? colors.redLabel
                    : "white"
                  : props.index == 3
                  ? hovered
                    ? colors.blueLabel
                    : "white"
                  : props.index == 4
                  ? hovered
                    ? colors.purpleLabel
                    : "white"
                  : hovered
                  ? colors.orangeLabel
                  : "white",

              shadowColor: "black",
              shadowOpacity: 0, //was .1
              shadowRadius: 5,
              shadowOffset: {
                height: 1,
                width: 1,
              },
              borderRadius: 5,
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              borderWidth: 1,
              borderColor:
                props.index == 1
                  ? colors.greenLabelBorder
                  : props.index == 2
                  ? colors.redLabelBorder
                  : props.index == 3
                  ? colors.blueLabelBorder
                  : props.index == 4
                  ? colors.purpleLabelBorder
                  : colors.orangeLabelBorder,
              paddingHorizontal: 10,
              paddingVertical: 5,
              height: 40,
              width: "90%", //was 150
              marginVertical: 5,
            }}
          >
            <Text
              style={{
                color:
                  props.index == 1
                    ? colors.greenLabelBorder
                    : props.index == 2
                    ? colors.redLabelBorder
                    : props.index == 3
                    ? colors.blueLabelBorder
                    : props.index == 4
                    ? colors.purpleLabelBorder
                    : colors.orangeLabelBorder,
                fontSize: 16,
                fontFamily: "Inter_600SemiBold",
                selectable: false,
                suppressHighlighting: true,
              }}
            >
              {props.label}
            </Text>

            <Text
              style={{
                color:
                  props.index == 1
                    ? colors.greenLabelBorder
                    : props.index == 2
                    ? colors.redLabelBorder
                    : props.index == 3
                    ? colors.blueLabelBorder
                    : props.index == 4
                    ? colors.purpleLabelBorder
                    : colors.orangeLabelBorder,
                fontSize: 15,
                fontFamily: "Inter_500Medium",
              }}
            >
              (
              {props.index == 1
                ? category1Count
                : props.index == 2
                ? category2Count
                : props.index == 3
                ? category3Count
                : props.index == 4
                ? category4Count
                : category5Count}
              )
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
    );
  };
  const PowerLabelButton = (props) => {
    return (
      <TouchableOpacity
        onPress={() => (setCurrentPowerLabelFilter(props.label), toTop())}
        style={{ alignItems: "center" }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            backgroundColor:
              currentPowerLabelFilter == props.label ? "white" : "transparent",
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: currentPowerLabelFilter == props.label ? 10 : 0,
            minHeight: 30,
            width: 120,
            shadowColor: "black",
            shadowOpacity: currentPowerLabelFilter == props.label ? 0.1 : 0,
            shadowRadius: 5,
            shadowOffset: {
              height: 1,
              width: 1,
            },
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: colors.greyDark,
              fontFamily:
                currentPowerLabelFilter == props.label
                  ? "Inter_600SemiBold"
                  : "Inter_500Medium",
            }}
          >
            {props.label}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  const ReviewImagesButton = (props) => {
    return (
      <TouchableOpacity
        onPress={() => (setCurrentReviewImageFilter(props.label), toTop())}
        style={{ alignItems: "center" }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            backgroundColor:
              currentReviewImageFilter == props.label ? "white" : "transparent",
            paddingHorizontal: 10,
            paddingVertical: 5,
            borderRadius: currentReviewImageFilter == props.label ? 10 : 0,
            minHeight: 30,
            width: 120,
            shadowColor: "black",
            shadowOpacity: currentReviewImageFilter == props.label ? 0.1 : 0,
            shadowRadius: 5,
            shadowOffset: {
              height: 1,
              width: 1,
            },
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: colors.greyDark,
              fontFamily:
                currentReviewImageFilter == props.label
                  ? "Inter_600SemiBold"
                  : "Inter_500Medium",
            }}
          >
            {props.label}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };
  const flatListRef = React.useRef();
  const toTop = () => {
    // use current
    flatListRef.current?.scrollToOffset({ animated: false, offset: 0 });
  };

  return (
    <View style={{ width: width, height: height }}>
      <Overlay //Not Browser
        isVisible={notBrowser}
        fullScreen={false}
        overlayStyle={{
          alignItems: "center",
          zIndex: 102,
          justifyContent: "center",
        }}
      >
        <View
          style={{
            backgroundColor: "white",
            height: height,
            width: width,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 20,
              fontFamily: "Inter_700Bold",
              paddingHorizontal: "10%",
            }}
          >
            Editor access is not available on mobile/tablet devices
          </Text>
        </View>
      </Overlay>

      <Overlay //Loading Demo Page
        isVisible={demoLoading}
        fullScreen={false}
        overlayStyle={{ backgroundColor: "transparent", zIndex: 100 }}
        backdropStyle={{ backgroundColor: "transparent", zIndex: 100 }}
      >
        <Animatable.View
          ref={handleViewRef}
          style={{
            height: height,
            width: width,
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "black",
              fontSize: 20,
              marginBottom: -100,
              fontFamily: "Inter_600SemiBold",
            }}
          >
            Building Demo
          </Text>
          <LottieView
            ref={animation}
            style={{
              width: 600,
              height: 300,
            }}
            speed={1}
            autoPlay={true}
            loop={false}
            onAnimationFinish={() => (
              fadeOut(),
              setTimeout(() => {
                setDemoLoading(false);
              }, 500)
            )}
            source={require("../assets/LoadingDemo.json")}
          />
        </Animatable.View>
      </Overlay>

      <Overlay //Completed Fast Label
        isVisible={completedSpeedLabel}
        fullScreen={false}
        overlayStyle={{
          alignItems: "center",
          zIndex: 101,
          justifyContent: "center",
        }}
      >
        <View
          style={{
            zIndex: 101,
            backgroundColor: "white",
            height: width * 0.3,
            width: width * 0.3,
            borderRadius: 5,
            padding: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LottieView
            ref={animation}
            style={{
              width: 250,
              height: 250,
            }}
            speed={1}
            autoPlay={true}
            loop={false}
            onAnimationFinish={() => (
              setShowSpeedLabel(false), setCompletedSpeedLabel(false)
            )}
            source={require("../assets/Checkmark.json")}
          />
          <Text
            style={{
              textAlign: "center",
              color: colors.greyDark,
              fontSize: 16,
              fontFamily: "Inter_600SemiBold",
              paddingHorizontal: "10%",
            }}
          >
            Your images and model have been updated!
          </Text>
        </View>
      </Overlay>

      <Overlay //Help Prompt
        isVisible={showHelp}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: height * 0.9,
            width: width * 0.8,
            backgroundColor: "white",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => setShowHelp(false)}
            style={{
              zIndex: 100,
              position: "absolute",
              borderRadius: 100,
              height: 40,
              width: 40,
              backgroundColor: colors.greyDisabled,
              right: 15,
              top: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "Inter_700Bold",
                fontSize: 16,
                color: colors.greyDark,
              }}
            >
              X
            </Text>
          </TouchableOpacity>
          <HelpInfo />
        </View>
      </Overlay>

      <Overlay //Screen Size Error
        isVisible={screenSizeError}
        fullScreen={true}
        overlayStyle={{ backgroundColor: "#f8fafc", zIndex: 100 }}
      >
        <View
          style={{
            height: height,
            width: width,
            backgroundColor: "#f8fafc",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#505456",
              fontSize: 16,
              fontFamily: "Inter_700Bold",
            }}
          >
            Boltlabel works best on a larger screen
          </Text>
          <Text
            style={{
              color: "#505456",
              fontSize: 15,
              paddingTop: 10,
              fontFamily: "Inter_500Medium",
            }}
          >
            Try adjusting your screen dimensions to 660 height x 1600 width
          </Text>
        </View>
      </Overlay>

      <SmallPopUp //Restart
        trigger={pressedRestart}
        fullscreen={"true"}
        overlaystyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
        headertext={"Restart Confirmation"}
        bodytext={
          "Restarting will remove the current model and remove all images"
        }
        showcancelbutton={"true"}
        cancelbuttonfunction={setPressedRestart}
        cancelbuttontext={"Cancel"}
        showactionbutton={"true"}
        actionbuttonfunction={resetKnnClassifierModel}
        actionbuttontext={"Restart"}
      />

      <SmallPopUp //Issue with an image (Power Label)
        trigger={imageIssue}
        fullscreen={"true"}
        overlaystyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
        headertext={"Images are Missing Labels"}
        bodytext={
          "Looks like some of your image files couldn't be read by the model. Correct any images marked 'Image Issue'"
        }
        showcancelbutton={"false"}
        cancelbuttonfunction={() => setImageIssue(false)}
        cancelbuttontext={"Go Back"}
        showactionbutton={"true"}
        actionbuttonfunction={() => setImageIssue(false)}
        actionbuttontext={"Go Back"}
      />

      <Overlay //Profile Info
        isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: 550,
            width: 1000,
            backgroundColor: "white",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => setShowProfileInfo(false)}
            style={{
              zIndex: 100,
              position: "absolute",
              borderRadius: 100,
              height: 40,
              width: 40,
              backgroundColor: colors.greyDisabled,
              right: 15,
              top: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "Inter_700Bold",
                fontSize: 16,
                color: colors.greyDark,
              }}
            >
              X
            </Text>
          </TouchableOpacity>
          <ProfileInfo closeProfile={closeProfile} />
        </View>
      </Overlay>

      {!imageIssue && (
        <Overlay //Began Power Label
          isVisible={pressedPowerLabel}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!beginPowerLabel && (
            <View
              style={{
                height: 600,
                width: 700,
                backgroundColor: colors.greySidebar,
                borderRadius: 10,
              }}
            >
              <View //overlay header
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                  borderBottomWidth: 1,
                  borderColor: colors.greyBorder,
                  height: 100,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontFamily: "Inter_600SemiBold",
                  }}
                >
                  Power Label
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: colors.greyLightFont,
                    fontSize: 14,
                    fontFamily: "Inter_500Medium",
                    paddingTop: 5,
                  }}
                >
                  Power Label allows your model to immediately label multiple
                  images
                </Text>
              </View>
              <View style={{ padding: 40 }}>
                <Text
                  style={{
                    textAlign: "center",
                    color: colors.greyDark,
                    fontSize: 16,
                    fontFamily: "Inter_500Medium",
                    paddingTop: "15%",
                  }}
                >
                  How many images to Power Label? (max of 500)
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    color: colors.blueLabelBorder,
                    fontSize: 40,
                    fontFamily: "Inter_700Bold",
                    paddingTop: "5%",
                  }}
                >
                  {sliderValue}
                </Text>
                <View style={{ width: "75%", alignSelf: "center" }}>
                  <Slider
                    value={1}
                    step={1}
                    thumbTintColor={colors.blueLabelBorder}
                    minimumTrackTintColor={colors.greyDark}
                    maximumTrackTintColor={colors.greyCancel}
                    onValueChange={(value) => setSliderValue(value)}
                    minimumValue={1}
                    maximumValue={sliderMax}
                  />
                </View>
              </View>
              <View //Bottom Buttons
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 50,
                  alignSelf: "center",
                }}
              >
                <SecondaryButton
                  disabled={false}
                  onPress={() => (
                    setPressedPowerLabel(false), setSliderValue(1)
                  )}
                  text={"Cancel"}
                  showLoading={false}
                />
                <View style={{ width: 10 }} />
                <MainButton
                  disabled={false}
                  onPress={() => (
                    setBeginPowerLabel(true),
                    remainingCount == 1
                      ? powerLabelPredictions(
                          imagesForModel.filter(
                            (image) => image.label === "Unlabeled"
                          )[0],
                          labels
                        )
                      : powerLabelPredictions(
                          imagesForModel
                            .filter((image) => image.label === "Unlabeled")
                            .slice(0, sliderValue),
                          labels
                        )
                  )}
                  text={"Continue"}
                  showLoading={false}
                />
              </View>
            </View>
          )}
          {beginPowerLabel && (
            <Animatable.View
              animation={"zoomIn"}
              duration={300}
              style={{
                height: height * 0.9,
                width: width * 0.9,
                backgroundColor: "white",
                borderRadius: 10,
                padding: 40,
              }}
            >
              {!donePowerLabelCollect && (
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 20,
                    fontFamily: "Inter_600SemiBold",
                    paddingTop: "2%",
                  }}
                >
                  Modify labels as needed:
                </Text>
              )}
              {!donePowerLabelCollect && (
                <View
                  style={{
                    flexDirection: "row",
                    width: (labels.length + 1) * 120,
                    alignSelf: "center",
                    backgroundColor: colors.greyDisabled,
                    borderColor: colors.greyBorder,
                    borderWidth: 1,
                    borderRadius: 10,
                    alignItems: "center",
                    justifyContent: "center",
                    marginVertical: 10,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => (setCurrentPowerLabelFilter("All"), toTop())}
                    style={{ alignItems: "center" }}
                  >
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        backgroundColor:
                          currentPowerLabelFilter == "All"
                            ? "white"
                            : "transparent",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        borderRadius: currentPowerLabelFilter == "All" ? 10 : 0,
                        minHeight: 30,
                        width: 120,
                        shadowColor: "black",
                        shadowOpacity:
                          currentPowerLabelFilter == "All" ? 0.1 : 0,
                        shadowRadius: 5,
                        shadowOffset: {
                          height: 1,
                          width: 1,
                        },
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 14,
                          color: colors.greyDark,
                          fontFamily:
                            currentPowerLabelFilter == "All"
                              ? "Inter_600SemiBold"
                              : "Inter_500Medium",
                        }}
                      >
                        All
                      </Text>
                    </View>
                  </TouchableOpacity>
                  {labels.map((label, index) => {
                    return (
                      <View key={index}>
                        <PowerLabelButton label={label} allLabels={labels} />
                      </View>
                    );
                  })}
                </View>
              )}
              <View
                style={{
                  height: "75%",
                  width: "90%",
                  paddingTop: "3`12%",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                {isPredicting &&
                  !donePowerLabelCollect &&
                  !finishedPowerLabel && (
                    <View style={{ paddingTop: "20%" }}>
                      <ActivityIndicator
                        size="large"
                        color={colors.blueLabelBorder}
                      />
                      <Text
                        style={{
                          color: colors.blueLabelBorder,
                          fontFamily: "Inter_600SemiBold",
                          paddingTop: 20,
                        }}
                      >
                        Processing Images...
                      </Text>
                    </View>
                  )}
                {isPredicting &&
                  !donePowerLabelCollect &&
                  finishedPowerLabel && (
                    <View style={{ paddingTop: "20%" }}>
                      <ActivityIndicator
                        size="large"
                        color={colors.blueLabelBorder}
                      />
                      <Text
                        style={{
                          color: colors.blueLabelBorder,
                          fontFamily: "Inter_600SemiBold",
                          paddingTop: 20,
                        }}
                      >
                        Adding Images to the Model...
                      </Text>
                    </View>
                  )}
                {powerLabelPredictionImages.filter(
                  (image) => image.prediction === currentPowerLabelFilter
                ).length == 0 && currentPowerLabelFilter != "All" ? (
                  <View
                    style={{
                      height: "90%",
                      width: "50%",
                      backgroundColor: colors.greyBackground,
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LottieView
                      ref={animation}
                      style={{
                        width: 320,
                        height: 320,
                        marginTop: "-5%",
                      }}
                      speed={1}
                      autoPlay={true}
                      loop={true}
                      source={require("../assets/No_Images.json")}
                    />
                    <Text
                      style={{
                        fontSize: 16,
                        fontFamily: "Inter_700Bold",
                        color: colors.greyDark,
                      }}
                    >
                      No Images!
                    </Text>
                  </View>
                ) : (
                  !isPredicting &&
                  !donePowerLabelCollect && (
                    <FlatList
                      ref={flatListRef}
                      initialScrollIndex={0}
                      data={
                        currentPowerLabelFilter == "All"
                          ? powerLabelPredictionImages
                          : powerLabelPredictionImages.filter(
                              (image) =>
                                image.prediction === currentPowerLabelFilter
                            )
                      }
                      renderItem={renderPowerLabelItem}
                      keyExtractor={(item) => item.image}
                      scrollEnabled={true}
                      numColumns={4}
                    />
                  )
                )}
                {donePowerLabelCollect && (
                  <LottieView
                    ref={animation}
                    style={{
                      marginTop: "13%",
                      width: 320,
                      height: 320,
                    }}
                    speed={1}
                    autoPlay={true}
                    loop={false}
                    onAnimationFinish={() => (
                      setIsPredicting(false),
                      setDonePowerLabelCollect(false),
                      setPressedPowerLabel(false),
                      setBeginPowerLabel(false),
                      setFinishedPowerLabel(false)
                    )}
                    source={require("../assets/Lightning.json")}
                  />
                )}
              </View>
              {!isPredicting && !donePowerLabelCollect && (
                <View //Bottom Buttons
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: 50,
                    alignSelf: "center",
                  }}
                >
                  <SecondaryButton
                    disabled={false}
                    onPress={() => (
                      setPressedPowerLabel(false),
                      setBeginPowerLabel(false),
                      setSliderValue(1),
                      setCurrentPowerLabelFilter("All"),
                      setPowerLabelPredictionImages([])
                    )}
                    text={"Cancel"}
                    showLoading={false}
                  />
                  <View style={{ width: 10 }} />
                  <MainButton
                    disabled={false}
                    onPress={() => finishPowerLabel()}
                    text={"Finish"}
                    showLoading={false}
                  />
                </View>
              )}
            </Animatable.View>
          )}
        </Overlay>
      )}

      <SmallPopUp //Go Home Prompt
        trigger={goHome}
        fullscreen={"true"}
        overlaystyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
        headertext={"Go Home Confirmation"}
        bodytext={"Navigating back to the home page will delete all progress"}
        showcancelbutton={"true"}
        cancelbuttonfunction={() => setGoHome(false)}
        cancelbuttontext={"Cancel"}
        showactionbutton={"true"}
        actionbuttonfunction={() => (
          setGoHome(false), navigation.navigate("Home")
        )}
        actionbuttontext={"Go Home"}
      />

      <Overlay //Open Sign Up
        isVisible={signInCheck == "signed_in" ? false : showSignUp}
        fullScreen={false}
        onBackdropPress={() => setShowSignUp(false)}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: height * 0.9,
            width: width * 0.9,
            backgroundColor: "white",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => setShowSignUp(false)}
            style={{
              zIndex: 100,
              position: "absolute",
              borderRadius: 100,
              height: 40,
              width: 40,
              backgroundColor: colors.greyDisabled,
              right: 15,
              top: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "Inter_700Bold",
                fontSize: 16,
                color: colors.greyDark,
              }}
            >
              X
            </Text>
          </TouchableOpacity>
          <SignUp_Editor />
        </View>
      </Overlay>

      <Overlay //Open Log In
        isVisible={signInCheck == "signed_in" ? false : showLogIn}
        fullScreen={false}
        onBackdropPress={() => setShowLogIn(false)}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            height: height * 0.9,
            width: width * 0.9,
            backgroundColor: "white",
            borderRadius: 10,
            overflow: "hidden",
          }}
        >
          <TouchableOpacity
            onPress={() => setShowLogIn(false)}
            style={{
              zIndex: 100,
              position: "absolute",
              borderRadius: 100,
              height: 40,
              width: 40,
              backgroundColor: colors.greyDisabled,
              right: 15,
              top: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "Inter_700Bold",
                fontSize: 16,
                color: colors.greyDark,
              }}
            >
              X
            </Text>
          </TouchableOpacity>
          <LogIn_Editor />
        </View>
      </Overlay>

      <Overlay //Began Sim Sort
        isVisible={showSpeedLabel}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Animatable.View
          animation={"zoomIn"}
          duration={300}
          style={{
            height: 750,
            width: 1005,
            backgroundColor: colors.greySidebar,
            borderRadius: 10,
          }}
        >
          <View //overlay header
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderBottomWidth: 1,
              borderColor: colors.greyBorder,
              height: 100,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              width: "100%",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: 20,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Similarity Sort
            </Text>
            <Text
              style={{
                textAlign: "center",
                color: colors.greyLightFont,
                fontSize: 14,
                fontFamily: "Inter_500Medium",
                paddingTop: 5,
              }}
            >
              Similarity Sort discovers patterns in your images and organizes
              for faster labeling
            </Text>
          </View>
          {completedSpeedLabel && (
            <View
              style={{
                width: "100%",
                alignItems: "center",
                paddingHorizontal: 50,
                paddingVertical: 25,
              }}
            >
              <LottieView
                ref={animation}
                style={{
                  width: 250,
                  height: 250,
                }}
                speed={1}
                autoPlay={true}
                loop={false}
                onAnimationFinish={() => (
                  setShowSpeedLabel(false), setCompletedSpeedLabel(false)
                )}
                source={require("../assets/Checkmark.json")}
              />
              <Text
                style={{
                  textAlign: "center",
                  color: colors.greyDark,
                  fontSize: 16,
                  fontFamily: "Inter_700Bold",
                  paddingHorizontal: "10%",
                }}
              >
                Your images and model have been updated!
              </Text>
            </View>
          )}

          {!completedSpeedLabel && speedLabelImagesUploaded ? (
            <View
              style={{
                width: "100%",
                paddingHorizontal: 50,
                paddingVertical: 25,
              }}
            >
              <Text
                style={{
                  color: colors.greyDark,
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 15,
                  paddingTop: 20,
                  paddingBottom: 15,
                }}
              >
                Pick a Label
              </Text>
              <FlatList
                horizontal
                data={labels}
                renderItem={renderFastLabelLabels}
                keyExtractor={(item) => item}
                extraData={[selectedSpeedLabelColor, showSpeedLabel]}
              />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: 30,
                  paddingBottom: 10,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      fontSize: 15,
                      opacity:
                        selectedSpeedLabelColor == colors.greyDark ? 0.5 : 1,
                      color: colors.greyDark,
                    }}
                  >
                    Click/Drag to select all the images for this label:
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      marginLeft: 5,
                      color: selectedSpeedLabelColor,
                      fontSize: 18,
                    }}
                  >
                    {selectedSpeedLabel}
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    disabled={selectedSpeedLabelColor == colors.greyDark}
                    onPress={() => sortImagesBySimilarity()}
                    style={{
                      width: 150,
                      height: 30,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 3,
                      borderWidth: 1,
                      borderColor:
                        selectedSpeedLabelColor == colors.greyDark
                          ? colors.greyLightFont
                          : colors.greyDark,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        color:
                          selectedSpeedLabelColor == colors.greyDark
                            ? colors.greyLightFont
                            : colors.greyDark,
                      }}
                    >
                      Try to sort again
                    </Text>
                  </TouchableOpacity>
                  {fastLabelCategory1.length +
                    fastLabelCategory2.length +
                    fastLabelCategory3.length +
                    fastLabelCategory4.length +
                    fastLabelCategory5.length >
                    0 && (
                    <TouchableOpacity
                      onPress={() => (
                        setFastLabelCategory1([]),
                        setFastLabelCategory2([]),
                        setFastLabelCategory3([]),
                        setFastLabelCategory4([]),
                        setFastLabelCategory5([]),
                        setSelectedIndexes([])
                      )}
                      style={{
                        marginLeft: 5,
                        width: 80,
                        height: 30,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 3,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Inter_600SemiBold",
                          color: colors.red,
                        }}
                      >
                        Clear
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  height: 305,
                  width: "100%",
                  borderRadius: 5,
                  backgroundColor:
                    selectedSpeedLabelColor == colors.greyDark
                      ? colors.greyCancelHover
                      : "white",
                }}
              >
                {selectedSpeedLabelColor != colors.greyDark &&
                  speedLabelImagesUploaded && <DragSelection />}
                <View
                  style={styles.elementsContainer}
                  ref={elementsContainerRef}
                >
                  {Array.from({ length: speedLabelImages.length }, (_, i) => (
                    <View
                      data-testid={`grid-cell-${i}`}
                      key={i}
                      style={[styles.element]}
                    >
                      <TouchableOpacity
                        disabled={true}
                        style={{
                          height: 80,
                          width: 80,
                          position: "absolute",
                          zIndex: 2,

                          borderWidth: 3,
                          borderColor: refSelectedIndexes.current.includes(i)
                            ? selectedSpeedLabelColor
                            : selectedSpeedLabelColor == colors.greyDark
                            ? hexToRgba(colors.greyLightFont, "0.7")
                            : fastLabelCategory1.includes(
                                speedLabelImages[i].image
                              )
                            ? colors.greenLabelBorder
                            : fastLabelCategory2.includes(
                                speedLabelImages[i].image
                              )
                            ? colors.redLabelBorder
                            : fastLabelCategory3.includes(
                                speedLabelImages[i].image
                              )
                            ? colors.blueLabelBorder
                            : fastLabelCategory4.includes(
                                speedLabelImages[i].image
                              )
                            ? colors.purpleLabelBorder
                            : fastLabelCategory5.includes(
                                speedLabelImages[i].image
                              )
                            ? colors.orangeLabelBorder
                            : "transparent",
                          backgroundColor:
                            selectedSpeedLabelColor == colors.greyDark
                              ? hexToRgba(colors.greyLightFont, "0.7")
                              : fastLabelCategory1.includes(
                                  speedLabelImages[i].image
                                )
                              ? hexToRgba("#00b300", "0.4")
                              : fastLabelCategory2.includes(
                                  speedLabelImages[i].image
                                )
                              ? hexToRgba("#eb1e63", "0.4")
                              : fastLabelCategory3.includes(
                                  speedLabelImages[i].image
                                )
                              ? hexToRgba("#007aff", "0.4")
                              : fastLabelCategory4.includes(
                                  speedLabelImages[i].image
                                )
                              ? hexToRgba("#8b00ff", "0.4")
                              : fastLabelCategory5.includes(
                                  speedLabelImages[i].image
                                )
                              ? hexToRgba("#ff9502", "0.4")
                              : "transparent",
                          borderRadius: 5,
                        }}
                      />
                      {fastLabelCategory1
                        .concat(
                          fastLabelCategory2,
                          fastLabelCategory3,
                          fastLabelCategory4,
                          fastLabelCategory5
                        )
                        .includes(speedLabelImages[i].image) && (
                        <Text
                          style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            backgroundColor: hexToRgba("#ffffff", 0.7),
                            zIndex: 3,
                            fontFamily: "Inter_500Medium",
                          }}
                        >
                          Labeled
                        </Text>
                      )}
                      {fastLabelCategory1
                        .concat(
                          fastLabelCategory2,
                          fastLabelCategory3,
                          fastLabelCategory4,
                          fastLabelCategory5
                        )
                        .includes(speedLabelImages[i].image) && (
                        <TouchableOpacity
                          style={{
                            width: 15,
                            height: 15,
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            zIndex: 3,
                            backgroundColor: hexToRgba("#ffffff", 0.7),
                          }}
                          onPress={() =>
                            removeLabelSimSort(speedLabelImages[i].image)
                          }
                        >
                          <Text style={{ fontFamily: "Inter_500Medium" }}>
                            X
                          </Text>
                        </TouchableOpacity>
                      )}
                      <Image
                        blurRadius={
                          selectedSpeedLabelColor == colors.greyDark ? 5 : 0
                        }
                        style={{
                          height: "100%",
                          borderRadius: 5,
                          width: "100%",
                        }}
                        source={speedLabelImages[i].image}
                      />
                    </View>
                  ))}
                </View>
              </View>
            </View>
          ) : (
            !completedSpeedLabel && (
              <View
                style={{
                  width: "100%",
                  height: 350,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ActivityIndicator
                  size={"large"}
                  color={colors.blueLabelBorder}
                />
              </View>
            )
          )}
          {speedLabelImagesUploaded && (
            <View //Bottom Buttons
              style={{
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: 50,
                alignSelf: "center",
              }}
            >
              <SecondaryButton
                disabled={false}
                onPress={() =>
                  totalLabeledInSpeedLabel == delayedTotalLabeledInSpeedLabel
                    ? JustCancelSimSort()
                    : submitAndFinishSimSort()
                }
                text={"Close"}
                showLoading={false}
              />
              <View style={{ width: 10 }} />
              <MainButton
                disabled={false}
                onPress={() => submitSimSort()}
                text={"Submit"}
                showLoading={false}
              />
            </View>
          )}
        </Animatable.View>
      </Overlay>

      <Overlay //Review Images
        isVisible={pressedReviewImages}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SmallPopUp //Delete Image (part of Review Images)
          trigger={deleteImagePrompt}
          fullscreen={"true"}
          overlaystyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
          headertext={"Delete Image Confirmation"}
          bodytext={
            "This will permanently delete the selected image from your dataset"
          }
          showcancelbutton={"true"}
          cancelbuttonfunction={() => setDeleteImagePrompt(false)}
          cancelbuttontext={"Cancel"}
          showactionbutton={"true"}
          actionbuttonfunction={() => deleteImageFromArray(imageForDeletion)}
          actionbuttontext={"Continue"}
        />

        <Animatable.View
          animation={"zoomIn"}
          duration={300}
          style={{
            height: 750,
            width: 1005,
            backgroundColor: colors.greySidebar,
            borderRadius: 10,
            alignItems: "center",
          }}
        >
          <View //overlay header
            style={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderBottomWidth: 1,
              borderColor: colors.greyBorder,
              height: 100,
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              width: "100%",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                fontSize: 20,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Review Images
            </Text>
            <Text
              style={{
                textAlign: "center",
                color: colors.greyLightFont,
                fontSize: 14,
                fontFamily: "Inter_500Medium",
                paddingTop: 5,
              }}
            >
              Explore/edit your images
            </Text>
          </View>
          <View //filter & search
            style={{
              width: "100%",
              paddingHorizontal: "5%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View //filters
              style={{
                flexDirection: "row",
                width: (labels.length + 2) * 120,
                alignSelf: "center",
                backgroundColor: colors.greyDisabled,
                borderColor: colors.greyBorder,
                borderWidth: 1,
                borderRadius: 10,
                alignItems: "center",
                justifyContent: "center",
                marginVertical: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => (setCurrentReviewImageFilter("All"), toTop())}
                style={{ alignItems: "center" }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    backgroundColor:
                      currentReviewImageFilter == "All"
                        ? "white"
                        : "transparent",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: currentReviewImageFilter == "All" ? 10 : 0,
                    minHeight: 30,
                    width: 120,
                    shadowColor: "black",
                    shadowOpacity: currentReviewImageFilter == "All" ? 0.1 : 0,
                    shadowRadius: 5,
                    shadowOffset: {
                      height: 1,
                      width: 1,
                    },
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: colors.greyDark,
                      fontFamily:
                        currentReviewImageFilter == "All"
                          ? "Inter_600SemiBold"
                          : "Inter_500Medium",
                    }}
                  >
                    All
                  </Text>
                </View>
              </TouchableOpacity>
              {labels.map((label, index) => {
                return (
                  <View key={index}>
                    <ReviewImagesButton label={label} allLabels={labels} />
                  </View>
                );
              })}
              <TouchableOpacity
                onPress={() => (
                  setCurrentReviewImageFilter("Unlabeled"), toTop()
                )}
                style={{ alignItems: "center" }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    backgroundColor:
                      currentReviewImageFilter == "Unlabeled"
                        ? "white"
                        : "transparent",
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius:
                      currentReviewImageFilter == "Unlabeled" ? 10 : 0,
                    minHeight: 30,
                    width: 120,
                    shadowColor: "black",
                    shadowOpacity:
                      currentReviewImageFilter == "Unlabeled" ? 0.1 : 0,
                    shadowRadius: 5,
                    shadowOffset: {
                      height: 1,
                      width: 1,
                    },
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      color: colors.greyDark,
                      fontFamily:
                        currentReviewImageFilter == "Unlabeled"
                          ? "Inter_600SemiBold"
                          : "Inter_500Medium",
                    }}
                  >
                    Unlabeled
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            {allow_search && (
              <View //search
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  opacity: 0, //hide this for now
                }}
              >
                <TextInput
                  maxLength={30}
                  onChangeText={(newVal) => setReviewImagesSearch(newVal)}
                  placeholder="Search your images..."
                  placeholderTextColor={"#4d4d4d"}
                  value={reviewImagesSearch}
                  style={{
                    height: 33,
                    width: 225,
                    borderRadius: 5,
                    backgroundColor: "white",
                    borderWidth: 1,
                    borderColor: colors.greyBorder,
                    paddingHorizontal: 15,
                    fontFamily: "Inter_700Bold",
                  }}
                  onKeyPress={(e) =>
                    e.key == "Enter" &&
                    reviewImagesSearch != "" &&
                    !executingImageSearch &&
                    searchImages(reviewImagesSearch)
                  }
                />
                <View style={{ width: 5 }} />
                <Hoverable
                  style={{
                    height: 33,
                    borderRadius: 5,
                  }}
                >
                  {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() =>
                        reviewImagesSearch != "" &&
                        !executingImageSearch &&
                        searchImages(reviewImagesSearch)
                      }
                      style={{
                        borderRadius: 5,
                        paddingHorizontal: 5,
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: hovered
                          ? hexToRgba(colors.blueLabelBorder, 0.9)
                          : colors.blueLabelBorder,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "Inter_600SemiBold",
                          fontSize: 14,
                        }}
                      >
                        Search
                      </Text>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
            )}
          </View>
          <View //Image List
            style={{
              width: "90%",
              height: 470,
              backgroundColor: "white",
              borderRadius: 10,
              borderWidth: 1,
              borderColor: colors.greyBorder,
              justifyContent: "center",
            }}
          >
            <FlatList
              ref={flatListRef}
              initialScrollIndex={0}
              contentContainerStyle={{ alignItems: "center" }}
              data={
                madeASearch
                  ? currentReviewImageFilter == "All"
                    ? imagesForModel.sort(dynamicSort("-searchSimilarity"))
                    : imagesForModel
                        .filter(
                          (image) => image.label === currentReviewImageFilter
                        )

                        .sort(dynamicSort("-searchSimilarity"))
                  : currentReviewImageFilter == "All"
                  ? imagesForModel.sort(dynamicSort("loadedOrder"))
                  : imagesForModel
                      .filter(
                        (image) => image.label === currentReviewImageFilter
                      )
                      .sort(dynamicSort("loadedOrder"))
              }
              renderItem={memorizedValueReviewImages}
              keyExtractor={(item) => item.image}
              scrollEnabled={true}
              numColumns={6}
            />
          </View>
          <View //Bottom Buttons
            style={{
              flexDirection: "row",
              justifyContent: "center",
              position: "absolute",
              bottom: 50,
              alignSelf: "center",
            }}
          >
            <MainButton
              disabled={false}
              onPress={() => (
                setPressedReviewImages(false),
                setCurrentReviewImageFilter("All")
              )}
              text={"Close"}
              showLoading={false}
            />
          </View>
        </Animatable.View>
      </Overlay>
      {isTfReady ? (
        <View
          style={{
            flexDirection: "row",
            shadowColor: "rgba(179, 179, 179, 1)",
          }}
        >
          <Animated.View //Sidebar Panel
            style={{
              backgroundColor: "white",
              height: height,
              width: sidebarCondenseOrExpand,
              borderRightWidth: 1,
              borderColor: colors.greyBorder,
              zIndex: 2,
            }}
          >
            <Animated.Image
              source={require("../assets/editor_sidebar_shapes.png")}
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: sidebarCondenseOrExpand,
                opacity: 0.13,
                height: height * 0.8,
              }}
            />
            <TouchableOpacity //logo full
              activeOpacity={1}
              onPress={() => setGoHome(true)}
              style={{
                zIndex: 100,
                marginLeft: 20,
                justifyContent: "center",
                position: "absolute",
                left: 10,
                top: 30,
              }}
            >
              <Animated.Image
                source={require("../assets/BoltLabel.png")}
                style={{ opacity: sidebarOpacity, width: 100, height: 30 }}
              />
            </TouchableOpacity>
            <TouchableOpacity //logo small
              activeOpacity={1}
              onPress={() => setGoHome(true)}
              style={{
                zIndex: 100,
                marginLeft: 20,
                justifyContent: "center",
                position: "absolute",
                left: 10,
                top: 30,
              }}
            >
              <Animated.Image
                source={require("../assets/AmpLabel_Logo_Small.png")}
                style={{ width: 30, height: 30 }}
              />
            </TouchableOpacity>
            <View style={{ height: "12%" }} />
            <Animated.View //button condense
              style={{
                opacity: sidebarOpacity,
                width: 30,
                height: 75,
                backgroundColor: "white",
                borderRadius: 100,
                borderWidth: 1,
                borderColor: colors.greyBorder,
                position: "absolute",
                right: 0,
                marginRight: -15,
                top: "27%",
                zIndex: 3,
              }}
            >
              <TouchableOpacity
                disabled={sidebarIsMoving}
                onPress={() => startAnimation()}
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Ionicons
                  name="chevron-back-outline"
                  size={25}
                  color={colors.greyLightFont}
                />
              </TouchableOpacity>
            </Animated.View>
            <Animated.View //button expand
              style={{
                opacity: reverseSidebarOpacity,
                width: 30,
                height: 75,
                backgroundColor: "white",
                borderRadius: 100,
                borderWidth: 1,
                borderColor: colors.greyBorder,
                position: "absolute",
                right: 0,
                marginRight: -15,
                top: "27%",
                zIndex: 3,
              }}
            >
              <TouchableOpacity
                disabled={sidebarIsMoving}
                onPress={() => startAnimation()}
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Ionicons
                  name="chevron-forward-outline"
                  size={25}
                  color={colors.greyLightFont}
                />
              </TouchableOpacity>
            </Animated.View>
            <View style={{ height: "73%", width: "100%" }}>
              <Animated.Text
                numberOfLines={1}
                style={{
                  opacity: sidebarOpacity,
                  fontFamily: "Inter_700Bold",
                  paddingLeft: 30,
                  fontSize: 15,
                  paddingBottom: 5,
                  color: colors.greyDark,
                }}
              >
                Use Cases
              </Animated.Text>
              <Picker
                selectedValue={useCasePicker}
                style={{
                  zIndex: 2,
                  height: 45,
                  width: "80%",
                  alignSelf: "center",
                  color: colors.greyDark,
                  textAlign: "center",
                  fontFamily: "Inter_700Bold",
                  borderColor: colors.greyBorder,
                  backgroundColor: "white",
                  paddingHorizontal: 5,
                  borderRadius: 5,
                }}
                onValueChange={(itemValue) => setUseCasePicker(itemValue)}
              >
                <Picker.Item
                  label={"Agriculture (pest detection)"} //done
                  value={"Agriculture (pest detection)"}
                  fontFamily={"Inter_500Medium"}
                />
                <Picker.Item
                  label={"Agriculture (ripe fruit)"} //done
                  value={"Agriculture (ripe fruit)"}
                  fontFamily={"Inter_500Medium"}
                />
                <Picker.Item
                  label={"Animals (dog, cat)"} //done
                  value={"Animals (dog, cat)"}
                  fontFamily={"Inter_500Medium"}
                />
                <Picker.Item
                  label={"Medicine (mask detection)"} //done
                  value={"Medicine (mask detection)"}
                  fontFamily={"Inter_500Medium"}
                />
                {/*  <Picker.Item
                  label={"Medicine (hair loss)"}
                  value={"Medicine (hair loss)"}
                  fontFamily={"Inter_500Medium"}
                />
                <Picker.Item
                  label={"Medicine (moles)"}
                  value={"Medicine (moles)"}
                  fontFamily={"Inter_500Medium"}
                /> */}
                <Picker.Item
                  label={"Manufacturing (defects)"} //done
                  value={"Manufacturing (defects)"}
                  fontFamily={"Inter_500Medium"}
                />
                {/*  <Picker.Item
                  label={"Nature (flowers)"}
                  value={"Nature (flowers)"}
                  fontFamily={"Inter_500Medium"}
                /> */}
                <Picker.Item
                  label={"Nature (weather)"} //done
                  value={"Nature (weather)"}
                  fontFamily={"Inter_500Medium"}
                />
                {/*  <Picker.Item
                  label={"Photography (blur detection)"}
                  value={"Photography (blur detection)"}
                  fontFamily={"Inter_500Medium"}
                />
                <Picker.Item
                  label={"Photography (closed eyes)"}
                  value={"Photography (closed eyes)"}
                  fontFamily={"Inter_500Medium"}
                /> */}
                {/* <Picker.Item
                  label={"Photography (smile detection)"} //done
                  value={"Photography (smile detection)"}
                  fontFamily={"Inter_500Medium"}
                /> */}
                {/*  <Picker.Item
                  label={"Satellite (hurricane damage)"}
                  value={"Satellite (hurricane damage)"}
                  fontFamily={"Inter_500Medium"}
                />
                <Picker.Item
                  label={"Vehicles (bike parking)"}
                  value={"Vehicles (bike parking)"}
                  fontFamily={"Inter_500Medium"}
                /> */}
                <Picker.Item
                  label={"Vehicles (bike, skateboard, surfboard)"} //done
                  value={"Vehicles (bike, skateboard, surfboard)"}
                  fontFamily={"Inter_500Medium"}
                />
                {/* <Picker.Item
                  label={"Vehicles (street signs)"} //done
                  value={"Vehicles (street signs)"}
                  fontFamily={"Inter_500Medium"}
                /> */}
              </Picker>
              <View style={{ height: 40 }} />
              <Animated.Text
                numberOfLines={1}
                style={{
                  opacity: sidebarOpacity,
                  fontFamily: "Inter_700Bold",
                  fontSize: 15,
                  color: colors.greyDark,
                  paddingLeft: 30,
                  paddingBottom: 5,
                }}
              >
                Image Functions
              </Animated.Text>
              <Hoverable //Add Images
                onMouseEnter={() => setDisabledAddImages(true)}
                onMouseLeave={() => setDisabledAddImages(false)}
                style={{ marginHorizontal: 20, borderRadius: 10 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={true}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "rgba(255, 0, 0,.05)",
                      paddingHorizontal: 10,
                      width: sidebarButtonWidth,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="image-outline"
                      size={25}
                      color={hovered ? colors.redError : colors.greyDark}
                    />
                    {!disabledAddImages && (
                      <Animated.Text
                        numberOfLines={1}
                        style={{
                          opacity: sidebarOpacity,
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: colors.greyDark,
                        }}
                      >
                        Add Images
                      </Animated.Text>
                    )}
                    {disabledAddImages && (
                      <Animated.Text
                        numberOfLines={1}
                        style={{
                          opacity: sidebarOpacity,
                          paddingLeft: 10,
                          fontSize: 14,
                          color: "#eb1e63",
                          fontFamily: "Inter_500Medium",
                        }}
                      >
                        Disabled for Demo
                      </Animated.Text>
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
              <View style={{ height: 5 }} />
              <Hoverable //Review Images
                style={{ marginHorizontal: 20, borderRadius: 10 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={!isTfReady}
                    onPress={() => setPressedReviewImages(true)}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "#e7f2fd",
                      paddingHorizontal: 10,
                      width: sidebarButtonWidth,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="search-outline"
                      size={25}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                    />

                    <Animated.Text
                      numberOfLines={1}
                      style={{
                        opacity: sidebarOpacity,
                        paddingLeft: 10,
                        fontSize: 15,
                        fontFamily: "Inter_500Medium",
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Review Images
                    </Animated.Text>
                    {!isTfReady && (
                      <ActivityIndicator
                        style={{ paddingLeft: 10 }}
                        size={"small"}
                        color={colors.blueLabelBorder}
                      />
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
              <View style={{ height: 5 }} />
              <Hoverable //Restart
                style={{ marginHorizontal: 20, borderRadius: 10 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setPressedRestart(true)}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "#e7f2fd",
                      paddingHorizontal: 10,
                      width: sidebarButtonWidth,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="sync-outline"
                      size={25}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                    />
                    <Animated.Text
                      numberOfLines={1}
                      style={{
                        opacity: sidebarOpacity,
                        paddingLeft: 10,
                        fontSize: 15,
                        fontFamily: "Inter_500Medium",
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Restart
                    </Animated.Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
              <View style={{ height: 5 }} />
              <Hoverable //Export
                style={{ marginHorizontal: 20, borderRadius: 10 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={startDownload ? true : false}
                    onPress={() => saveKnnClassifierModel()}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "#e7f2fd",
                      paddingHorizontal: 10,
                      width: sidebarButtonWidth,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="download-outline"
                      size={25}
                      color={hovered ? colors.blueLabelBorder : colors.greyDark}
                    />
                    <Animated.Text
                      numberOfLines={1}
                      style={{
                        opacity: sidebarOpacity,

                        paddingLeft: 10,
                        fontSize: 15,
                        fontFamily: "Inter_500Medium",
                        color: hovered
                          ? colors.blueLabelBorder
                          : colors.greyDark,
                      }}
                    >
                      Export
                    </Animated.Text>
                    {startDownload && (
                      <ActivityIndicator
                        style={{ paddingLeft: 10 }}
                        size="small"
                        color={colors.blueLabelBorder}
                      />
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>

              <View style={{ height: 40 }} />
              <View>
                <Animated.View
                  style={{
                    backgroundColor: colors.greyBorder,
                    width: "50%",
                    alignSelf: "center",
                    height: 1,
                    opacity: reverseSidebarOpacity,
                  }}
                />
                <Animated.Text
                  numberOfLines={1}
                  style={{
                    opacity: sidebarOpacity,
                    color: colors.greyDark,
                    fontFamily: "Inter_700Bold",
                    paddingLeft: 30,
                    fontSize: 15,
                    paddingBottom: 5,
                  }}
                >
                  Label Functions
                </Animated.Text>
              </View>
              <Hoverable //Similarity Sort
                style={{ marginHorizontal: 20, borderRadius: 10 }}
              >
                {({ hovered }) =>
                  remainingCount == 0 ? (
                    <View
                      style={{
                        flexDirection: "row",
                        borderRadius: 10,
                        backgroundColor: hovered && "rgba(255, 0, 0,.05)",
                        paddingHorizontal: 10,
                        width: sidebarButtonWidth,
                        height: 45,
                        alignItems: "center",
                      }}
                    >
                      <Feather
                        name="wind"
                        size={23}
                        color={hovered ? colors.redError : colors.greyDark}
                      />
                      {hovered ? (
                        <Animated.Text
                          numberOfLines={1}
                          style={{
                            opacity: sidebarOpacity,
                            paddingLeft: 10,
                            fontSize: 15,
                            fontFamily: "Inter_500Medium",
                            color: hovered ? colors.redError : colors.greyDark,
                          }}
                        >
                          No Images Loaded
                        </Animated.Text>
                      ) : (
                        <Animated.Text
                          numberOfLines={1}
                          style={{
                            opacity: sidebarOpacity,
                            paddingLeft: 10,
                            fontSize: 15,
                            fontFamily: "Inter_500Medium",
                            color: hovered
                              ? colors.blueLabelBorder
                              : colors.greyDark,
                          }}
                        >
                          Similarity Sort
                        </Animated.Text>
                      )}
                      {!isTfReady && (
                        <ActivityIndicator
                          style={{ paddingLeft: 10 }}
                          size={"small"}
                          color={colors.blueLabelBorder}
                        />
                      )}
                    </View>
                  ) : (
                    <TouchableOpacity
                      disabled={!isTfReady}
                      onPress={() => (
                        setShowSpeedLabel(true),
                        setTimeout(() => {
                          pressedSimSort(
                            imagesForModel
                              .filter((image) => image.label === "Unlabeled")
                              .slice(0, 27)
                          );
                        }, 500)
                      )}
                      style={{
                        flexDirection: "row",
                        borderRadius: 10,
                        backgroundColor: hovered && "#e7f2fd",
                        paddingHorizontal: 10,
                        width: sidebarButtonWidth,
                        height: 45,
                        alignItems: "center",
                      }}
                    >
                      <Feather
                        name="wind"
                        size={23}
                        color={
                          hovered ? colors.blueLabelBorder : colors.greyDark
                        }
                      />

                      <Animated.Text
                        numberOfLines={1}
                        style={{
                          opacity: sidebarOpacity,
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: hovered
                            ? colors.blueLabelBorder
                            : colors.greyDark,
                        }}
                      >
                        Similarity Sort
                      </Animated.Text>
                      {!isTfReady && (
                        <ActivityIndicator
                          style={{ paddingLeft: 10 }}
                          size={"small"}
                          color={colors.blueLabelBorder}
                        />
                      )}
                    </TouchableOpacity>
                  )
                }
              </Hoverable>
              <View style={{ height: 5 }} />
              <Hoverable //Load Model
                onMouseEnter={() => setDisabledLoadModel(true)}
                onMouseLeave={() => setDisabledLoadModel(false)}
                style={{ marginHorizontal: 20, borderRadius: 10 }}
              >
                {({ hovered }) => (
                  <Pressable
                    disabled={true}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "rgba(255, 0, 0,.05)",
                      paddingHorizontal: 10,
                      width: sidebarButtonWidth,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    <Ionicons
                      name="push-outline"
                      size={25}
                      color={hovered ? colors.redError : colors.greyDark}
                    />
                    {!disabledLoadModel && (
                      <Animated.Text
                        numberOfLines={1}
                        style={{
                          opacity: sidebarOpacity,
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: colors.greyDark,
                        }}
                      >
                        Load Model
                      </Animated.Text>
                    )}
                    {disabledLoadModel && (
                      <Animated.Text
                        numberOfLines={1}
                        style={{
                          opacity: sidebarOpacity,
                          paddingLeft: 10,
                          fontSize: 14,
                          color: "#eb1e63",
                          fontFamily: "Inter_500Medium",
                        }}
                      >
                        Disabled for Demo
                      </Animated.Text>
                    )}
                  </Pressable>
                )}
              </Hoverable>
              <View style={{ height: 5 }} />
              <Hoverable //Predictions Toggle
                style={{ marginHorizontal: 20, borderRadius: 10 }}
              >
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowPrediction(!showPrediction)}
                    style={{
                      flexDirection: "row",
                      borderRadius: 10,
                      backgroundColor: hovered && "#e7f2fd",
                      paddingHorizontal: 10,
                      width: sidebarButtonWidth,
                      height: 45,
                      alignItems: "center",
                    }}
                  >
                    {!showPrediction && (
                      <Ionicons
                        name="flash-outline"
                        size={25}
                        color={
                          hovered ? colors.blueLabelBorder : colors.greyDark
                        }
                      />
                    )}
                    {showPrediction && (
                      <Ionicons
                        name="flash-off-outline"
                        size={25}
                        color={
                          hovered ? colors.blueLabelBorder : colors.greyDark
                        }
                      />
                    )}
                    {!showPrediction && (
                      <Animated.Text
                        numberOfLines={1}
                        style={{
                          opacity: sidebarOpacity,
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: hovered
                            ? colors.blueLabelBorder
                            : colors.greyDark,
                        }}
                      >
                        Show Predictions
                      </Animated.Text>
                    )}
                    {showPrediction && (
                      <Animated.Text
                        numberOfLines={1}
                        style={{
                          opacity: sidebarOpacity,
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: hovered
                            ? colors.blueLabelBorder
                            : colors.greyDark,
                        }}
                      >
                        Hide Predictions
                      </Animated.Text>
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>

            <View
              style={{
                height: "15%",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <View
                style={{
                  height: "55%",
                  alignItems: "center",
                  width: "100%",
                  paddingBottom: 30,
                  justifyContent: "flex-end",
                }}
              ></View>
              <View style={{ height: "5%" }} />
              {/* {signInCheck == "signed_out" && (
                <Animated.View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    height: "35%",
                    width: "100%",
                    opacity: sidebarOpacity,
                  }}
                >
                  <SecondaryButton
                    disabled={false}
                    onPress={() => setShowLogIn(true)}
                    text={"Log In"}
                    showLoading={false}
                  />
                  <View style={{ width: "5%", height: 50 }} />
                  <MainButton
                    disabled={false}
                    onPress={() => setShowSignUp(true)}
                    text={"Sign Up"}
                    showLoading={false}
                  />
                </Animated.View>
              )}
              {signInCheck == "signed_in" && premiumStatus != "premium" && (
                <Hoverable //Upgrade
                  style={{ height: 55, width: "75%", marginBottom: "5%" }}
                >
                  {({ hovered }) => (
                    <TouchableOpacity
                      disabled={showingCheckout}
                      onPress={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      style={{
                        flexDirection: "row",
                        borderRadius: 10,
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "center",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        shadowColor: "black",
                        shadowOpacity: hovered ? 0.3 : 0.1,
                        shadowRadius: 25,
                        shadowOffset: {
                          height: 7,
                          width: 1,
                        },
                      }}
                    >
                      <LinearGradient
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: 10,

                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0.5, y: 0.5 }}
                        colors={[colors.red, colors.purpleLabelBorder]}
                      >
                        {!showingCheckout && (
                          <Text
                            style={{
                              fontSize: 16,
                              color: "white",
                              fontFamily: "Inter_700Bold",
                              textAlign: "center",
                            }}
                          >
                            Upgrade
                          </Text>
                        )}
                        {showingCheckout && (
                          <LottieView
                            ref={animation}
                            style={{
                              width: 40,
                              height: 15,
                            }}
                            autoPlay={true}
                            loop={true}
                            source={require("../assets/loadingAnimation.json")}
                          />
                        )}
                      </LinearGradient>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              )} */}

              <View style={{ height: "5%" }} />
              <Animated.View
                style={{
                  opacity: sidebarOpacity,
                  flexDirection: "row",
                  width: "100%",
                  height: 55,
                  borderTopWidth: 1,
                  borderColor: colors.greyBorder,
                  backgroundColor: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "Inter_600SemiBold",
                    color: colors.greyLightFont,
                  }}
                >
                  Status:
                </Text>
                <Text
                  style={{
                    paddingLeft: 10,
                    fontSize: 14,
                    fontFamily: "Inter_500Medium",
                    color: colors.greyLightFont,
                  }}
                >
                  Demo
                </Text>
              </Animated.View>
            </View>
          </Animated.View>

          <Animated.View
            style={{
              flexDirection: "row",
              height: height,
              width: centerPanelCondenseOrExpand,
            }}
          >
            <View //Center Panel
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: height,
                width: "70%",
              }}
            >
              <View style={{ height: height, width: "100%" }}>
                {imagesSuccessfullyUploaded && remainingCount == 0 && (
                  <View
                    style={{
                      height: 500,
                      width: 500,
                      borderRadius: 10,
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Pressable
                      onPress={() => resetKnnClassifierModel()}
                      style={({ hovered }) => [
                        {
                          borderColor: hovered ? "black" : colors.greyLightFont,
                          borderWidth: 2,
                          alignItems: "center",
                          justifyContent: "center",
                          borderStyle: "dashed",
                          width: 500,
                          height: 250,
                          borderRadius: 10,
                        },
                      ]}
                    >
                      <View style={{ paddingTop: 10, alignItems: "center" }}>
                        <Text
                          style={{
                            fontSize: 17,
                            fontFamily: "Inter_500Medium",
                            color: colors.greyLightFont,
                          }}
                        >
                          Click to Restart Demo!
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                )}
                {imagesSuccessfullyUploaded && remainingCount != 0 && (
                  <View //Image List Panel
                    style={{
                      height: "75%",
                      width: "100%",
                      backgroundColor: colors.greyBackground,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        overflow: "hidden",
                        borderRadius: 20,
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        borderWidth: 10,
                        backgroundColor: dontShowPrediction
                          ? "transparent"
                          : prediction == "No Results"
                          ? "transparent"
                          : !showPrediction
                          ? "transparent"
                          : loadedModel
                          ? labelWeakColors[
                              Object.keys(labelWeakColors)[
                                labels.indexOf(prediction.label)
                              ]
                            ]
                          : numberOfImagesInModel < 3
                          ? "white"
                          : labelWeakColors[
                              Object.keys(labelWeakColors)[
                                labels.indexOf(prediction.label)
                              ]
                            ],
                        borderColor: dontShowPrediction
                          ? "transparent"
                          : prediction == "No Results"
                          ? "transparent"
                          : !showPrediction
                          ? "transparent"
                          : loadedModel
                          ? labelWeakColors[
                              Object.keys(labelWeakColors)[
                                labels.indexOf(prediction.label)
                              ]
                            ]
                          : numberOfImagesInModel < 3
                          ? "white"
                          : labelWeakColors[
                              Object.keys(labelWeakColors)[
                                labels.indexOf(prediction.label)
                              ]
                            ],
                        shadowColor: "black",
                        shadowOpacity: changingUseCase ? 0 : 0.15,
                        shadowRadius: 5,
                        shadowOffset: {
                          height: 1,
                          width: 1,
                        },
                      }}
                    >
                      {!uploadingImages && !changingUseCase && (
                        <View
                          style={{
                            height: 500,
                            width: 500,
                            overflow: "hidden",
                            borderRadius: 15,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FlatList
                            ref={ref}
                            onScrollToIndexFailed={(info) => {
                              const wait = new Promise((resolve) =>
                                setTimeout(resolve, 500)
                              );
                              wait.then(() => {
                                ref.current?.scrollToIndex({
                                  index,
                                  animated: false,
                                });
                              });
                            }}
                            initialScrollIndex={index}
                            data={imagesForModel
                              .filter((image) => image.label === "Unlabeled")
                              .sort(dynamicSort("loadedOrder"))}
                            renderItem={memorizedValue}
                            keyExtractor={(item) => item.image}
                            scrollEnabled={false}
                            extraData={[imagesForModel, restarting]}
                          />
                        </View>
                      )}

                      {uploadingImages && (
                        <ActivityIndicator
                          size="large"
                          color={colors.blueLabelBorder}
                        />
                      )}
                      {changingUseCase && (
                        <ActivityIndicator
                          size="large"
                          color={colors.blueLabelBorder}
                        />
                      )}
                      {showPrediction && !changingUseCase && (
                        <View
                          style={{
                            flexDirection: "row",
                            backgroundColor: dontShowPrediction
                              ? "transparent"
                              : prediction == "No Results"
                              ? "transparent"
                              : remainingCount == 0
                              ? "transparent"
                              : noMoreImages
                              ? "transparent"
                              : loadedModel
                              ? numberOfImagesInModel < 1
                                ? "transparent"
                                : labelWeakColors[
                                    Object.keys(labelWeakColors)[
                                      labels.indexOf(prediction.label)
                                    ]
                                  ]
                              : numberOfImagesInModel < 3
                              ? "transparent"
                              : labelWeakColors[
                                  Object.keys(labelWeakColors)[
                                    labels.indexOf(prediction.label)
                                  ]
                                ],
                            width: "100%",
                            justifyContent: "center",
                            paddingTop: 10,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Inter_700Bold",
                              fontSize: 16,
                              color: colors.greyDark,
                            }}
                          >
                            Prediction:
                          </Text>
                          <Text
                            style={{
                              fontFamily: dontShowPrediction
                                ? "Inter_500Medium"
                                : remainingCount == 0
                                ? "Inter_500Medium"
                                : noMoreImages
                                ? "Inter_500Medium"
                                : loadedModel
                                ? numberOfImagesInModel < 1
                                  ? "Inter_500Medium"
                                  : "Inter_600SemiBold"
                                : numberOfImagesInModel < 3
                                ? "Inter_500Medium"
                                : "Inter_600SemiBold",
                              fontSize: 16,
                              paddingLeft: 5,
                              color: dontShowPrediction
                                ? "black"
                                : remainingCount == 0
                                ? "black"
                                : noMoreImages
                                ? "black"
                                : loadedModel
                                ? numberOfImagesInModel < 1
                                  ? "black"
                                  : labelStrongColors[
                                      Object.keys(labelStrongColors)[
                                        labels.indexOf(prediction.label)
                                      ]
                                    ]
                                : numberOfImagesInModel < 3
                                ? "black"
                                : labelStrongColors[
                                    Object.keys(labelStrongColors)[
                                      labels.indexOf(prediction.label)
                                    ]
                                  ],
                            }}
                          >
                            {dontShowPrediction
                              ? "-"
                              : noMoreImages
                              ? "-"
                              : remainingCount == 0
                              ? "-"
                              : loadedModel
                              ? numberOfImagesInModel < 1
                                ? "-"
                                : isPredicting
                                ? "-"
                                : prediction.label
                              : numberOfImagesInModel < 3
                              ? "-"
                              : isPredicting
                              ? "-"
                              : prediction.label}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                )}
                {imagesSuccessfullyUploaded && remainingCount != 0 && (
                  <View
                    style={{
                      backgroundColor: "white",
                      height: "25%",
                      width: "100%",
                      borderTopWidth: 1,
                      borderColor: colors.greyBorder,
                      justifyContent: "center",
                    }}
                  >
                    {showLabelIndicator && (
                      <Animatable.View //Help bubble
                        animation={"zoomIn"}
                        delay={3500}
                        style={{
                          backgroundColor: colors.greyDarkHover,
                          width: 275,
                          alignSelf: "center",
                          position: "absolute",
                          top: -10,
                          height: 45,
                          marginTop: 0,
                          zIndex: 2,
                          borderRadius: 10,
                          alignItems: "center",
                          justifyContent: "center",
                          shadowColor: "black",
                          shadowOpacity: 0.2,
                          shadowRadius: 25,
                          shadowOffset: {
                            height: 7,
                            width: 1,
                          },
                        }}
                      >
                        <Text
                          style={{
                            color: "white",
                            fontFamily: "Inter_700Bold",
                          }}
                        >
                          Begin labeling & the AI will learn 👇
                        </Text>
                        <TouchableOpacity
                          onPress={() => setShowLabelIndicator(false)}
                          style={{
                            position: "absolute",
                            top: -10,
                            right: -10,
                            backgroundColor: colors.greyCancel,
                            justifyContent: "center",
                            alignItems: "center",
                            height: 25,
                            width: 25,
                            borderRadius: 100,
                            shadowColor: "black",
                            shadowOpacity: 0,
                            shadowRadius: 5,
                            shadowOffset: {
                              height: 1,
                              width: 1,
                            },
                          }}
                        >
                          <Text>X</Text>
                        </TouchableOpacity>
                      </Animatable.View>
                    )}
                    <View
                      style={{
                        alignSelf: "center",
                        width: "60%",
                        flexWrap: "wrap",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      {labels.slice(0, 3).map((label, index) => {
                        return (
                          <View key={index} style={{ flex: 1, width: "23%" }}>
                            <CategoryButton
                              index={index + 1}
                              label={label}
                              allLabels={labels}
                            />
                          </View>
                        );
                      })}
                    </View>
                    <View
                      style={{
                        alignSelf: "center",
                        width: "50%",
                        flexWrap: "wrap",
                        alignItems: "center",
                        flexDirection: "row",
                        marginTop: 15,
                      }}
                    >
                      {labels.slice(3, 5).map((label, index) => {
                        return (
                          <View
                            key={index + 3}
                            style={{ flex: 1, width: "25%" }}
                          >
                            <CategoryButton
                              index={index + 4}
                              label={label}
                              allLabels={labels}
                            />
                          </View>
                        );
                      })}
                    </View>
                  </View>
                )}
              </View>
            </View>

            <View //Right Sidebar
              style={{
                backgroundColor: "white",
                borderLeftWidth: 1,
                borderColor: colors.greyBorder,
                height: height,
                width: "30%",
                shadowColor: "rgba(179, 179, 179, 1)",
                shadowOpacity: 0.2, //was set to .2
                shadowRadius: 15,
                shadowOffset: {
                  height: 0,
                  width: -2,
                },
              }}
            >
              <View //Help & Profile
                style={{
                  flexDirection: "row",
                  width: "100%",
                  paddingRight: "3%",
                  justifyContent: "flex-end",
                  paddingTop: 20,
                  marginBottom: -20,
                  zIndex: 100,
                }}
              >
                {showHelpIndicator && (
                  <Animatable.View
                    animation={"zoomIn"}
                    delay={3500}
                    style={{
                      backgroundColor: colors.greyDarkHover,
                      width: 185,
                      height: 45,
                      marginTop: 0,
                      zIndex: 2,
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                      shadowColor: "black",
                      shadowOpacity: 0.2,
                      shadowRadius: 25,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    }}
                  >
                    <Text
                      style={{ color: "white", fontFamily: "Inter_700Bold" }}
                    >
                      Need Help? Click here 👉
                    </Text>
                    <TouchableOpacity
                      onPress={() => setShowHelpIndicator(false)}
                      style={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        backgroundColor: colors.greyCancel,
                        justifyContent: "center",
                        alignItems: "center",
                        height: 25,
                        width: 25,
                        borderRadius: 100,
                        shadowColor: "black",
                        shadowOpacity: 0,
                        shadowRadius: 5,
                        shadowOffset: {
                          height: 1,
                          width: 1,
                        },
                      }}
                    >
                      <Text>X</Text>
                    </TouchableOpacity>
                  </Animatable.View>
                )}
                <Hoverable>
                  {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => (
                        setShowHelp(true), setShowHelpIndicator(false)
                      )}
                      style={{
                        flexDirection: "row",
                        borderRadius: 10,
                        backgroundColor: hovered ? colors.greySidebar : "white",
                        paddingHorizontal: 20,
                        marginHorizontal: 2.5,
                        height: 45,
                        alignItems: "center",
                      }}
                    >
                      <Ionicons
                        name="help-circle-outline"
                        size={25}
                        color={colors.greyDark}
                      />
                      <Text
                        style={{
                          paddingLeft: 10,
                          fontSize: 15,
                          fontFamily: "Inter_500Medium",
                          color: colors.greyDark,
                        }}
                      >
                        Help
                      </Text>
                    </TouchableOpacity>
                  )}
                </Hoverable>
                {signInCheck == "signed_in" && (
                  <Hoverable>
                    {({ hovered }) => (
                      <TouchableOpacity
                        onPress={() => setShowProfileInfo(true)}
                        style={{
                          borderRadius: 10,
                          backgroundColor: hovered
                            ? colors.greySidebar
                            : "white",
                          paddingHorizontal: 20,
                          marginHorizontal: 2.5,
                          height: 45,
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Ionicons
                          name="person-outline"
                          size={25}
                          color={colors.greyDark}
                        />
                        <Text
                          style={{
                            paddingLeft: 10,
                            fontSize: 15,
                            fontFamily: "Inter_500Medium",
                            color: colors.greyDark,
                          }}
                        >
                          Profile
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Hoverable>
                )}
              </View>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "13%",
                }}
              ></View>
              {showPrediction && (
                <View
                  style={{
                    marginTop: "-10%",
                    alignItems: "flex-start",
                    paddingHorizontal: "7%",
                    paddingBottom: "5%",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 16,
                        color: colors.greyDark,
                        paddingRight: 5,
                      }}
                    >
                      Prediction:
                    </Text>
                    <Text
                      style={{
                        fontSize: 16,
                        paddingHorizontal: 10,
                        paddingVertical: 7,
                        borderRadius: 50,
                        fontFamily: dontShowPrediction
                          ? "Inter_500Medium"
                          : remainingCount == 0
                          ? "Inter_500Medium"
                          : noMoreImages
                          ? "Inter_500Medium"
                          : loadedModel
                          ? numberOfImagesInModel < 1
                            ? "Inter_500Medium"
                            : "Inter_600SemiBold"
                          : numberOfImagesInModel < 3
                          ? "Inter_500Medium"
                          : "Inter_600SemiBold",
                        backgroundColor: dontShowPrediction
                          ? "transparent"
                          : remainingCount == 0
                          ? "transparent"
                          : noMoreImages
                          ? "transparent"
                          : loadedModel
                          ? numberOfImagesInModel < 1
                            ? "transparent"
                            : prediction.label == labels[0]
                            ? colors.greenLabel
                            : prediction.label == labels[1]
                            ? colors.redLabel
                            : prediction.label == labels[2]
                            ? colors.blueLabel
                            : prediction.label == labels[3]
                            ? colors.purpleLabel
                            : prediction.label == labels[4]
                            ? colors.orangeLabel
                            : "transparent"
                          : numberOfImagesInModel < 3
                          ? "transparent"
                          : prediction.label == labels[0]
                          ? colors.greenLabel
                          : prediction.label == labels[1]
                          ? colors.redLabel
                          : prediction.label == labels[2]
                          ? colors.blueLabel
                          : prediction.label == labels[3]
                          ? colors.purpleLabel
                          : prediction.label == labels[4]
                          ? colors.orangeLabel
                          : "transparent",
                        color: dontShowPrediction
                          ? "black"
                          : remainingCount == 0
                          ? "black"
                          : noMoreImages
                          ? "black"
                          : loadedModel
                          ? numberOfImagesInModel < 1
                            ? "black"
                            : prediction.label == labels[0]
                            ? colors.greenLabelBorder
                            : prediction.label == labels[1]
                            ? colors.redLabelBorder
                            : prediction.label == labels[2]
                            ? colors.blueLabelBorder
                            : prediction.label == labels[3]
                            ? colors.purpleLabelBorder
                            : prediction.label == labels[4]
                            ? colors.orangeLabelBorder
                            : "black"
                          : numberOfImagesInModel < 3
                          ? "black"
                          : prediction.label == labels[0]
                          ? colors.greenLabelBorder
                          : prediction.label == labels[1]
                          ? colors.redLabelBorder
                          : prediction.label == labels[2]
                          ? colors.blueLabelBorder
                          : prediction.label == labels[3]
                          ? colors.purpleLabelBorder
                          : prediction.label == labels[4]
                          ? colors.orangeLabelBorder
                          : "black",
                      }}
                    >
                      {dontShowPrediction
                        ? "-"
                        : noMoreImages
                        ? "-"
                        : remainingCount == 0
                        ? "-"
                        : loadedModel
                        ? numberOfImagesInModel < 1
                          ? "-"
                          : isPredicting
                          ? "-"
                          : prediction.label
                        : numberOfImagesInModel < 3
                        ? "-"
                        : isPredicting
                        ? "-"
                        : prediction.label}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 5,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 16,
                        color: colors.greyDark,
                      }}
                    >
                      Confidence:
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter_500Medium",
                        fontSize: 16,
                        paddingLeft: 10,
                        paddingVertical: 7,
                        borderRadius: 50,
                        color: colors.greyDark,
                      }}
                    >
                      {noMoreImages
                        ? ""
                        : remainingCount == 0
                        ? ""
                        : loadedModel
                        ? numberOfImagesInModel < 1
                          ? "-"
                          : isPredicting
                          ? "-"
                          : predictionConfidence
                        : numberOfImagesInModel < 3
                        ? "-"
                        : isPredicting
                        ? "-"
                        : predictionConfidence}
                    </Text>

                    {loadedModel && numberOfImagesInModel < 1 && (
                      <Text
                        style={{
                          fontFamily: "Inter_500Medium",
                          fontSize: 20,
                          paddingVertical: 7,
                          borderRadius: 50,
                          color: colors.greyDark,
                        }}
                      >
                        %
                      </Text>
                    )}
                    <Text
                      style={{
                        fontFamily: "Inter_500Medium",
                        fontSize: 16,
                        paddingLeft: 5,
                        paddingVertical: 7,
                        borderRadius: 50,
                        color: colors.greyDark,
                      }}
                    >
                      {noMoreImages
                        ? ""
                        : remainingCount == 0
                        ? ""
                        : loadedModel
                        ? numberOfImagesInModel < 1
                          ? ""
                          : isPredicting
                          ? ""
                          : "%"
                        : numberOfImagesInModel < 3
                        ? ""
                        : isPredicting
                        ? ""
                        : "%"}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 5,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 16,
                        color: colors.greyDark,
                      }}
                    >
                      Model Strength:
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter_500Medium",
                        fontSize: 16,
                        paddingHorizontal: 10,
                        paddingVertical: 7,
                        borderRadius: 50,
                        color: colors.greyDark,
                      }}
                    >
                      {loadedModel
                        ? strength
                        : numberOfImagesInModel < 3
                        ? "-"
                        : strength}
                    </Text>
                  </View>

                  <View
                    style={{
                      marginTop: 10,
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Hoverable style={{ width: "100%" }}>
                      {({ hovered }) => (
                        <Pressable
                          disabled={
                            strength == "Weak"
                              ? true //set to true
                              : remainingCount == 0
                              ? true
                              : false
                          }
                          onPress={() => (
                            setPressedPowerLabel(true),
                            setSliderMax(
                              remainingCount > 499 ? 500 : remainingCount
                            )
                          )}
                          style={{
                            height: 50,
                            borderRadius: 5,
                            alignItems: "center",
                            justifyContent: "center",
                            shadowColor: "black",
                            shadowOpacity:
                              strength == "Weak"
                                ? 0
                                : remainingCount == 0
                                ? 0
                                : hovered
                                ? 0.3
                                : 0.1,
                            shadowRadius: 25,
                            shadowOffset: {
                              height: 7,
                              width: 1,
                            },
                          }}
                        >
                          <LinearGradient
                            style={{
                              height: "100%",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 5,
                            }}
                            start={{ x: 0, y: 0 }}
                            end={{ x: 0.5, y: 0.5 }}
                            colors={
                              strength == "Weak"
                                ? [colors.greyDisabled, colors.greyDisabled]
                                : remainingCount == 0
                                ? [colors.greyDisabled, colors.greyDisabled]
                                : [colors.red, colors.purpleLabelBorder]
                            }
                          >
                            {strength == "Weak" ? (
                              <Text
                                style={{
                                  fontFamily: "Inter_700Bold",
                                  fontSize: 16,
                                  color: colors.greyLightFont,
                                }}
                              >
                                Power Label
                              </Text>
                            ) : remainingCount == 0 ? (
                              <Text
                                style={{
                                  fontFamily: "Inter_700Bold",
                                  fontSize: 16,
                                  color: colors.greyLightFont,
                                }}
                              >
                                Power Label
                              </Text>
                            ) : (
                              <Text
                                style={{
                                  fontFamily: "Inter_700Bold",
                                  fontSize: 16,
                                  color: "white",
                                }}
                              >
                                Power Label ⚡️
                              </Text>
                            )}
                          </LinearGradient>
                        </Pressable>
                      )}
                    </Hoverable>
                  </View>
                </View>
              )}
              <View
                style={{ height: "25%", padding: "7%", alignItems: "center" }}
              >
                <View
                  style={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      height: "100%",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: colors.greyDisabled,
                        height: "100%",
                        width: "47.5%",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        shadowColor: "rgba(179, 179, 179, 1)",
                        shadowOpacity: 0,
                        shadowRadius: 5,
                        shadowOffset: { height: 2, width: 2 },
                      }}
                    >
                      {imagesUploaded != null && (
                        <Text
                          style={{
                            fontFamily: "Inter_700Bold",
                            fontSize: 30,
                            color: colors.greyDark,
                          }}
                        >
                          {numberOfImages}
                        </Text>
                      )}
                      <Text
                        style={{
                          fontFamily: "Inter_500Medium",
                          fontSize: 15,
                          color: colors.greyDark,
                        }}
                      >
                        Total Images
                      </Text>
                    </View>
                    <View style={{ width: "5%" }} />
                    <View
                      style={{
                        backgroundColor: colors.greyDisabled,
                        height: "100%",
                        width: "47.5%",
                        borderRadius: 5,
                        justifyContent: "center",
                        alignItems: "center",
                        shadowColor: "rgba(179, 179, 179, 1)",
                        shadowOpacity: 0,
                        shadowRadius: 5,
                        shadowOffset: { height: 2, width: 2 },
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Inter_700Bold",
                          fontSize: 30,
                          color: colors.greyDark,
                        }}
                      >
                        {numberOfImagesInModel}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "Inter_500Medium",
                          fontSize: 15,
                          color: colors.greyDark,
                        }}
                      >
                        Labeled
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{ height: 15 }} />
                <View
                  style={{
                    backgroundColor: colors.greyDisabled,
                    height: "100%",
                    width: "100%",
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    shadowColor: "rgba(179, 179, 179, 1)",
                    shadowOpacity: 0,
                    shadowRadius: 5,
                    shadowOffset: { height: 2, width: 2 },
                  }}
                >
                  {imagesUploaded != null && (
                    <Text
                      style={{
                        fontFamily: "Inter_700Bold",
                        fontSize: 30,
                        color: colors.greyDark,
                      }}
                    >
                      {remainingCount}
                    </Text>
                  )}
                  <Text
                    style={{
                      fontFamily: "Inter_500Medium",
                      fontSize: 15,
                      color: colors.greyDark,
                    }}
                  >
                    Remaining
                  </Text>
                </View>
              </View>
            </View>
          </Animated.View>
        </View>
      ) : (
        <View
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size={"large"} />
          <Text
            style={{
              fontFamily: "Inter_600SemiBold",
              fontSize: 16,
              color: colors.greyDark,
              paddingTop: 20,
            }}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
    fontSize: 50,
    backgroundColor: "transparent",
  },

  elementsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
  },
  element: {
    width: 80,
    height: 80,
    margin: 10,
    borderRadius: 5,
  },
});
