import React, { useState, useEffect, useContext } from "react";
import {
  Image,
  TextInput,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  Alert,
} from "react-native";
import { Overlay } from "react-native-elements";
import { Hoverable, Pressable } from "react-native-web-hover";
import { ActivityIndicator } from "react-native-web";
import { auth } from "../References/firebase.js";
import { colors } from "../Styles/colors";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { AuthContext } from "../References/Auth.js";

var validator = require("email-validator");

export default function LogIn_Editor({ navigation }) {
  const window = Dimensions.get("window");
  const [emailText, setEmailText] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [passwordText1, setPasswordText1] = useState("");
  const [loadingImages, setLoadingImages] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showNeedVerification, setShowNeedVerification] = useState(false);
  const [startedLogin, setStartedLogin] = useState(false);
  const { signInCheck, dimensions } = useContext(AuthContext);

  //Auth Provider Object(s)
  const googleProvider = new GoogleAuthProvider();

  //Google Sign Up / Login Function
  const googleSignUp = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        const ref = doc(db, "users", result.user.uid);
        const docRef = await setDoc(ref, {
          uid: result.user.uid,
          email: result.user.email,
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    validator.validate(emailText) ? setValidEmail(true) : setValidEmail(false);
  }, [emailText]);

  const width = dimensions.window.width;
  const height = dimensions.window.height;

  const forgotEmail = () => {
    sendPasswordResetEmail(auth, emailText)
      .then(() => {
        alert(
          "If an account associated with this email exists, a password reset link has been sent"
        );
      })
      .catch((error) => {
        if (error.code == "auth/user-not-found") {
          alert(
            "If an account associated with this email exists, a password reset link has been sent"
          );
        }
        alert(error.code);
      });
  };

  const handleLogIn = () => {
    setStartedLogin(true);
    signInWithEmailAndPassword(auth, emailText, passwordText1)
      .then((userCredentials) => {
        const user = userCredentials.user;
        setLoggedIn(true);
        auth.currentUser.emailVerified == false
          ? setShowNeedVerification(true)
          : setShowNeedVerification(false); //determine if verified
        setTimeout(() => {
          setLoggedIn(false);
        }, 1000); //show spinner
        setEmailText(""); //remove email text
        setPasswordText1(""); //remove password text
        setTimeout(() => {
          setShowNeedVerification(false);
        }, 3000); //stop showing error
        auth.currentUser.emailVerified == false &&
          signOut(auth).catch((error) => {
            alert(error);
          }); //log out if not verified
      })
      .catch((error) => {
        if (error.code == "auth/user-not-found") {
          setLoggedIn(false);
          alert("This email or password is incorrect");
        }
        if (error.code == "auth/invalid-email") {
          setLoggedIn(false);
          alert("Please enter a valid email");
        }
        if (error.code == "auth/wrong-password") {
          setLoggedIn(false);
          alert("This email or password is incorrect");
        } else {
          alert(error.code);
        }
      });
    setStartedLogin(false);
  };

  return (
    <View
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Overlay
        isVisible={loadingImages}
        fullScreen={true}
        overlayStyle={{ backgroundColor: "white", zIndex: 100 }}
      >
        <View
          style={{
            height: height,
            width: width,
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ActivityIndicator size="large" color={colors.blueLabelBorder} />
        </View>
      </Overlay>
      <View style={{ height: "100%", width: "100%", flexDirection: "row" }}>
        <View style={{ height: "100%", width: "55%" }}>
          <Image
            onLoadEnd={() => setLoadingImages(false)}
            style={{ height: "100%", width: "100%" }}
            source={require("../assets/LogIn.png")}
          />
        </View>
        <View
          style={{ height: "100%", width: "45%", backgroundColor: "white" }}
        >
          <View
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "white",
              justifyContent: "center",
              padding: "15%",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                fontSize: 40,
                paddingBottom: 40,
              }}
            >
              Login
            </Text>
            <View
              style={{
                flexDirection: width > 450 && "row",
              }}
            >
              <TouchableOpacity onPress={() => googleSignUp()}>
                <View
                  style={{
                    width: 190,
                    padding: 2,
                    borderRadius: 5,
                    backgroundColor: "#4086f4",
                    marginTop: width > 450 ? 15 : 5,
                    marginRight: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: 35,
                      height: 35,
                      backgroundColor: "white",
                      borderRadius: 3,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={require("../assets/Google_icon.png")}
                      style={{ width: 35, height: 35 }}
                    />
                  </View>
                  <View
                    style={{
                      width: 155,
                      alignItems: "center",
                      justifyContent: "center",
                      height: 35,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 13,
                      }}
                    >
                      Login with Google
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
            <View
              style={{
                width: width < 550 ? "100%" : "70%",
                height: 1,
                borderBottomWidth: 1,
                marginVertical: width > 450 ? 20 : 15,
                borderColor: colors.greyBorder,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: colors.greyLightFont,
                  backgroundColor: "white",
                  paddingHorizontal: 15,
                  marginTop: -8,
                }}
              >
                OR
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 16,
                paddingBottom: 15,
              }}
            >
              Email Address
            </Text>
            <TextInput
              style={{
                height: 40,
                width: "70%",
                borderWidth: 1,
                borderRadius: 10,
                borderColor: "grey",
                padding: 10,
              }}
              placeholder={"Email"}
              onChangeText={setEmailText}
              value={emailText}
            />
            <View
              style={{
                flexDirection: "row",
                width: "70%",
                paddingTop: 50,
                paddingBottom: 15,
                justifyContent: "space-between",
              }}
            >
              <Text style={{ fontFamily: "Inter_600SemiBold", fontSize: 16 }}>
                Password
              </Text>
              <TouchableOpacity
                onPress={() =>
                  validEmail
                    ? forgotEmail()
                    : alert("Please enter a valid email")
                }
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 15,
                    color: "#1a75ff",
                  }}
                >
                  Forgot Password?
                </Text>
              </TouchableOpacity>
            </View>
            <TextInput
              style={{
                height: 40,
                width: "70%",
                borderWidth: 1,
                borderRadius: 10,
                borderColor: "grey",
                padding: 10,
              }}
              placeholder={"Password"}
              onChangeText={setPasswordText1}
              secureTextEntry
              value={passwordText1}
              onKeyPress={(e) =>
                e.key == "Enter" &&
                emailText.length > 0 &&
                !startedLogin &&
                passwordText1.length > 0 &&
                handleLogIn()
              }
            />
            <Pressable
              onPress={() => handleLogIn()}
              disabled={
                emailText.length > 0 && passwordText1.length > 0 ? false : true
              }
              style={({ hovered }) => [
                {
                  height: 50,
                  width: 150,
                  borderRadius: 5,
                  marginTop: 40,
                  backgroundColor:
                    emailText.length > 0 && passwordText1.length > 0
                      ? hovered
                        ? "#d31253"
                        : "#eb1e63"
                      : "#f0f1f1",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                },
              ]}
            >
              <Text
                style={{
                  color:
                    emailText.length > 0 && passwordText1.length > 0
                      ? "white"
                      : "lightgrey",
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Login
              </Text>
              {!showNeedVerification && loggedIn && (
                <ActivityIndicator
                  color={"black"}
                  size={"small"}
                  style={{ paddingLeft: 10 }}
                />
              )}
            </Pressable>
            {showNeedVerification && (
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: "red",
                  paddingTop: 15,
                }}
              >
                Please verify your email to login
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}
