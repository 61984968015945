export const Medicine_hair_loss = [
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss1.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss1.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss2.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss3.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss4.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss3.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss4.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss5.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss6.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss5.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss6.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss9.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss7.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss7.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss8.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss9.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss10.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss11.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss11.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss12.jpg"),
    label: "Unlabeled",
  },

  {
    image: require("../Images/Medicine_hair_loss/Hairloss12.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss2.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss13.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss14.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss14.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/Hairloss15.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss15.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss8.jpg"),
    label: "Unlabeled",
  },
  {
    image: require("../Images/Medicine_hair_loss/No_Hairloss10.jpg"),
    label: "Unlabeled",
  },
];
