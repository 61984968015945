import React, { useState, useEffect } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { colors } from "../Styles/colors.js";
import { Feather, Ionicons } from "@expo/vector-icons";
import { Hoverable, Pressable } from "react-native-web-hover";

import { format } from "date-fns";
import Moment from "moment";
export default function ExistingProjectsItem({
  item,
  index,
  goTo,
  openProjectsWindow,
  pressedDelete,
}) {
  return (
    <View
      style={{
        height: 40,
        flexDirection: "row",
        backgroundColor: "white",
        alignItems: "center",
        padding: 15,
        borderTopWidth: index == 0 ? 0 : 1,
        borderColor: colors.greyBorder,
      }}
    >
      <Text
        style={{
          fontFamily: "Inter_600SemiBold",
          fontSize: 11,
          color: colors.greyDark,
          width: "50%",
        }}
      >
        {item.projectName}
      </Text>
      <Text
        style={{
          fontFamily: "Inter_600SemiBold",
          fontSize: 11,
          color: colors.greyDark,
          width: "15%",
          textAlign: "center",
        }}
      >
        {item?.images?.toString() == "" ? "0" : item?.images?.toString()}
      </Text>
      <Text
        style={{
          fontFamily: "Inter_600SemiBold",
          fontSize: 11,
          color: colors.greyDark,
          width: "15%",
          textAlign: "center",
        }}
      >
        {Moment(item.timestamp?.toDate()).format("ll").toString()}
      </Text>
      <Hoverable style={{ width: "15%" }}>
        {({ hovered }) => (
          <TouchableOpacity
            onPress={() => (
              goTo("Editor_Cloud", {
                projectName: item.projectName,
                uploadImagesAndLabels: false,
              }),
              openProjectsWindow(false)
            )}
          >
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: colors.blueLabelBorder,
                textAlign: "center",
                padding: 5,
                borderRadius: 5,
                backgroundColor: hovered ? colors.greySidebar : "white",
              }}
            >
              Select
            </Text>
          </TouchableOpacity>
        )}
      </Hoverable>
      <Hoverable style={{ width: "5%", alignItems: "flex-end" }}>
        {({ hovered }) => (
          <TouchableOpacity
            style={{
              padding: 10,
              borderRadius: 100,
              backgroundColor: hovered ? colors.greySidebar : "white",
            }}
            onPress={() => pressedDelete(item.projectName)}
          >
            <Feather name="trash-2" size={14} color={"red"} />
          </TouchableOpacity>
        )}
      </Hoverable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: 140,
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderRadius: 10,
    marginHorizontal: 15,
    marginVertical: 10,
  },
});
