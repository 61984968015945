import React, { useState, useEffect, useRef, useContext } from "react";
import { colors } from "../Styles/colors.js";
import { Text, View } from "react-native";
import * as Animatable from "react-native-animatable";
import { Ionicons } from "@expo/vector-icons";
import { auth } from "../References/firebase";
import { AuthContext } from "../References/Auth";

export default function HomeCards() {
  const { signInCheck, width } = useContext(AuthContext);
  const [premiumStatus, setPremiumStatus] = useState(false);
  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }
  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  const animation = useRef(null);

  if (width < 300) {
    return (
      <View //cards
        style={{
          backgroundColor: "transparent",
          marginTop: -70,
          justifyContent: "space-between",
          width: 260,
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <Animatable.View //card 1
          animation={"fadeIn"}
          delay={100}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons name="git-compare-outline" size={40} color={colors.red} />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              +50% Faster Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Save time & money on your ML projects
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 2
          animation={"fadeIn"}
          delay={200}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "100%",
            marginBottom: 10,
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="images-outline"
              size={40}
              color={colors.orangeLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Unlimited Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No limits to uploads
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 3
          animation={"fadeIn"}
          delay={300}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="code-slash-outline"
              size={40}
              color={colors.blueLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              No Code
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No coding required
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 4
          animation={"fadeIn"}
          delay={400}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 30,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="shapes-outline"
              size={40}
              color={colors.purpleLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Simple & Intuitive
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Everything you need out of the box
            </Text>
          </View>
        </Animatable.View>
      </View>
    );
  }
  if (width < 345) {
    return (
      <View //cards
        style={{
          backgroundColor: "transparent",
          marginTop: -70,
          justifyContent: "space-between",
          width: 280,
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <Animatable.View //card 1
          animation={"fadeIn"}
          delay={100}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons name="git-compare-outline" size={40} color={colors.red} />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              +50% Faster Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Save time & money on your ML projects
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 2
          animation={"fadeIn"}
          delay={200}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "100%",
            marginBottom: 10,
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="images-outline"
              size={40}
              color={colors.orangeLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Unlimited Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No limits to uploads
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 3
          animation={"fadeIn"}
          delay={300}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="code-slash-outline"
              size={40}
              color={colors.blueLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              No Code
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No coding required
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 4
          animation={"fadeIn"}
          delay={400}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 30,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="shapes-outline"
              size={40}
              color={colors.purpleLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Simple & Intuitive
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Everything you need out of the box
            </Text>
          </View>
        </Animatable.View>
      </View>
    );
  }
  if (width < 435) {
    return (
      <View //cards
        style={{
          backgroundColor: "transparent",
          marginTop: -70,
          justifyContent: "space-between",
          width: 300,
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <Animatable.View //card 1
          animation={"fadeIn"}
          delay={100}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons name="git-compare-outline" size={40} color={colors.red} />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              +50% Faster Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Save time & money on your ML projects
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 2
          animation={"fadeIn"}
          delay={200}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "100%",
            marginBottom: 10,
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="images-outline"
              size={40}
              color={colors.orangeLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Unlimited Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No limits to uploads
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 3
          animation={"fadeIn"}
          delay={300}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="code-slash-outline"
              size={40}
              color={colors.blueLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              No Code
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No coding required
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 4
          animation={"fadeIn"}
          delay={400}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 30,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="shapes-outline"
              size={40}
              color={colors.purpleLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Simple & Intuitive
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Everything you need out of the box
            </Text>
          </View>
        </Animatable.View>
      </View>
    );
  }
  if (width < 550) {
    return (
      <View //cards
        style={{
          backgroundColor: "transparent",
          marginTop: -70,
          justifyContent: "space-between",
          width: 350,
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <Animatable.View //card 1
          animation={"fadeIn"}
          delay={100}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons name="git-compare-outline" size={40} color={colors.red} />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              +50% Faster Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Save time & money on your ML projects
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 2
          animation={"fadeIn"}
          delay={200}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "100%",
            marginBottom: 10,
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="images-outline"
              size={40}
              color={colors.orangeLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Unlimited Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No limits to uploads
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 3
          animation={"fadeIn"}
          delay={300}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="code-slash-outline"
              size={40}
              color={colors.blueLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              No Code
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No coding required
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 4
          animation={"fadeIn"}
          delay={400}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 30,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="shapes-outline"
              size={40}
              color={colors.purpleLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Simple & Intuitive
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Everything you need out of the box
            </Text>
          </View>
        </Animatable.View>
      </View>
    );
  }
  if (width < 650) {
    return (
      <View //cards
        style={{
          backgroundColor: "transparent",
          marginTop: -70,
          justifyContent: "space-between",
          width: 450,
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <Animatable.View //card 1
          animation={"fadeIn"}
          delay={100}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons name="git-compare-outline" size={40} color={colors.red} />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              +50% Faster Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Save time & money on your ML projects
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 2
          animation={"fadeIn"}
          delay={200}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "100%",
            marginBottom: 10,
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="images-outline"
              size={40}
              color={colors.orangeLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Unlimited Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No limits to uploads
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 3
          animation={"fadeIn"}
          delay={300}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 10,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="code-slash-outline"
              size={40}
              color={colors.blueLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              No Code
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              No coding required
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View //card 4
          animation={"fadeIn"}
          delay={400}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            marginBottom: 30,
            width: "100%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="shapes-outline"
              size={40}
              color={colors.purpleLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_700Bold",
                color: "black",
              }}
            >
              Simple & Intuitive
            </Text>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                fontSize: 13,
                color: "black",
                opacity: 0.4,
              }}
            >
              Everything you need out of the box
            </Text>
          </View>
        </Animatable.View>
      </View>
    );
  }
  if (width < 775) {
    return (
      <View>
        <View //2x2 cards (1)
          style={{
            backgroundColor: "transparent",
            //  marginTop: -50,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            width: 600,
            alignSelf: "center",
            alignItems: "center",
            height: 80,
          }}
        >
          <Animatable.View //card 1
            animation={"fadeIn"}
            delay={100}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="git-compare-outline"
                size={40}
                color={colors.red}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                +50% Faster Labeling
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                Save time & money on your ML projects
              </Text>
            </View>
          </Animatable.View>
          <Animatable.View //card 2
            animation={"fadeIn"}
            delay={200}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="images-outline"
                size={40}
                color={colors.orangeLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                Unlimited Labeling
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                No limits to uploads
              </Text>
            </View>
          </Animatable.View>
        </View>
        <View //2x2 cards (2)
          style={{
            backgroundColor: "transparent",
            marginTop: 10,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            width: 600,
            alignSelf: "center",
            alignItems: "center",
            height: 80,
          }}
        >
          <Animatable.View //card 3
            animation={"fadeIn"}
            delay={300}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="code-slash-outline"
                size={40}
                color={colors.blueLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                No Code
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                No coding required
              </Text>
            </View>
          </Animatable.View>
          <Animatable.View //card 4
            animation={"fadeIn"}
            delay={400}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="shapes-outline"
                size={40}
                color={colors.purpleLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                Simple & Intuitive
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                Everything you need out of the box
              </Text>
            </View>
          </Animatable.View>
        </View>
      </View>
    );
  }
  if (width < 940) {
    return (
      <View>
        <View //2x2 cards (1)
          style={{
            backgroundColor: "transparent",
            //  marginTop: -50,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            width: 750,
            alignSelf: "center",
            alignItems: "center",
            height: 80,
          }}
        >
          <Animatable.View //card 1
            animation={"fadeIn"}
            delay={100}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="git-compare-outline"
                size={40}
                color={colors.red}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                +50% Faster Labeling
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                Save time & money on your ML projects
              </Text>
            </View>
          </Animatable.View>
          <Animatable.View //card 2
            animation={"fadeIn"}
            delay={200}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="images-outline"
                size={40}
                color={colors.orangeLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                Unlimited Labeling
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                No limits to uploads
              </Text>
            </View>
          </Animatable.View>
        </View>
        <View //2x2 cards (2)
          style={{
            backgroundColor: "transparent",
            marginTop: 10,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            width: 750,
            alignSelf: "center",
            alignItems: "center",
            height: 80,
          }}
        >
          <Animatable.View //card 3
            animation={"fadeIn"}
            delay={300}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="code-slash-outline"
                size={40}
                color={colors.blueLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                No Code
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                No coding required
              </Text>
            </View>
          </Animatable.View>
          <Animatable.View //card 4
            animation={"fadeIn"}
            delay={400}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "49.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="shapes-outline"
                size={40}
                color={colors.purpleLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                Simple & Intuitive
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                Everything you need out of the box
              </Text>
            </View>
          </Animatable.View>
        </View>
      </View>
    );
  }
  if (width < 1170) {
    return (
      <View>
        <View //2x2 cards (1)
          style={{
            backgroundColor: "transparent",
            //  marginTop: -50,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: 930,
            alignSelf: "center",
            alignItems: "center",
            height: 80,
          }}
        >
          <Animatable.View //card 1
            animation={"fadeIn"}
            delay={100}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "48.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="git-compare-outline"
                size={40}
                color={colors.red}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                +50% Faster Labeling
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                Save time & money on your ML projects
              </Text>
            </View>
          </Animatable.View>
          <Animatable.View //card 2
            animation={"fadeIn"}
            delay={200}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "48.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="images-outline"
                size={40}
                color={colors.orangeLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                Unlimited Labeling
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                No limits to uploads
              </Text>
            </View>
          </Animatable.View>
        </View>
        <View //2x2 cards (2)
          style={{
            backgroundColor: "transparent",
            marginTop: 10,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: 940,
            alignSelf: "center",
            alignItems: "center",
            height: 80,
          }}
        >
          <Animatable.View //card 3
            animation={"fadeIn"}
            delay={300}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "48.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="code-slash-outline"
                size={40}
                color={colors.blueLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                No Code
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                No coding required
              </Text>
            </View>
          </Animatable.View>
          <Animatable.View //card 4
            animation={"fadeIn"}
            delay={400}
            style={{
              backgroundColor: "white",
              height: 70,
              padding: 5,
              width: "48.25%",
              borderRadius: 10,
              alignItems: "center",
              flexDirection: "row",
              shadowColor: "black",
              shadowOpacity: 0.1,
              shadowRadius: 25,
              shadowOffset: {
                height: 7,
                width: 1,
              },
            }}
          >
            <View
              style={{
                height: 60,
                width: 60,
                justifyContent: "center",
                alignItems: "center",

                borderRadius: 5,
                backgroundColor: colors.greySidebar,
              }}
            >
              <Ionicons
                name="shapes-outline"
                size={40}
                color={colors.purpleLabelBorder}
              />
            </View>
            <View style={{ paddingLeft: 10, width: "70%" }}>
              <Text
                style={{
                  fontFamily: "Inter_700Bold",
                  color: "black",
                }}
              >
                Simple & Intuitive
              </Text>
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 13,
                  color: "black",
                  opacity: 0.4,
                }}
              >
                Everything you need out of the box
              </Text>
            </View>
          </Animatable.View>
        </View>
      </View>
    );
  } else {
    return (
      <View //4 cards
        style={{
          zIndex: 20,
          backgroundColor: "transparent",
          //  marginTop: -50,
          marginBottom: 10,
          flexDirection: "row",
          justifyContent: "space-evenly",
          width: 1215,
          alignSelf: "center",
          alignItems: "center",
          height: 80,
        }}
      >
        <Animatable.View
          animation={"fadeInUp"}
          delay={100}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "22%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons name="git-compare-outline" size={40} color={colors.red} />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                color: "black",
              }}
            >
              +50% Faster Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_400Regular",
                fontSize: 11,
                color: "black",
              }}
            >
              Save time & money on your ML projects
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View
          animation={"fadeInUp"}
          delay={200}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "22%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="images-outline"
              size={40}
              color={colors.orangeLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                color: "black",
              }}
            >
              Unlimited Labeling
            </Text>
            <Text
              style={{
                fontFamily: "Inter_400Regular",
                fontSize: 11,
                color: "black",
              }}
            >
              No limits to uploads
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View
          animation={"fadeInUp"}
          delay={300}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "22%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="code-slash-outline"
              size={40}
              color={colors.blueLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                color: "black",
              }}
            >
              No Code
            </Text>
            <Text
              style={{
                fontFamily: "Inter_400Regular",
                fontSize: 11,
                color: "black",
              }}
            >
              No coding required
            </Text>
          </View>
        </Animatable.View>
        <Animatable.View
          animation={"fadeInUp"}
          delay={400}
          style={{
            backgroundColor: "white",
            height: 70,
            padding: 5,
            width: "22%",
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            shadowColor: "black",
            shadowOpacity: 0.1,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View
            style={{
              height: 60,
              width: 60,
              justifyContent: "center",
              alignItems: "center",

              borderRadius: 5,
              backgroundColor: colors.greySidebar,
            }}
          >
            <Ionicons
              name="shapes-outline"
              size={40}
              color={colors.purpleLabelBorder}
            />
          </View>
          <View style={{ paddingLeft: 10, width: "70%" }}>
            <Text
              style={{
                fontFamily: "Inter_600SemiBold",
                color: "black",
              }}
            >
              Simple & Intuitive
            </Text>
            <Text
              style={{
                fontFamily: "Inter_400Regular",
                fontSize: 11,
                color: "black",
              }}
            >
              Everything you need out of the box
            </Text>
          </View>
        </Animatable.View>
      </View>
    );
  }
}
