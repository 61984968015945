export const Agriculture_ripe_fruit = [
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green1.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe21.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe1.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe1.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe21.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe17.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green21.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe18.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green11.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe11.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe2.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green4.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe18.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe2.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe4.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green6.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe5.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe4.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green7.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe5.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe8.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe22.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe7.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green12.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe10.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe19.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green14.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe12.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green5.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe22.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green15.png"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe19.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe15.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green16.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe12.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe13.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green17.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe16.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe17.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green18.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe15.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green19.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe16.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/ripe20.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green20.png"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/overripe20.jpg"),
  },

  {
    label: "Unlabeled",
    image: require("../Images/Agriculture_ripe_fruit/green22.jpg"),
  },
];
