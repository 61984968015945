import React, { useState, useEffect, useContext, useRef } from "react";
import { colors } from "../Styles/colors.js";
import {
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import LottieView from "react-native-web-lottie";
import { LinearGradient } from "expo-linear-gradient";
import { createCheckoutSession } from "../Stripe/createCheckoutSession";
import { auth } from "../References/firebase";
import { AuthContext } from "../References/Auth";
import { ENV_ST_MO, ENV_POST_SUBSCR, ENV_POST_CANCEL_SUBSCR } from "@env";
import { deviceType } from "react-device-detect";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButton";
import EmailSignup from "./EmailSignup";
import { Overlay } from "react-native-elements";
import { color } from "react-native-reanimated";
var validator = require("email-validator");

export default function HomeTitle(props) {
  const [showingCheckoutTop, setShowingCheckoutTop] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const { signInCheck, width, height } = useContext(AuthContext);
  const animation = useRef(null);

  const upgradeFunction = () => {
    setShowingCheckoutTop(true),
      createCheckoutSession(
        auth.currentUser?.uid,
        ENV_ST_MO,
        ENV_POST_SUBSCR,
        ENV_POST_CANCEL_SUBSCR
      ),
      setTimeout(() => {
        setShowingCheckoutTop(false);
      }, 5000);
  };
  const CustomText = (props) => {
    const text = props.text.split(" ");

    return (
      <Text
        style={{
          letterSpacing: -2,
          fontFamily: "Inter_600SemiBold",
          fontSize: 50,
          width: 800,
          textAlign: "center",
          color: "black",
        }}
      >
        {text.map((text, index) => {
          if (text == "Automate") {
            return (
              <Text
                key={index}
                style={{
                  color: "black",
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                {text}
              </Text>
            );
          }
          return `${text} `;
        })}
      </Text>
    );
  };

  return (
    <View //Landing Page Title
      style={{
        height: height * 0.6,
        width: 750,
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 60,
        alignSelf: "center",
      }}
    >
      <Overlay //Give email
        isVisible={showSignUp}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EmailSignup setShowSignUp={setShowSignUp} />
      </Overlay>
      <CustomText text="Label Images Faster" />
      <Text
        style={{
          fontFamily: "Inter_500Medium",
          fontSize: 18,
          textAlign: "center",
          color: "black",

          paddingBottom: 30,
          paddingTop: 20,
          width: 650,
        }}
      >
        AI-assisted automated image labeling that is easy, fast, and private.
        Expedite your image data labeling in an in-browser editor.
      </Text>
      <View
        style={{
          width: 310,
          height: 50,
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {signInCheck == "signed_out" && (
          <MainButton
            disabled={false}
            onPress={() => setShowSignUp(true)}
            text={"Stay Updated"}
            showLoading={false}
          />
        )}
        {signInCheck == "signed_in" && props.premiumStatus != "premium" && (
          <MainButton
            disabled={showingCheckoutTop}
            onPress={upgradeFunction}
            text={"Upgrade"}
            showLoading={showingCheckoutTop}
          />
        )}
        {signInCheck == "signed_in" &&
          props.premiumStatus == "premium" &&
          deviceType == "browser" && (
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  onPress={() => props.goTo("Editor")}
                  style={{
                    width: 225,
                    height: 60,
                    borderRadius: 10,
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    shadowColor: "black",
                    shadowOpacity: hovered ? 0.3 : 0.1,
                    shadowRadius: 25,
                    shadowOffset: {
                      height: 7,
                      width: 1,
                    },
                  }}
                >
                  <LinearGradient
                    style={{
                      height: "100%",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 0.5, y: 0.5 }}
                    colors={[colors.red, colors.purpleLabelBorder]}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        color: "white",
                        fontFamily: "Inter_700Bold",
                      }}
                    >
                      Go to Editor
                    </Text>
                  </LinearGradient>
                </TouchableOpacity>
              )}
            </Hoverable>
          )}
        {signInCheck == "signed_in" && props.premiumStatus != "premium" && (
          <View style={{ width: 10 }} />
        )}
        {signInCheck == "signed_out" && <View style={{ width: 10 }} />}

        {signInCheck == "signed_out" && deviceType == "browser" && (
          <SecondaryButton
            disabled={false}
            onPress={() => props.goTo("Demo")}
            text={"Online Demo"}
            showLoading={false}
          />
        )}
        {signInCheck == "signed_in" &&
          props.premiumStatus != "premium" &&
          deviceType == "browser" && (
            <SecondaryButton
              disabled={showingCheckoutTop}
              onPress={() => props.goTo("Editor")}
              text={"Open Free Editor"}
              showLoading={1 == 2}
            />
          )}
      </View>
    </View>
  );
}
