import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Platform } from "react-native";
import Classification from "./Pages/Editor";
import Editor_Cloud from "./Pages/Editor_Cloud";
import Home from "./Pages/Home";
import Privacy from "./Pages/Privacy";
import TermsConditions from "./Pages/TermsConditions";
import Pricing from "./Pages/Pricing";
import Demo from "./Pages/Demo";
import SignUp from "./Pages/SignUp";
import LogIn from "./Pages/LogIn";
import About from "./Pages/About";
import ScreenTooSmall from "./Pages/ScreenTooSmall";
import Password from "./Pages/Password";
import Subscribed from "./Pages/Subscribed";
import ObjectDetection from "./Pages/TestEditor";
import Error from "./Pages/Error";
import { AuthProvider } from "./References/Auth";
import "@stripe/stripe-js";
import { deviceType, isAndroid, isIOS } from "react-device-detect";
import { MenuProvider } from "react-native-popup-menu";
import {
  useFonts,
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  Inter_700Bold,
  Inter_800ExtraBold,
  Inter_900Black,
} from "@expo-google-fonts/inter";

const Stack = createNativeStackNavigator();

const config = {
  screens: {
    Home: "home",
    Password: "password",
    Demo: "editor/demo",
    Editor_Cloud: "editor/editor_cloud/:projectName/:uploadImagesAndLabels",
    Classification: "editor/classification",
    ObjectDetection: "editor/object_detection",
    Privacy: "privacy",
    Pricing: "pricing",
    SignUp: "signup",
    LogIn: "login",
    About: "about",
    Subscribed: "subscribed",
    Error: "*",
    ScreenTooSmall: "dimensions",
    TermsConditions: "terms_and_conditions",
  },
};

const linking = {
  // prefixes: ['*boltlabel.com/*', '*localhost:19006/*'],
  config,
};

const App = () => {
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
  });
  return (
    <AuthProvider>
      <MenuProvider style={{ borderRadius: 20 }}>
        <NavigationContainer linking={linking}>
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
            }}
          >
            <Stack.Screen name="Home" component={Home} />
            {/*             <Stack.Screen name="Password" component={Password} />
             */}
            <Stack.Screen name="Privacy" component={Privacy} />
            <Stack.Screen name="About" component={About} />
            {/*             <Stack.Screen name="Subscribed" component={Subscribed} />
             */}
            {/* <Stack.Screen name="Editor_Cloud" component={Editor_Cloud} /> */}
            {/* <Stack.Screen name="Editor" component={Classification} /> */}
            {/* <Stack.Screen name="ObjectDetection" component={ObjectDetection} /> */}
            <Stack.Screen name="Demo" component={Demo} />
            <Stack.Screen name="ScreenTooSmall" component={ScreenTooSmall} />
            {/* <Stack.Screen name="Pricing" component={Pricing} /> */}
            {/* <Stack.Screen name="SignUp" component={SignUp} />
            <Stack.Screen name="LogIn" component={LogIn} /> */}
            <Stack.Screen name="Error" component={Error} />
            <Stack.Screen name="TermsConditions" component={TermsConditions} />
          </Stack.Navigator>
        </NavigationContainer>
      </MenuProvider>
    </AuthProvider>
  );
};
export default App;
