import React, { Component } from "react";
import Select from "react-select";

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options:
        props.labels.length < 3
          ? [
              { label: props.labels[0], value: props.labels[0] },
              { label: props.labels[1], value: props.labels[1] },
            ]
          : props.labels.length < 4
          ? [
              { label: props.labels[0], value: props.labels[0] },
              { label: props.labels[1], value: props.labels[1] },
              { label: props.labels[2], value: props.labels[2] },
            ]
          : props.labels.length < 5
          ? [
              { label: props.labels[0], value: props.labels[0] },
              { label: props.labels[1], value: props.labels[1] },
              { label: props.labels[2], value: props.labels[2] },
              { label: props.labels[3], value: props.labels[3] },
            ]
          : [
              { label: props.labels[0], value: props.labels[0] },
              { label: props.labels[1], value: props.labels[1] },
              { label: props.labels[2], value: props.labels[2] },
              { label: props.labels[3], value: props.labels[3] },
              { label: props.labels[4], value: props.labels[4] },
            ],
    };
  }

  handleChange = (e) => {
    let { onChange } = this.props;
    let v = e.value;
    onChange(v);
  };

  onDelete = () => {
    let { onDelete } = this.props;
    onDelete();
  };

  render() {
    //console.log("annotator", this.props);
    return (
      <div className="rp-default-input-section" style={{ zIndex: 100 }}>
        <div className="col col-md-8" style={{ width: 150 }}>
          <Select
            value={this.state.selectedOption}
            onChange={this.handleChange}
            options={this.state.options}
          />
        </div>
        <a
          className="rp-default-input-section_delete"
          onClick={() => this.onDelete()}
        >
          <svg
            className="rp-delete"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <g data-name="Layer 2">
              <g data-name="trash-2">
                <rect width="24" height="24" opacity="0" />
                <path d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 16a1 1 0 0 1-2 0v-4a1 1 0 0 1 2 0zm0-11.67c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM16 16a1 1 0 0 1-2 0v-4a1 1 0 0 1 2 0z" />
              </g>
            </g>
          </svg>
        </a>
      </div>
    );
  }
}
