import React, { useState, useEffect, useRef, useContext } from "react";
import { colors } from "../Styles/colors.js";
import {
  Switch,
  TextInput,
  ScrollView,
  FlatList,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  Popover,
} from "react-native-popup-menu";
import { Overlay } from "react-native-elements";
import ProfileInfo from "./ProfileInfo";
import ExistingProjectsItem from "./ExistingProjectsItem";
import LottieView from "react-native-web-lottie";
import { Hoverable, Pressable } from "react-native-web-hover";
import { onAuthStateChanged } from "firebase/auth";
import { createCheckoutSession } from "../Stripe/createCheckoutSession";
import { ENV_POST_SUBSCR, ENV_POST_CANCEL_SUBSCR, ENV_ST_MO } from "@env";
import { LinearGradient } from "expo-linear-gradient";
import { deviceType } from "react-device-detect";
import { Feather, Ionicons } from "@expo/vector-icons";
import {
  getDocs,
  setDoc,
  serverTimestamp,
  collection,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { auth, db } from "../References/firebase.js";
import { AuthContext } from "../References/Auth";
import { getStorage, ref, listAll, deleteObject } from "firebase/storage";

export default function Header(props) {
  const { signInCheck, width, height } = useContext(AuthContext);
  const [showingCheckout, setShowingCheckout] = useState(false);
  const [premiumStatus, setPremiumStatus] = useState(false);
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [browserOrCloudSelection, setBrowserOrCloudSelection] = useState(false);
  const [projectNames, setProjectNames] = useState([]);
  const [createNewProject, setCreateNewProject] = useState(false);
  const [existingProjects, setExistingProjects] = useState([]);
  const [deleteProject, setDeleteProject] = useState(false);
  const [projectForDeletion, setProjectForDeletion] = useState("");
  const [toggleDeletion, setToggleDeletion] = useState(false);
  const [showDupProjectError, setShowDupProjectError] = useState(false);
  const [existingProjectNames, setExistingProjectNames] = useState([]);
  const [projectName, setProjectName] = useState();

  // Get a reference to the cloud & define user
  const storage = getStorage();
  const user = auth.currentUser;

  // delete from cloud
  const deleteFromCloud = async () => {
    const userId = auth.currentUser?.uid;
    await deleteDoc(doc(db, `users/${userId}/projects/${projectForDeletion}`));
    const folderRef = ref(
      storage,
      `users/${user.uid}/projects/${projectForDeletion}/`
    );
    // List all the files inside the folder
    listAll(folderRef)
      .then((result) => {
        // Delete each file inside the folder
        result.items.forEach((fileRef) => {
          deleteObject(fileRef)
            .then(() => {
              console.log("File deleted successfully!");
            })
            .catch((error) => {
              console.error("Error deleting file:", error);
            });
        });
      })
      .catch((error) => {
        console.error("Error listing files:", error);
      })
      .finally(() => {
        // Delete the folder itself
        deleteObject(folderRef)
          .then(() => {
            console.log("Folder deleted successfully!");
          })
          .catch((error) => {
            console.error("Error deleting folder:", error);
          });
      });
  };
  // pressed delete project
  const pressedDelete = (projectName) => {
    setDeleteProject(true);
    setProjectForDeletion(projectName);
  };

  // make sure there can't duplicate be duplicate porject names
  useEffect(() => {
    existingProjectNames.includes(projectName) && setShowDupProjectError(true);
    !existingProjectNames.includes(projectName) &&
      setShowDupProjectError(false);
  }, [projectName]);

  // get the data for all the existing projects
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const userId = auth.currentUser?.uid;
        const projectsRef = collection(
          db,
          "users",
          auth.currentUser?.uid,
          "projects"
        );
        const querySnapshot = await getDocs(projectsRef);

        const projectsData = [];
        querySnapshot.forEach((doc) => {
          projectsData.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setExistingProjects(projectsData);
        const existingProjectNamesOnly = [];

        for (var i = 0; i < projectsData?.length; i++) {
          existingProjectNamesOnly.push(projectsData[i].projectName);
        }
        setExistingProjectNames(existingProjectNamesOnly);
      } catch (e) {
        console.log(e);
      }
    };
    signInCheck == "signed_in" && fetchProjects();
  }, [signInCheck, createNewProject, browserOrCloudSelection, toggleDeletion]);

  const addProject = (projectName) => {
    if (projectName == null) return;
    setProjectNames([...projectNames, projectName]);
  };

  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }
  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  const animation = useRef(null);

  function closeProfile() {
    setShowProfileInfo(false);
  }

  const deleteProjectName = (deleteIndex) => {
    setProjectNames(
      projectNames.filter((value, index) => index != deleteIndex)
    );
  };

  const ProjectNameInputField = (props) => {
    const handleAddProject = (value) => {
      props.addProject(value);
      setProjectName(null);
    };
    function handleKeyPress(projectName) {
      !projectNames.includes(projectName) && handleAddProject(projectName);
    }

    return (
      <View
        style={{
          flexDirection: "row",
        }}
      >
        <View
          style={{
            borderColor: colors.greyBorder,
            backgroundColor: "white",
            borderWidth: 1,
            width: 360,
            borderRadius: 5,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Ionicons
            name="create-outline"
            size={24}
            color={colors.greyDark}
            style={{ paddingHorizontal: 20, marginRight: -20 }}
          />
          <TextInput
            style={{
              color: "black",
              height: 50,
              flex: 1,
              marginLeft: -44,
              paddingLeft: 66,
              paddingHorizontal: 20,
            }}
            value={projectName}
            onChangeText={(text) => setProjectName(text)}
            placeholder={"Input Project Name Here..."}
            placeholderTextColor={"grey"}
            maxLength={15}
            multiline={false}
            autoFocus
            onKeyPress={(e) =>
              e.key == "Enter" &&
              !showDupProjectError &&
              projectName != null &&
              (handleKeyPress(projectName), setCreateNewProject(true))
            }
          />
        </View>
        <View style={{ width: 10 }} />
        <Pressable
          style={({ hovered }) => [
            {
              height: 50,
              width: 50,
              borderWidth: 1,
              borderColor: colors.greyBorder,
              borderRadius: 5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: hovered ? colors.greyBorder : colors.greySidebar,
            },
          ]}
          onPress={() =>
            !showDupProjectError &&
            projectName != null &&
            (handleKeyPress(projectName), setCreateNewProject(true))
          }
        >
          <Text
            style={{
              color: "black",
              fontSize: 20,
              fontFamily: "Inter_600SemiBold",
            }}
          >
            +
          </Text>
        </Pressable>
      </View>
    );
  };

  //This renders the existing projects
  const renderExistingProjects = ({ item, index }) => {
    return (
      <ExistingProjectsItem
        item={item}
        index={index}
        goTo={props.goTo}
        openProjectsWindow={setBrowserOrCloudSelection}
        pressedDelete={pressedDelete}
      />
    );
  };

  if (width < 300) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 250,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 105,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35 }}
              />
            </TouchableOpacity>
          </View>

          <View //Right side of bar
            style={{
              width: 100,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {!showingCheckout && (
              <Menu style={{ borderRadius: 20 }}>
                <MenuTrigger
                  style={{
                    height: 35,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Ionicons
                    name="ellipsis-vertical-outline"
                    size={25}
                    color={colors.greyDark}
                  />
                </MenuTrigger>
                <MenuOptions
                  customStyles={{
                    optionsContainer: {
                      backgroundColor: "rgba(255,255,255,.95)",
                      borderRadius: 10,
                      shadowColor: "black",
                      marginRight: 10,
                      shadowOpacity: 0.1,
                      shadowRadius: 25,
                      borderRadius: 10,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    },
                    optionText: {
                      color: colors.greyDark,
                      fontFamily: "Inter_500Medium",
                    },
                  }}
                >
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus == "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      text="Upgrade"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("SignUp")}
                      text="Sign up"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("LogIn")}
                      text="Log in"
                    />
                  )}
                  {signInCheck == "signed_out" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("Demo")}
                      text="Demo"
                    />
                  )}
                  {signInCheck == "signed_in" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        padding: 10,
                        zIndex: 2,
                        borderBottomWidth: 1,
                        borderColor: colors.greyBorder,
                      }}
                      onSelect={() => props.goTo("Editor")}
                      text="Editor"
                    />
                  )}
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("About")}
                    text="About"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Pricing")}
                    text="Pricing"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Privacy")}
                    text="Privacy"
                  />
                  <MenuOption
                    style={{ padding: 10, zIndex: 2 }}
                    onSelect={() => props.goTo("TermsConditions")}
                    text="Terms & Conditions"
                  />
                </MenuOptions>
              </Menu>
            )}
            {showingCheckout && (
              <ActivityIndicator size={"small"} color={colors.red} />
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 345) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 270,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 105,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35 }}
              />
            </TouchableOpacity>
          </View>

          <View //Right side of bar
            style={{
              width: 100,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {!showingCheckout && (
              <Menu style={{ borderRadius: 20 }}>
                <MenuTrigger
                  style={{
                    height: 35,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Ionicons
                    name="ellipsis-vertical-outline"
                    size={25}
                    color={colors.greyDark}
                  />
                </MenuTrigger>
                <MenuOptions
                  customStyles={{
                    optionsContainer: {
                      backgroundColor: "rgba(255,255,255,.95)",
                      borderRadius: 10,
                      shadowColor: "black",
                      marginRight: 10,
                      shadowOpacity: 0.1,
                      shadowRadius: 25,
                      borderRadius: 10,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    },
                    optionText: {
                      color: colors.greyDark,
                      fontFamily: "Inter_500Medium",
                    },
                  }}
                >
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus == "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      text="Upgrade"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("SignUp")}
                      text="Sign up"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("LogIn")}
                      text="Log in"
                    />
                  )}
                  {signInCheck == "signed_out" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("Demo")}
                      text="Demo"
                    />
                  )}
                  {signInCheck == "signed_in" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        padding: 10,
                        zIndex: 2,
                        borderBottomWidth: 1,
                        borderColor: colors.greyBorder,
                      }}
                      onSelect={() => props.goTo("Editor")}
                      text="Editor"
                    />
                  )}
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("About")}
                    text="About"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Pricing")}
                    text="Pricing"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Privacy")}
                    text="Privacy"
                  />
                  <MenuOption
                    style={{ padding: 10, zIndex: 2 }}
                    onSelect={() => props.goTo("TermsConditions")}
                    text="Terms & Conditions"
                  />
                </MenuOptions>
              </Menu>
            )}
            {showingCheckout && (
              <ActivityIndicator size={"small"} color={colors.red} />
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 360) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 300,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 105,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35 }}
              />
            </TouchableOpacity>
          </View>

          <View //Right side of bar
            style={{
              width: 100,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {!showingCheckout && (
              <Menu style={{ borderRadius: 20 }}>
                <MenuTrigger
                  style={{
                    height: 35,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Ionicons
                    name="ellipsis-vertical-outline"
                    size={25}
                    color={colors.greyDark}
                  />
                </MenuTrigger>
                <MenuOptions
                  customStyles={{
                    optionsContainer: {
                      backgroundColor: "rgba(255,255,255,.95)",
                      borderRadius: 10,
                      shadowColor: "black",
                      marginRight: 10,
                      shadowOpacity: 0.1,
                      shadowRadius: 25,
                      borderRadius: 10,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    },
                    optionText: {
                      color: colors.greyDark,
                      fontFamily: "Inter_500Medium",
                    },
                  }}
                >
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus == "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      text="Upgrade"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("SignUp")}
                      text="Sign up"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("LogIn")}
                      text="Log in"
                    />
                  )}
                  {signInCheck == "signed_out" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("Demo")}
                      text="Demo"
                    />
                  )}
                  {signInCheck == "signed_in" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        padding: 10,
                        zIndex: 2,
                        borderBottomWidth: 1,
                        borderColor: colors.greyBorder,
                      }}
                      onSelect={() => props.goTo("Editor")}
                      text="Editor"
                    />
                  )}
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("About")}
                    text="About"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Pricing")}
                    text="Pricing"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Privacy")}
                    text="Privacy"
                  />
                  <MenuOption
                    style={{ padding: 10, zIndex: 2 }}
                    onSelect={() => props.goTo("TermsConditions")}
                    text="Terms & Conditions"
                  />
                </MenuOptions>
              </Menu>
            )}
            {showingCheckout && (
              <ActivityIndicator size={"small"} color={colors.red} />
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 435) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 340,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 105,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35 }}
              />
            </TouchableOpacity>
          </View>

          <View //Right side of bar
            style={{
              width: 100,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {!showingCheckout && (
              <Menu style={{ borderRadius: 20 }}>
                <MenuTrigger
                  style={{
                    height: 35,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Ionicons
                    name="ellipsis-vertical-outline"
                    size={25}
                    color={colors.greyDark}
                  />
                </MenuTrigger>
                <MenuOptions
                  customStyles={{
                    optionsContainer: {
                      backgroundColor: "rgba(255,255,255,.95)",
                      borderRadius: 10,
                      shadowColor: "black",
                      marginRight: 10,
                      shadowOpacity: 0.1,
                      shadowRadius: 25,
                      borderRadius: 10,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    },
                    optionText: {
                      color: colors.greyDark,
                      fontFamily: "Inter_500Medium",
                    },
                  }}
                >
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus == "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      text="Upgrade"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("SignUp")}
                      text="Sign up"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("LogIn")}
                      text="Log in"
                    />
                  )}
                  {signInCheck == "signed_out" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("Demo")}
                      text="Demo"
                    />
                  )}
                  {signInCheck == "signed_in" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        padding: 10,
                        zIndex: 2,
                        borderBottomWidth: 1,
                        borderColor: colors.greyBorder,
                      }}
                      onSelect={() => props.goTo("Editor")}
                      text="Editor"
                    />
                  )}
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("About")}
                    text="About"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Pricing")}
                    text="Pricing"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Privacy")}
                    text="Privacy"
                  />
                  <MenuOption
                    style={{ padding: 10, zIndex: 2 }}
                    onSelect={() => props.goTo("TermsConditions")}
                    text="Terms & Conditions"
                  />
                </MenuOptions>
              </Menu>
            )}
            {showingCheckout && (
              <ActivityIndicator size={"small"} color={colors.red} />
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 550) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 400,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 105,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35 }}
              />
            </TouchableOpacity>
          </View>

          <View //Right side of bar
            style={{
              width: 100,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {!showingCheckout && (
              <Menu style={{ borderRadius: 20 }}>
                <MenuTrigger
                  style={{
                    height: 35,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Ionicons
                    name="ellipsis-vertical-outline"
                    size={25}
                    color={colors.greyDark}
                  />
                </MenuTrigger>
                <MenuOptions
                  customStyles={{
                    optionsContainer: {
                      backgroundColor: "rgba(255,255,255,.95)",
                      borderRadius: 10,
                      shadowColor: "black",
                      marginRight: 10,
                      shadowOpacity: 0.1,
                      shadowRadius: 25,
                      borderRadius: 10,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    },
                    optionText: {
                      color: colors.greyDark,
                      fontFamily: "Inter_500Medium",
                    },
                  }}
                >
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus == "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      text="Upgrade"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("SignUp")}
                      text="Sign up"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("LogIn")}
                      text="Log in"
                    />
                  )}
                  {signInCheck == "signed_out" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("Demo")}
                      text="Demo"
                    />
                  )}
                  {signInCheck == "signed_in" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        padding: 10,
                        zIndex: 2,
                        borderBottomWidth: 1,
                        borderColor: colors.greyBorder,
                      }}
                      onSelect={() => props.goTo("Editor")}
                      text="Editor"
                    />
                  )}
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("About")}
                    text="About"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Pricing")}
                    text="Pricing"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Privacy")}
                    text="Privacy"
                  />
                  <MenuOption
                    style={{ padding: 10, zIndex: 2 }}
                    onSelect={() => props.goTo("TermsConditions")}
                    text="Terms & Conditions"
                  />
                </MenuOptions>
              </Menu>
            )}
            {showingCheckout && (
              <ActivityIndicator size={"small"} color={colors.red} />
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 650) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 490,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 105,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35 }}
              />
            </TouchableOpacity>
          </View>

          <View //Right side of bar
            style={{
              width: 100,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {!showingCheckout && (
              <Menu style={{ borderRadius: 20 }}>
                <MenuTrigger
                  style={{
                    height: 35,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Ionicons
                    name="ellipsis-vertical-outline"
                    size={25}
                    color={colors.greyDark}
                  />
                </MenuTrigger>
                <MenuOptions
                  customStyles={{
                    optionsContainer: {
                      backgroundColor: "rgba(255,255,255,.95)",
                      borderRadius: 10,
                      shadowColor: "black",
                      marginRight: 10,
                      shadowOpacity: 0.1,
                      shadowRadius: 25,
                      borderRadius: 10,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    },
                    optionText: {
                      color: colors.greyDark,
                      fontFamily: "Inter_500Medium",
                    },
                  }}
                >
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus == "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      text="Upgrade"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("SignUp")}
                      text="Sign up"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("LogIn")}
                      text="Log in"
                    />
                  )}
                  {signInCheck == "signed_out" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("Demo")}
                      text="Demo"
                    />
                  )}
                  {signInCheck == "signed_in" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        padding: 10,
                        zIndex: 2,
                        borderBottomWidth: 1,
                        borderColor: colors.greyBorder,
                      }}
                      onSelect={() => props.goTo("Editor")}
                      text="Editor"
                    />
                  )}
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("About")}
                    text="About"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Pricing")}
                    text="Pricing"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Privacy")}
                    text="Privacy"
                  />
                  <MenuOption
                    style={{ padding: 10, zIndex: 2 }}
                    onSelect={() => props.goTo("TermsConditions")}
                    text="Terms & Conditions"
                  />
                </MenuOptions>
              </Menu>
            )}
            {showingCheckout && (
              <ActivityIndicator size={"small"} color={colors.red} />
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 775) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 600,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 105,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35 }}
              />
            </TouchableOpacity>
          </View>

          <View //Right side of bar
            style={{
              width: 100,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {!showingCheckout && (
              <Menu style={{ borderRadius: 20 }}>
                <MenuTrigger
                  style={{
                    height: 35,
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <Ionicons
                    name="ellipsis-vertical-outline"
                    size={25}
                    color={colors.greyDark}
                  />
                </MenuTrigger>
                <MenuOptions
                  customStyles={{
                    optionsContainer: {
                      backgroundColor: "rgba(255,255,255,.95)",
                      borderRadius: 10,
                      shadowColor: "black",
                      marginRight: 10,
                      shadowOpacity: 0.1,
                      shadowRadius: 25,
                      borderRadius: 10,
                      shadowOffset: {
                        height: 7,
                        width: 1,
                      },
                    },
                    optionText: {
                      color: colors.greyDark,
                      fontFamily: "Inter_500Medium",
                    },
                  }}
                >
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus == "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={props.showProfile}
                      text="Profile"
                    />
                  )}
                  {signInCheck == "signed_in" && premiumStatus != "premium" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => {
                        setShowingCheckout(true),
                          createCheckoutSession(
                            auth.currentUser?.uid,
                            ENV_ST_MO,
                            ENV_POST_SUBSCR,
                            ENV_POST_CANCEL_SUBSCR
                          ),
                          setTimeout(() => {
                            setShowingCheckout(false);
                          }, 5000);
                      }}
                      text="Upgrade"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("SignUp")}
                      text="Sign up"
                    />
                  )}
                  {signInCheck == "signed_out" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("LogIn")}
                      text="Log in"
                    />
                  )}
                  {signInCheck == "signed_out" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        borderBottomWidth: 1,
                        zIndex: 2,
                        borderColor: colors.greyBorder,
                        padding: 10,
                      }}
                      onSelect={() => props.goTo("Demo")}
                      text="Demo"
                    />
                  )}
                  {signInCheck == "signed_in" && deviceType == "browser" && (
                    <MenuOption
                      style={{
                        padding: 10,
                        zIndex: 2,
                        borderBottomWidth: 1,
                        borderColor: colors.greyBorder,
                      }}
                      onSelect={() => props.goTo("Editor")}
                      text="Editor"
                    />
                  )}
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("About")}
                    text="About"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Pricing")}
                    text="Pricing"
                  />
                  <MenuOption
                    style={{
                      padding: 10,
                      zIndex: 2,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                    onSelect={() => props.goTo("Privacy")}
                    text="Privacy"
                  />
                  <MenuOption
                    style={{ padding: 10, zIndex: 2 }}
                    onSelect={() => props.goTo("TermsConditions")}
                    text="Terms & Conditions"
                  />
                </MenuOptions>
              </Menu>
            )}
            {showingCheckout && (
              <ActivityIndicator size={"small"} color={colors.red} />
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 940) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 750,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 370,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35, marginLeft: -10 }}
              />
            </TouchableOpacity>
            {signInCheck == "signed_out" && deviceType == "browser" && (
              <Pressable
                onPress={() => props.goTo("Demo")}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Demo
                </Text>
              </Pressable>
            )}
            {signInCheck == "signed_in" && deviceType == "browser" && (
              <Pressable
                onPress={() => setBrowserOrCloudSelection(true)}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Cloud Editor
                </Text>
              </Pressable>
            )}
            {signInCheck == "signed_in" && deviceType == "browser" && (
              <Pressable
                onPress={() => props.goTo("Editor")}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Editor
                </Text>
              </Pressable>
            )}
            <Pressable
              onPress={() => props.goTo("About")}
              style={({ hovered }) => [
                {
                  backgroundColor: hovered ? colors.greySidebar : "transparent",
                  borderRadius: 5,
                  padding: 10,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: "#505456",
                }}
              >
                About
              </Text>
            </Pressable>

            <Pressable
              onPress={() => props.goTo("Pricing")}
              style={({ hovered }) => [
                {
                  backgroundColor: hovered ? colors.greySidebar : "transparent",
                  borderRadius: 5,
                  padding: 10,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: "#505456",
                }}
              >
                Pricing
              </Text>
            </Pressable>
          </View>

          <View //Right side of bar
            style={{
              width: 300,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {signInCheck == "signed_out" && (
              <View
                style={{
                  flexDirection: "row",
                  width: 300,
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Hoverable>
                  {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => props.goTo("SignUp")}
                      style={{
                        width: 120,
                        height: 35,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        shadowColor: "black",
                      }}
                    >
                      <LinearGradient
                        style={{
                          height: "100%",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0.5, y: 0.5 }}
                        colors={[colors.red, colors.purpleLabelBorder]}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "white",
                            fontFamily: "Inter_700Bold",
                          }}
                        >
                          Sign Up
                        </Text>
                      </LinearGradient>
                    </TouchableOpacity>
                  )}
                </Hoverable>

                <View style={{ width: "5%", height: 50 }} />
                <Pressable
                  onPress={() => props.goTo("LogIn")}
                  style={({ hovered }) => [
                    {
                      width: 120,
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      height: 35,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#39394c",
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    Login
                  </Text>
                </Pressable>
              </View>
            )}
            {signInCheck == "signed_in" && premiumStatus != "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowProfileInfo(true)}
                    style={{
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      borderRadius: 5,

                      height: 35,
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginHorizontal: 10,
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#505456",
                        fontFamily: "Inter_600SemiBold",
                      }}
                    >
                      Profile (Free)
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            {signInCheck == "signed_in" && premiumStatus == "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowProfileInfo(true)}
                    style={{
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      borderRadius: 5,

                      height: 35,
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#505456",
                        fontFamily: "Inter_600SemiBold",
                      }}
                    >
                      Profile (Premium)
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            {signInCheck == "signed_in" && premiumStatus != "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={showingCheckout}
                    onPress={() => {
                      setShowingCheckout(true),
                        createCheckoutSession(
                          auth.currentUser?.uid,
                          ENV_ST_MO,
                          ENV_POST_SUBSCR,
                          ENV_POST_CANCEL_SUBSCR
                        ),
                        setTimeout(() => {
                          setShowingCheckout(false);
                        }, 5000);
                    }}
                    style={{
                      borderRadius: 5,
                      width: 130,
                      height: 35,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LinearGradient
                      style={{
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                      }}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 0.5, y: 0.5 }}
                      colors={[colors.red, colors.purpleLabelBorder]}
                    >
                      {!showingCheckout && (
                        <Text
                          style={{
                            fontSize: 16,
                            color: "white",
                            fontFamily: "Inter_700Bold",
                          }}
                        >
                          Upgrade
                        </Text>
                      )}
                      {showingCheckout && (
                        <LottieView
                          ref={animation}
                          style={{
                            width: 40,
                            height: 15,
                          }}
                          autoPlay={true}
                          loop={true}
                          source={require("../assets/loadingAnimation.json")}
                        />
                      )}
                    </LinearGradient>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
  if (width < 1170) {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 905,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 370,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35, marginLeft: -10 }}
              />
            </TouchableOpacity>
            {signInCheck == "signed_out" && deviceType == "browser" && (
              <Pressable
                onPress={() => props.goTo("Demo")}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Demo
                </Text>
              </Pressable>
            )}
            {signInCheck == "signed_in" && deviceType == "browser" && (
              <Pressable
                onPress={() => props.goTo("Editor_Cloud")}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Cloud Editor
                </Text>
              </Pressable>
            )}
            {signInCheck == "signed_in" && deviceType == "browser" && (
              <Pressable
                onPress={() => props.goTo("Editor")}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Editor
                </Text>
              </Pressable>
            )}
            <Pressable
              onPress={() => props.goTo("About")}
              style={({ hovered }) => [
                {
                  backgroundColor: hovered ? colors.greySidebar : "transparent",
                  borderRadius: 5,
                  padding: 10,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: "#505456",
                }}
              >
                About
              </Text>
            </Pressable>

            <Pressable
              onPress={() => props.goTo("Pricing")}
              style={({ hovered }) => [
                {
                  backgroundColor: hovered ? colors.greySidebar : "transparent",
                  borderRadius: 5,
                  padding: 10,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: "#505456",
                }}
              >
                Pricing
              </Text>
            </Pressable>
          </View>

          <View //Right side of bar
            style={{
              width: 300,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {signInCheck == "signed_out" && (
              <View
                style={{
                  flexDirection: "row",
                  width: 300,
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Hoverable>
                  {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => props.goTo("SignUp")}
                      style={{
                        width: 120,
                        height: 35,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        shadowColor: "black",
                      }}
                    >
                      <LinearGradient
                        style={{
                          height: "100%",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0.5, y: 0.5 }}
                        colors={[colors.red, colors.purpleLabelBorder]}
                      >
                        <Text
                          style={{
                            fontSize: 16,
                            color: "white",
                            fontFamily: "Inter_700Bold",
                          }}
                        >
                          Sign Up
                        </Text>
                      </LinearGradient>
                    </TouchableOpacity>
                  )}
                </Hoverable>

                <View style={{ width: "5%", height: 50 }} />
                <Pressable
                  onPress={() => props.goTo("LogIn")}
                  style={({ hovered }) => [
                    {
                      width: 120,
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      height: 35,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#39394c",
                      fontFamily: "Inter_600SemiBold",
                    }}
                  >
                    Login
                  </Text>
                </Pressable>
              </View>
            )}
            {signInCheck == "signed_in" && premiumStatus != "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowProfileInfo(true)}
                    style={{
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      borderRadius: 5,

                      height: 35,
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginHorizontal: 10,
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#505456",
                        fontFamily: "Inter_600SemiBold",
                      }}
                    >
                      Profile (Free)
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            {signInCheck == "signed_in" && premiumStatus == "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowProfileInfo(true)}
                    style={{
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      borderRadius: 5,

                      height: 35,
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#505456",
                        fontFamily: "Inter_600SemiBold",
                      }}
                    >
                      Profile (Premium)
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            {signInCheck == "signed_in" && premiumStatus != "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={showingCheckout}
                    onPress={() => {
                      setShowingCheckout(true),
                        createCheckoutSession(
                          auth.currentUser?.uid,
                          ENV_ST_MO,
                          ENV_POST_SUBSCR,
                          ENV_POST_CANCEL_SUBSCR
                        ),
                        setTimeout(() => {
                          setShowingCheckout(false);
                        }, 5000);
                    }}
                    style={{
                      borderRadius: 5,
                      width: 130,
                      height: 35,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LinearGradient
                      style={{
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                      }}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 0.5, y: 0.5 }}
                      colors={[colors.red, colors.purpleLabelBorder]}
                    >
                      {!showingCheckout && (
                        <Text
                          style={{
                            fontSize: 16,
                            color: "white",
                            fontFamily: "Inter_700Bold",
                          }}
                        >
                          Upgrade
                        </Text>
                      )}
                      {showingCheckout && (
                        <LottieView
                          ref={animation}
                          style={{
                            width: 40,
                            height: 15,
                          }}
                          autoPlay={true}
                          loop={true}
                          source={require("../assets/loadingAnimation.json")}
                        />
                      )}
                    </LinearGradient>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
          </View>
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  } else {
    return (
      //Header
      <View
        style={{
          zIndex: 100,
          height: 90,
          justifyContent: "center",
        }}
      >
        <Overlay //Editor (Browser or Cloud)
          isVisible={browserOrCloudSelection}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 700,
              width: 900,
              backgroundColor: colors.greySidebar,
              borderRadius: 10,
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
                height: 100,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: colors.greyDark,
                  fontSize: 20,
                  fontFamily: "Inter_700Bold",
                }}
              >
                Select/Create your project
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: 50,
                paddingVertical: 25,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  color: colors.greyDark,
                  fontSize: 18,
                  paddingTop: 20,
                  paddingBottom: 15,
                }}
              >
                Create a Project
              </Text>
              <ProjectNameInputField addProject={addProject} />
              {showDupProjectError && (
                <Text
                  style={{
                    paddingTop: 10,
                    fontFamily: "Inter_500Medium",
                    fontSize: 13,
                    color: colors.redError,
                  }}
                >
                  A project with this name already exists
                </Text>
              )}

              {existingProjects.length > 0 && (
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    color: colors.greyDark,
                    fontSize: 18,
                    paddingTop: 50,
                    paddingBottom: 15,
                  }}
                >
                  Existing Projects
                </Text>
              )}
              {existingProjects.length > 0 && (
                <View
                  style={{
                    width: "100%",
                    maxHeight: 250,
                    backgroundColor: "white",
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: colors.greyBorder,
                  }}
                >
                  <View
                    style={{
                      height: 40,
                      flexDirection: "row",
                      backgroundColor: colors.greySidebar,
                      alignItems: "center",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      padding: 15,
                      borderBottomWidth: 1,
                      borderColor: colors.greyBorder,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 11,
                        color: colors.greyDark,
                        width: "50%",
                      }}
                    >
                      Project Name
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 11,
                        color: colors.greyDark,
                        width: "15%",
                        textAlign: "center",
                      }}
                    >
                      # of Images
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 11,
                        color: colors.greyDark,
                        width: "15%",
                        textAlign: "center",
                      }}
                    >
                      Last Updated
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Inter_600SemiBold",
                        fontSize: 11,
                        color: colors.greyDark,
                        width: "20%",
                      }}
                    ></Text>
                  </View>
                  <FlatList
                    style={{ padding: 5 }}
                    data={existingProjects}
                    renderItem={renderExistingProjects}
                    scrollEnabled={true}
                    keyExtractor={(item) => item.id}
                    showsVerticalScrollIndicator={true}
                    extraData={[toggleDeletion]}
                  />
                </View>
              )}
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: 50,
                alignSelf: "center",
              }}
            >
              <Pressable
                onPress={() => setBrowserOrCloudSelection(false)}
                style={({ hovered }) => [
                  {
                    borderWidth: 1,
                    borderColor: colors.greyBorder,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: hovered
                      ? colors.greyBorder
                      : colors.greySidebar,
                    width: 200,
                    height: 50,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: colors.greyDark,
                  }}
                >
                  Cancel
                </Text>
              </Pressable>
            </View>
          </View>
        </Overlay>

        <Overlay //Create New Project
          isVisible={createNewProject}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "transparent",
            zIndex: 100,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 350,
              width: 550,
              backgroundColor: colors.greySidebar,
              borderRadius: 10,
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
                height: 100,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: colors.greyDark,
                  fontSize: 20,
                  fontFamily: "Inter_700Bold",
                }}
              >
                Confirmation
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: 50,
                paddingVertical: 25,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  color: colors.greyDark,
                  fontSize: 18,
                  paddingTop: 20,
                  paddingBottom: 15,
                  alignSelf: "center",
                }}
              >
                Create a new project named {projectNames[0]}?
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: 50,
                alignSelf: "center",
              }}
            >
              <Pressable
                onPress={() => (
                  setCreateNewProject(false), setProjectNames([])
                )}
                style={({ hovered }) => [
                  {
                    borderWidth: 1,
                    borderColor: colors.greyBorder,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: hovered
                      ? colors.greyBorder
                      : colors.greySidebar,
                    width: 200,
                    height: 50,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: colors.greyDark,
                  }}
                >
                  Back
                </Text>
              </Pressable>
              <View style={{ width: "5%" }} />
              <Pressable
                onPress={() => (
                  setBrowserOrCloudSelection(false),
                  setCreateNewProject(false),
                  props.goTo("Editor_Cloud", {
                    projectName: projectNames[0],
                    uploadImagesAndLabels: true,
                  }),
                  setProjectNames([])
                )}
                style={({ hovered }) => [
                  {
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 200,
                    height: 50,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Continue
                </Text>
              </Pressable>
            </View>
          </View>
        </Overlay>

        <Overlay //Delete Project
          isVisible={deleteProject}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "transparent",
            zIndex: 100,
            padding: "5%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 350,
              width: 550,
              backgroundColor: colors.greySidebar,
              borderRadius: 10,
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                borderBottomWidth: 1,
                borderColor: colors.greyBorder,
                height: 100,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                width: "100%",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: colors.greyDark,
                  fontSize: 20,
                  fontFamily: "Inter_700Bold",
                }}
              >
                Delete
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                paddingHorizontal: 50,
                paddingVertical: 25,
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  color: colors.greyDark,
                  fontSize: 18,
                  paddingTop: 20,
                  paddingBottom: 15,
                  alignSelf: "center",
                }}
              >
                Are you sure you want to delete {projectForDeletion}?
              </Text>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: 50,
                alignSelf: "center",
              }}
            >
              <Pressable
                onPress={() => setDeleteProject(false)}
                style={({ hovered }) => [
                  {
                    borderWidth: 1,
                    borderColor: colors.greyBorder,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: hovered
                      ? colors.greyBorder
                      : colors.greySidebar,
                    width: 200,
                    height: 50,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: colors.greyDark,
                  }}
                >
                  Back
                </Text>
              </Pressable>
              <View style={{ width: "5%" }} />
              <Pressable
                onPress={() => (
                  deleteFromCloud(),
                  setDeleteProject(false),
                  setToggleDeletion(!toggleDeletion)
                )}
                style={({ hovered }) => [
                  {
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 200,
                    height: 50,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  Continue
                </Text>
              </Pressable>
            </View>
          </View>
        </Overlay>

        <Overlay //Profile Info
          isVisible={signInCheck == "signed_in" ? showProfileInfo : false}
          fullScreen={true}
          overlayStyle={{
            backgroundColor: "rgba(0,0,0,.5)",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              height: 550,
              width: 1000,
              backgroundColor: "white",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            <TouchableOpacity
              onPress={() => setShowProfileInfo(false)}
              style={{
                zIndex: 100,
                position: "absolute",
                borderRadius: 20,
                height: 40,
                width: 40,
                backgroundColor: colors.greyDisabled,
                right: 15,
                top: 15,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontWeight: "Inter_800ExtraBold",
                  fontSize: 16,
                  color: colors.greyDark,
                }}
              >
                X
              </Text>
            </TouchableOpacity>
            <ProfileInfo closeProfile={closeProfile} />
          </View>
        </Overlay>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "space-between",
            width: 1150,
          }}
        >
          <View //Left side of bar
            style={{
              flexDirection: "row",
              height: "100%",
              width: 370,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TouchableOpacity
              activeOpacity={1}
              onPress={() => props.goTo("Home")}
              style={{
                justifyContent: "center",
                width: 105,
                height: 35,
              }}
            >
              <Image
                source={require("../assets/BoltLabel.png")}
                style={{ width: 105, height: 35, marginLeft: -10 }}
              />
            </TouchableOpacity>
            {signInCheck == "signed_out" && deviceType == "browser" && (
              <Pressable
                onPress={() => props.goTo("Demo")}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Demo
                </Text>
              </Pressable>
            )}
            {/*  {signInCheck == "signed_in" && deviceType == "browser" && (
              <Pressable
                onPress={() => setBrowserOrCloudSelection(true)}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Cloud Editor
                </Text>
              </Pressable>
            )} */}
            {/* {signInCheck == "signed_in" && deviceType == "browser" && (
              <Pressable
                onPress={() => props.goTo("Editor")}
                style={({ hovered }) => [
                  {
                    backgroundColor: hovered
                      ? colors.greySidebar
                      : "transparent",
                    borderRadius: 5,
                    padding: 10,
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_500Medium",
                    fontSize: 15,
                    color: "#505456",
                  }}
                >
                  Editor
                </Text>
              </Pressable>
            )} */}
            <Pressable
              onPress={() => props.goTo("About")}
              style={({ hovered }) => [
                {
                  backgroundColor: hovered ? colors.greySidebar : "transparent",
                  borderRadius: 5,
                  padding: 10,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: "#505456",
                }}
              >
                About
              </Text>
            </Pressable>

            {/* <Pressable
              onPress={() => props.goTo("Pricing")}
              style={({ hovered }) => [
                {
                  backgroundColor: hovered ? colors.greySidebar : "transparent",
                  borderRadius: 5,
                  padding: 10,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  fontSize: 15,
                  color: "#505456",
                }}
              >
                Pricing
              </Text>
            </Pressable> */}
          </View>

          {/*  <View //Right side of bar
            style={{
              width: 300,
              height: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              alignSelf: "right",
            }}
          >
            {signInCheck == "signed_out" && (
              <View
                style={{
                  flexDirection: "row",
                  width: 300,
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Hoverable>
                  {({ hovered }) => (
                    <TouchableOpacity
                      onPress={() => props.goTo("SignUp")}
                      style={{
                        width: 120,
                        height: 35,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                        shadowColor: "black",
                      }}
                    >
                      <LinearGradient
                        style={{
                          height: "100%",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 5,
                        }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0.5, y: 0.5 }}
                        colors={[colors.red, colors.red]} //colors.red, colors.purpleLabelBorder
                      >
                        <Text
                          style={{
                            fontSize: 15,
                            color: "white",
                            fontFamily: "Inter_500Medium",
                          }}
                        >
                          Sign Up
                        </Text>
                      </LinearGradient>
                    </TouchableOpacity>
                  )}
                </Hoverable>

                <View style={{ width: "3%", height: 50 }} />
                <Pressable
                  onPress={() => props.goTo("LogIn")}
                  style={({ hovered }) => [
                    {
                      width: 120,
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      height: 35,
                      borderRadius: 5,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      color: "#39394c",
                      fontFamily: "Inter_500Medium",
                    }}
                  >
                    Login
                  </Text>
                </Pressable>
              </View>
            )}
            {signInCheck == "signed_in" && premiumStatus != "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowProfileInfo(true)}
                    style={{
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      borderRadius: 5,

                      height: 35,
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginHorizontal: 10,
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#505456",
                        fontFamily: "Inter_600SemiBold",
                      }}
                    >
                      Profile (Free)
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            {signInCheck == "signed_in" && premiumStatus == "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    onPress={() => setShowProfileInfo(true)}
                    style={{
                      backgroundColor: hovered
                        ? colors.greySidebar
                        : "transparent",
                      borderRadius: 5,

                      height: 35,
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "center",
                      padding: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#505456",
                        fontFamily: "Inter_600SemiBold",
                      }}
                    >
                      Profile (Premium)
                    </Text>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
            {signInCheck == "signed_in" && premiumStatus != "premium" && (
              <Hoverable>
                {({ hovered }) => (
                  <TouchableOpacity
                    disabled={showingCheckout}
                    onPress={() => {
                      setShowingCheckout(true),
                        createCheckoutSession(
                          auth.currentUser?.uid,
                          ENV_ST_MO,
                          ENV_POST_SUBSCR,
                          ENV_POST_CANCEL_SUBSCR
                        ),
                        setTimeout(() => {
                          setShowingCheckout(false);
                        }, 5000);
                    }}
                    style={{
                      borderRadius: 5,
                      width: 130,
                      height: 35,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LinearGradient
                      style={{
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 5,
                      }}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 0.5, y: 0.5 }}
                      colors={[colors.red, colors.purpleLabelBorder]}
                    >
                      {!showingCheckout && (
                        <Text
                          style={{
                            fontSize: 16,
                            color: "white",
                            fontFamily: "Inter_700Bold",
                          }}
                        >
                          Upgrade
                        </Text>
                      )}
                      {showingCheckout && (
                        <LottieView
                          ref={animation}
                          style={{
                            width: 40,
                            height: 15,
                          }}
                          autoPlay={true}
                          loop={true}
                          source={require("../assets/loadingAnimation.json")}
                        />
                      )}
                    </LinearGradient>
                  </TouchableOpacity>
                )}
              </Hoverable>
            )}
          </View> */}
        </View>
        {deviceType != "browser" && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              top: 70,
              backgroundColor: "#e6f9ff",
              height: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Inter_500Medium",
                fontSize: 14,
                color: colors.greyDarkHover,
                textAlign: "center",
              }}
            >
              Editor access is not available on mobile/tablet devices
            </Text>
          </View>
        )}
      </View>
    );
  }
}
