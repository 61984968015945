import React, { useRef, useContext } from "react";
import { colors } from "../Styles/colors.js";
import { Text, View, Image } from "react-native";
import * as Animatable from "react-native-animatable";
import { AuthContext } from "../References/Auth";
import { LinearGradient } from "expo-linear-gradient";
import LottieView from "react-native-web-lottie";

export default function HomeContents() {
  const { width } = useContext(AuthContext);

  const animation = useRef(null);
  const contentWidth = 1037;
  return (
    <Animatable.View
      animation={"fadeInUp"}
      delay={400}
      style={{
        width: contentWidth,
        paddingBottom: 100,
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      <View //Layer 1
        style={{
          flexDirection: "row",
          width: "100%",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LinearGradient
          colors={[colors.red, colors.purpleLabelBorder]}
          start={{ x: 0, y: 0 }}
          end={{ x: 0.5, y: 0.5 }}
          style={{
            borderRadius: 25,
            height: 450,
            width: contentWidth * 0.6 - 15,
            shadowColor: "black",
            shadowOpacity: 0.0,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View style={{ padding: 40 }}>
            <Text
              style={{
                color: "white",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Images
            </Text>
            <Text
              style={{
                color: "white",
                paddingTop: 10,
                opacity: 0.6,
                width: "75%",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Get started in minutes by uploading your images directly into the
              browser.
            </Text>
          </View>
          <Image
            source={require("../assets/UploadedImages.png")}
            style={{
              width: "100%",
              height: "50%",
              zIndex: 2,
            }}
          />
        </LinearGradient>

        <View style={{ width: 30 }}></View>

        <View
          style={{
            borderRadius: 25,
            height: 450,
            width: contentWidth * 0.4 - 15,
            backgroundColor: "white",
            shadowColor: "black",
            shadowOpacity: 0.0,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View style={{ padding: 40 }}>
            <Text
              style={{
                color: "black",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Labels
            </Text>
            <Text
              style={{
                color: "black",
                paddingTop: 10,
                opacity: 0.6,
                width: "85%",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Easily create labels for each of your categories.
            </Text>
          </View>
          <View style={{ height: 30 }} />
          <View
            style={{
              marginTop: -50,
              justifyContent: "center",
              height: 450 - 25 - 25 - 10 - 40 - 40,
            }}
          >
            <View
              style={{
                height: 50,
                width: "80%",
                backgroundColor: colors.greyCancel,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
            <View style={{ height: 5 }} />
            <View
              style={{
                height: 50,
                width: "70%",
                backgroundColor: colors.greyCancel,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
            <View style={{ height: 5 }} />
            <View
              style={{
                height: 50,
                width: "60%",
                backgroundColor: colors.greyCancel,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
            <View style={{ height: 5 }} />
            <View
              style={{
                height: 50,
                width: "50%",
                backgroundColor: colors.greyCancel,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
          </View>
        </View>
      </View>

      <View style={{ height: 30 }} />

      <View //Layer 2
        style={{
          flexDirection: "row",
          width: "100%",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0.5, y: 0.5 }}
          colors={[colors.orangeLabelBorder, colors.red]}
          style={{
            borderRadius: 25,
            height: 450,
            width: contentWidth * 0.4 - 15,
            backgroundColor: colors.greyCancel,
            shadowColor: "black",
            shadowOpacity: 0.0,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View style={{ padding: 40 }}>
            <Text
              style={{
                color: "white",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Inputs
            </Text>
            <Text
              style={{
                color: "white",
                paddingTop: 10,
                opacity: 0.6,
                width: "90%",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Tag a few images for the ML to learn your dataset.
            </Text>
            <View
              style={{
                justifyContent: "center",
                height: 450 - 25 - 25 - 10 - 40 - 40,
              }}
            >
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <View style={{ width: "40%", alignItems: "center" }}>
                  <View
                    style={{
                      height: 70,
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: 25,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 30,
                        fontFamily: "Inter_800ExtraBold",
                        color: "#f55735",
                      }}
                    >
                      Rose
                    </Text>
                  </View>
                  <Text style={{ fontSize: 90, marginTop: -30, zIndex: 2 }}>
                    👆
                  </Text>
                </View>
                <View style={{ width: 30 }} />
                <View style={{ width: "40%", alignItems: "center" }}>
                  <View
                    style={{
                      height: 70,
                      width: "100%",
                      backgroundColor: "rgba(255,255,255,.3)",
                      borderRadius: 25,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 30,
                        fontFamily: "Inter_800ExtraBold",
                        color: "white",
                      }}
                    >
                      Orchid
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </LinearGradient>
        <View style={{ width: 30 }}></View>
        <View
          style={{
            borderRadius: 25,
            height: 450,
            width: contentWidth * 0.6 - 15,
            backgroundColor: colors.greySidebar,
            shadowColor: "black",
            shadowOpacity: 0.0,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View style={{ padding: 40 }}>
            <Text
              style={{
                color: "black",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Quick & Simple
            </Text>
            <Text
              style={{
                color: "black",
                paddingTop: 10,
                opacity: 0.6,
                width: "85%",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Built for all users. No coding required.
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <Image
              source={require("../assets/NoCode.png")}
              style={{
                width: 200,
                height: 200,
                marginLeft: -20,
              }}
            />
          </View>
        </View>
      </View>

      <View style={{ height: 30 }} />

      <View //Layer 3
        style={{
          flexDirection: "row",
          width: "100%",
          alignSelf: "center",
        }}
      >
        <View
          style={{
            borderRadius: 25,
            height: 450,
            width: contentWidth * 0.55 - 15,
            backgroundColor: "white",
            shadowColor: "black",
            shadowOpacity: 0.0,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View style={{ padding: 40 }}>
            <Text
              style={{
                color: "black",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Organize
            </Text>
            <Text
              style={{
                color: "black",
                paddingTop: 10,
                opacity: 0.6,
                width: "85%",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Export your data & model. Re-upload in future sessions to continue
              progress.
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <Image
              source={require("../assets/Files.png")}
              style={{
                width: 150,
                height: 150,
                marginLeft: -20,
              }}
            />
          </View>
        </View>
        <View style={{ width: 30 }}></View>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0.5, y: 0.5 }}
          colors={[colors.purpleLabelBorder, colors.blueLabelBorder]}
          style={{
            borderRadius: 25,
            height: 450,
            width: contentWidth * 0.45 - 15,
            shadowColor: "black",
            shadowOpacity: 0.0,
            shadowRadius: 25,
            overflow: "hidden",
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View style={{ padding: 40 }}>
            <Text
              style={{
                color: "white",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Automate
            </Text>
            <Text
              style={{
                color: "white",
                paddingTop: 10,
                opacity: 0.6,
                width: "90%",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Label hundreds of images with only a few clicks.
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              borderRadius: 25,
              overflow: "hidden",
              height: "65%",
            }}
          >
            <Image
              source={require("../assets/PowerLabel.png")}
              style={{
                width: "100%",
                borderBottomLeftRadius: 25,
                borderBottomRightRadius: 25,
                height: "100%",
                marginTop: 47,
              }}
            />
          </View>
        </LinearGradient>
      </View>

      <View style={{ height: 30 }} />

      <View //Layer 4
        style={{
          flexDirection: "row",
          width: "100%",
          alignSelf: "center",
        }}
      >
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0.5, y: 0.5 }}
          colors={["#20252C", "#424955"]}
          style={{
            borderRadius: 25,
            height: 450,
            width: "100%",
            backgroundColor: colors.greyCancel,
            shadowColor: "black",
            overflow: "hidden",
            shadowOpacity: 0.0,
            shadowRadius: 25,
            shadowOffset: {
              height: 7,
              width: 1,
            },
          }}
        >
          <View style={{ padding: 40 }}>
            <Text
              style={{
                color: "white",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              No Installation Required
            </Text>
            <Text
              style={{
                color: "white",
                paddingTop: 10,
                opacity: 0.6,
                width: "50%",
                fontSize: 25,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Privately manage all your labeled data directly in a browser-based
              editor.
            </Text>
          </View>
          <View
            style={{
              width: "85%",
              alignSelf: "center",
              height: "35%",
              position: "absolute",
              bottom: 0,
            }}
          >
            <Image
              source={require("../assets/BrowserImage.png")}
              style={{
                width: "109%",
                height: "100%",
                zIndex: 2,
              }}
            />
          </View>
        </LinearGradient>
      </View>
    </Animatable.View>
  );
}
