import React, { useState, useEffect, useRef, useContext } from "react";
import { colors } from "../Styles/colors.js";
import { Hoverable, Pressable } from "react-native-web-hover";
import {
  ScrollView,
  Text,
  View,
  Image,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
} from "react-native";

import { LinearGradient } from "expo-linear-gradient";
import { createCheckoutSession } from "../Stripe/createCheckoutSession";
import LottieView from "react-native-web-lottie";
import { auth } from "../References/firebase";
import { AuthContext } from "../References/Auth";
import { deviceType } from "react-device-detect";
import { ENV_ST_MO, ENV_POST_SUBSCR, ENV_POST_CANCEL_SUBSCR } from "@env";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButton";
import EmailSignup from "./EmailSignup";
import { Overlay } from "react-native-elements";

export default function Anchor(props) {
  const { signInCheck, width, height } = useContext(AuthContext);
  const [showingCheckout, setShowingCheckout] = useState(false);
  const [premiumStatus, setPremiumStatus] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }
  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  const animation = useRef(null);

  return (
    <View //anchor
      style={{
        width: width,
        height: 400,
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 3,
      }}
    >
      <Overlay //Give email
        isVisible={showSignUp}
        fullScreen={true}
        overlayStyle={{
          backgroundColor: "rgba(0,0,0,.5)",
          zIndex: 100,
          padding: "5%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EmailSignup setShowSignUp={setShowSignUp} />
      </Overlay>
      {premiumStatus != "premium" && (
        <Text
          style={{
            fontFamily: "Inter_600SemiBold",
            letterSpacing: -2,
            fontSize: 50,
            color: "black",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          Get Started
        </Text>
      )}
      {premiumStatus == "premium" && (
        <Text
          style={{
            fontFamily: "Inter_600SemiBold",
            letterSpacing: -2,
            fontSize: 50,
            color: "black",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          Start Labeling
        </Text>
      )}
      {premiumStatus != "premium" && (
        <Text
          style={{
            fontFamily: "Inter_400Regular",
            fontSize: 20,
            color: "black",
            width: 300,
            paddingVertical: 20,
            zIndex: 2,
            textAlign: "center",
          }}
        >
          Sign up for email updates or try the online demo.
        </Text>
      )}
      {premiumStatus == "premium" && (
        <Text
          style={{
            fontFamily: "Inter_400Regular",
            fontSize: 20,
            color: "black",
            width: 300,
            paddingVertical: 20,
            zIndex: 2,
            textAlign: "center",
            lineHeight: 40,
          }}
        >
          Go to the editor or try the online demo.
        </Text>
      )}
      <View
        style={{
          width: 310,
          height: 50,
          paddingTop: 20,
          zIndex: 2,
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {signInCheck == "signed_out" && (
          <MainButton
            disabled={false}
            onPress={() => setShowSignUp(true)}
            text={"Stay Updated"}
            showLoading={false}
          />
        )}
        <View style={{ width: 10 }} />
        {signInCheck == "signed_in" &&
          premiumStatus == "premium" &&
          deviceType == "browser" && (
            <Hoverable>
              {({ hovered }) => (
                <TouchableOpacity
                  onPress={() => props.goTo("Editor")}
                  style={{
                    width: 225,
                    height: 60,
                    borderRadius: 10,
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: "hidden",
                    shadowColor: "black",
                    shadowOpacity: hovered ? 0.3 : 0.1,
                    shadowRadius: 25,
                    shadowOffset: {
                      height: 7,
                      width: 1,
                    },
                  }}
                >
                  <LinearGradient
                    style={{
                      height: "100%",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 0.5, y: 0.5 }}
                    colors={[colors.red, colors.purpleLabelBorder]}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        color: "white",
                        fontFamily: "Inter_700Bold",
                      }}
                    >
                      Editor
                    </Text>
                  </LinearGradient>
                </TouchableOpacity>
              )}
            </Hoverable>
          )}
        {signInCheck == "signed_in" && premiumStatus != "premium" && (
          <Hoverable>
            {({ hovered }) => (
              <TouchableOpacity
                disabled={showingCheckout}
                onPress={() => {
                  setShowingCheckout(true),
                    createCheckoutSession(
                      auth.currentUser?.uid,
                      ENV_ST_MO,
                      ENV_POST_SUBSCR,
                      ENV_POST_CANCEL_SUBSCR
                    ),
                    setTimeout(() => {
                      setShowingCheckout(false);
                    }, 5000);
                }}
                style={{
                  width: 225,
                  height: 60,
                  borderRadius: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  shadowColor: "black",
                  shadowOpacity: hovered ? 0.3 : 0.1,
                  shadowRadius: 25,
                  shadowOffset: {
                    height: 7,
                    width: 1,
                  },
                }}
              >
                <LinearGradient
                  style={{
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 0.5, y: 0.5 }}
                  colors={[colors.red, colors.purpleLabelBorder]}
                >
                  {!showingCheckout && (
                    <Text
                      style={{
                        fontSize: 20,
                        color: "white",
                        fontFamily: "Inter_700Bold",
                      }}
                    >
                      Upgrade
                    </Text>
                  )}
                  {showingCheckout && (
                    <LottieView
                      ref={animation}
                      style={{
                        width: 40,
                        height: 15,
                      }}
                      autoPlay={true}
                      loop={true}
                      source={require("../assets/loadingAnimation.json")}
                    />
                  )}
                </LinearGradient>
              </TouchableOpacity>
            )}
          </Hoverable>
        )}
        {deviceType == "browser" && (
          <SecondaryButton
            disabled={false}
            onPress={() => props.goTo("Demo")}
            text={"Online Demo"}
            showLoading={false}
          />
        )}
      </View>
    </View>
  );
}
