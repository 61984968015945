import React, { useState, useContext, useEffect, useRef } from "react";
import {
  TextInput,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { Hoverable, Pressable } from "react-native-web-hover";
import {
  getAuth,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import LottieView from "react-native-web-lottie";

import { colors } from "../Styles/colors.js";
import { AuthContext } from "../References/Auth.js";
import * as WebBrowser from "expo-web-browser";

import { createCheckoutSession } from "../Stripe/createCheckoutSession";
import { collection, getDocs } from "firebase/firestore";
import { auth, db } from "../References/firebase";
import moment from "moment";
import {
  ENV_CHECKOUT,
  ENV_ST_MO,
  ENV_POST_SUBSCR,
  ENV_POST_CANCEL_SUBSCR,
} from "@env";

export default function ProfileInfo(props) {
  const [profilePressed, setProfilePressed] = useState(false);
  const [profileInfoPressed, setProfileInfoPressed] = useState(true);
  const [supportPressed, setSupportPressed] = useState(false);
  const [accountPressed, setAccountPressed] = useState(false);
  const [signOutPressed, setSignOutPressed] = useState(false);
  const [signingOut, setSigningOut] = useState(false);
  const [changeEmailPressed, setChangeEmailPressed] = useState(false);
  const [changePasswordPressed, setChangePasswordPressed] = useState(false);
  const [passwordText1, setPasswordText1] = useState("");
  const [passwordText2, setPasswordText2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [atleast6Characters, setAtleast6Characters] = useState(false);
  const [resendingVerification, setResendingVerification] = useState(false);
  const [startedNewPassword, setStartedNewPassword] = useState(false);
  const [startedReVerify, setStartedReVerify] = useState(false);
  const { signInCheck } = useContext(AuthContext);
  const [subscriptionStatus, setSubscriptionStatus] = useState(["-"]);
  const [periodEnd, setPeriodEnd] = useState(["-"]);
  const [checkCanceled, setCheckCanceled] = useState(["-"]);
  const [showingCheckout, setShowingCheckout] = useState(false);
  const [subscriptionLength, setSubscriptionLength] = useState(["-"]);
  const [premiumStatus, setPremiumStatus] = useState(false);

  async function checkIfPremium() {
    //function to check if premium
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }
  useEffect(() => {
    //check if premium
    //check premium status
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  useEffect(() => {
    passwordText1.length > 5
      ? setAtleast6Characters(true)
      : setAtleast6Characters(false);
    passwordText2.length > 0 && passwordText1 == passwordText2
      ? setPasswordsMatch(true)
      : setPasswordsMatch(false);
  }, [passwordText1]);

  useEffect(() => {
    passwordText1.length > 5
      ? setAtleast6Characters(true)
      : setAtleast6Characters(false);
    passwordText2.length > 0 && passwordText1 == passwordText2
      ? setPasswordsMatch(true)
      : setPasswordsMatch(false);
  }, [passwordText2]);

  //const width = dimensions.window.width;
  //const height = dimensions.window.height;

  const auth = getAuth();

  const cancelSubscription = async () => {
    WebBrowser.openBrowserAsync(ENV_CHECKOUT);
    /* await deleteDoc(doc(db, "users", auth.currentUser.uid));
    const ref = doc(db, "users", auth.currentUser.uid);
    const docRef = await setDoc(ref, {
      uid: auth.currentUser.uid,
      email: auth.currentUser.email,
      // NEVER FINISHED THIS subscription: auth.currentUser.
    }); */
    props.closeProfile();
  };
  const animation = useRef(null);

  const signOutUser = () => {
    setSigningOut(true);
    props.closeProfile();
    signOut(auth)
      .then(() => {
        setProfilePressed(false);
      })
      .catch((error) => {
        alert(error.code);
      });
  };
  useEffect(() => {
    async function getSubStatus() {
      const subRef = collection(
        db,
        "users",
        auth.currentUser?.uid,
        "subscriptions"
      );
      // const q = query(subRef);
      const querySnapshot = await getDocs(subRef);
      const subscriptionList = [];
      querySnapshot.forEach((doc) => {
        subscriptionList.push((doc.id, " => ", doc.data()));
      });
      var secs =
        subscriptionList.length > 0
          ? subscriptionList[subscriptionList.length - 1].current_period_end
          : "none";
      const cleanSecs =
        subscriptionList.length > 0
          ? JSON.stringify(secs)
              .slice(11)
              .slice(0, JSON.stringify(secs).slice(11).indexOf(","))
          : "none";
      const EndPeriod =
        subscriptionList.length > 0 ? new Date(cleanSecs * 1000) : "none";
      setSubscriptionStatus(
        subscriptionList.length > 0
          ? await subscriptionList[subscriptionList.length - 1].status
          : "none"
      );
      const isCanceled =
        subscriptionList.length > 0
          ? await subscriptionList[subscriptionList.length - 1]
              .cancel_at_period_end
          : "none";
      setCheckCanceled(
        subscriptionList.length > 0
          ? isCanceled == true
            ? "Canceled"
            : "Active"
          : "none"
      );

      setPeriodEnd(
        subscriptionList.length > 0 ? await JSON.stringify(EndPeriod) : "none"
      );
      {
        try {
          setSubscriptionLength(
            await subscriptionList[subscriptionList.length - 1].items[0]?.plan
              ?.interval
          );
        } catch {}
      }
    }
    subscriptionStatus != "none" &&
      signInCheck == "signed_in" &&
      getSubStatus();
  }, []);

  const setNewPassword = () => {
    setStartedNewPassword(true);
    updatePassword(auth.currentUser, passwordText1)
      .then(() => {
        alert("Password successfully updated");
        setPasswordText1("");
        setPasswordText2("");
        changeEmailPressed(false);
      })
      .catch((error) => {
        if (error.code == "auth/requires-recent-login") {
          alert(
            "For security reasons, please re-login or re-verify to update your password"
          );
        } else {
          alert(error.code);
        }
      });
    setStartedNewPassword(false);
  };

  const reVerify = () => {
    setStartedReVerify(true);
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      passwordText1
    );
    reauthenticateWithCredential(auth.currentUser, credential)
      .then(() => {
        setPasswordText1("");
        alert("Your account has been re-verified");
        setResendingVerification(false);
      })
      .catch((error) => {
        if (error.code == "auth/wrong-password") {
          alert("This email or password is incorrect");
        } else {
          alert(error.code);
        }
      });
    setStartedReVerify(false);
  };

  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: "white",
        height: 550,
        width: 1000,
        borderRadius: 5,
        padding: 20,
      }}
    >
      <View
        style={{
          borderRightWidth: 1,
          borderColor: "lightgrey",
          height: "100%",
          width: "20%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Hoverable //Profile Info
          style={{ width: "80%" }}
        >
          {({ hovered }) => (
            <TouchableOpacity
              onPress={() => (
                setProfileInfoPressed(true),
                setSupportPressed(false),
                setAccountPressed(false),
                setSignOutPressed(false)
              )}
              style={{
                borderLeftWidth: 4,
                borderColor: profileInfoPressed
                  ? colors.blueLabelBorder
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
                paddingLeft: 10,
                borderRadius: 5,
                justifyContent: "center",
                height: 50,
                backgroundColor: profileInfoPressed
                  ? "#f0f1f1"
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "#505456",
                }}
              >
                Profile info
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>

        <View style={{ height: 15 }} />

        <Hoverable //Account
          style={{ width: "80%" }}
        >
          {({ hovered }) => (
            <TouchableOpacity
              onPress={() => (
                setProfileInfoPressed(false),
                setSupportPressed(false),
                setAccountPressed(true),
                setSignOutPressed(false)
              )}
              style={{
                borderLeftWidth: 4,
                borderColor: accountPressed
                  ? colors.blueLabelBorder
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
                paddingLeft: 10,
                borderRadius: 5,
                justifyContent: "center",
                height: 50,
                backgroundColor: accountPressed
                  ? "#f0f1f1"
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "#505456",
                }}
              >
                Account
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>

        <View style={{ height: 15 }} />

        <Hoverable //Support
          style={{ width: "80%" }}
        >
          {({ hovered }) => (
            <TouchableOpacity
              onPress={() => (
                setProfileInfoPressed(false),
                setSupportPressed(true),
                setAccountPressed(false),
                setSignOutPressed(false)
              )}
              style={{
                borderLeftWidth: 4,
                borderColor: supportPressed
                  ? colors.blueLabelBorder
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
                paddingLeft: 10,
                borderRadius: 5,
                justifyContent: "center",
                height: 50,
                backgroundColor: supportPressed
                  ? "#f0f1f1"
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "#505456",
                }}
              >
                Support
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>

        <View style={{ height: 15 }} />

        <Hoverable //Sign Out
          style={{ width: "80%" }}
        >
          {({ hovered }) => (
            <TouchableOpacity
              onPress={() => (
                setProfileInfoPressed(false),
                setSupportPressed(false),
                setAccountPressed(false),
                setSignOutPressed(true)
              )}
              style={{
                borderLeftWidth: 4,
                borderColor: signOutPressed
                  ? colors.blueLabelBorder
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
                paddingLeft: 10,
                borderRadius: 5,
                justifyContent: "center",
                height: 50,
                backgroundColor: signOutPressed
                  ? "#f0f1f1"
                  : hovered
                  ? "#f0f1f1"
                  : "#fff",
              }}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "#505456",
                }}
              >
                Sign out
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>
      </View>

      {profileInfoPressed && (
        <View style={{ width: "80%", padding: "5%" }}>
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_700Bold",
              paddingTop: "5%",
            }}
          >
            Profile Info
          </Text>
          <Text
            style={{
              color: "#505456",
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Your account information
          </Text>
          <View
            style={{
              paddingTop: "10%",
              flexDirection: "row",
              width: "95%",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: "#000",
                fontSize: 16,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Email:
            </Text>
            <View style={{ width: 10 }} />
            <Text
              style={{
                color: "#505456",
                fontSize: 16,
                fontFamily: "Inter_500Medium",
              }}
            >
              {auth.currentUser?.email}
            </Text>
          </View>
          {!resendingVerification && (
            <View
              style={{
                paddingTop: "4%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {!changePasswordPressed && (
                <Text
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: "Inter_600SemiBold",
                  }}
                >
                  Password:
                </Text>
              )}
              {changePasswordPressed && (
                <Text
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: "Inter_600SemiBold",
                  }}
                >
                  New Password:
                </Text>
              )}

              <View style={{ width: 10 }} />
              {!changePasswordPressed && (
                <Text
                  style={{
                    color: "#505456",
                    fontSize: 16,
                    fontFamily: "Inter_500Medium",
                  }}
                >
                  *******
                </Text>
              )}
              {changePasswordPressed && (
                <TextInput
                  style={{
                    height: 30,
                    width: "50%",
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: "grey",
                    padding: 10,
                  }}
                  placeholder={"New Password"}
                  secureTextEntry
                  onChangeText={setPasswordText1}
                  value={passwordText1}
                />
              )}
              {!changeEmailPressed && !changePasswordPressed && (
                <TouchableOpacity
                  onPress={() => setChangePasswordPressed(true)}
                  style={{ position: "absolute", right: 0 }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      fontSize: 15,
                      color: "#1a75ff",
                    }}
                  >
                    Change Password
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {!changePasswordPressed && !changeEmailPressed && (
            <View
              style={{
                paddingTop: "4%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {!resendingVerification && (
                <Text
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: "Inter_600SemiBold",
                  }}
                >
                  Verification Status:
                </Text>
              )}
              {!resendingVerification && <View style={{ width: 10 }} />}
              {!resendingVerification && (
                <Text
                  style={{
                    color: "#505456",
                    fontSize: 16,
                    fontFamily: "Inter_500Medium",
                  }}
                >
                  {auth.currentUser?.emailVerified
                    ? "Verified"
                    : "Not Verified"}
                </Text>
              )}
              {resendingVerification && (
                <Text
                  style={{
                    color: "#000",
                    fontSize: 16,
                    fontFamily: "Inter_600SemiBold",
                  }}
                >
                  Password:
                </Text>
              )}
              {resendingVerification && (
                <TextInput
                  style={{
                    height: 30,
                    width: "50%",
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: "grey",
                    padding: 10,
                  }}
                  placeholder={"Password"}
                  secureTextEntry
                  onChangeText={setPasswordText1}
                  value={passwordText1}
                  onKeyPress={(e) =>
                    e.key == "Enter" &&
                    !startedReVerify &&
                    passwordText1.length > 0 &&
                    reVerify()
                  }
                />
              )}
              {!resendingVerification && (
                <TouchableOpacity
                  onPress={() => setResendingVerification(true)}
                  style={{ position: "absolute", right: 0 }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      fontSize: 15,
                      color: "#1a75ff",
                    }}
                  >
                    Re-verify
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {resendingVerification && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: 50,
                alignSelf: "center",
              }}
            >
              <Pressable
                onPress={() => setResendingVerification(false)}
                style={({ hovered }) => [
                  {
                    width: 175,
                    backgroundColor: hovered ? "#e6e6e6" : "#f0f1f1",
                    height: 50,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Cancel
                </Text>
              </Pressable>
              <View style={{ width: "5%" }} />
              <Pressable
                onPress={() => reVerify()}
                style={({ hovered }) => [
                  {
                    width: 175,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                    height: 50,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#fff",
                  }}
                >
                  Re-verify
                </Text>
              </Pressable>
            </View>
          )}
          {changePasswordPressed && (
            <View
              style={{
                paddingTop: "4%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Re-type New Password:
              </Text>
              <View style={{ width: 10 }} />
              {changePasswordPressed && (
                <TextInput
                  style={{
                    height: 30,
                    width: "50%",
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: "grey",
                    padding: 10,
                  }}
                  placeholder={"New Password"}
                  secureTextEntry
                  onChangeText={setPasswordText2}
                  value={passwordText2}
                  onKeyPress={(e) =>
                    e.key == "Enter" &&
                    passwordsMatch &&
                    atleast6Characters &&
                    !startedNewPassword &&
                    setNewPassword()
                  }
                />
              )}
            </View>
          )}

          {changeEmailPressed && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: 50,
                alignSelf: "center",
              }}
            >
              <Pressable
                onPress={() => setChangeEmailPressed(false)}
                style={({ hovered }) => [
                  {
                    width: 175,
                    backgroundColor: hovered ? "#e6e6e6" : "#f0f1f1",
                    height: 50,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Cancel
                </Text>
              </Pressable>
              <View style={{ width: "5%" }} />
              <Pressable
                style={({ hovered }) => [
                  {
                    width: 175,
                    backgroundColor: hovered ? colors.red : colors.red,
                    height: 50,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#fff",
                  }}
                >
                  Submit Email
                </Text>
              </Pressable>
            </View>
          )}
          {changePasswordPressed && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                position: "absolute",
                bottom: 50,
                alignSelf: "center",
              }}
            >
              <Pressable
                onPress={() => (
                  setChangePasswordPressed(false),
                  setPasswordText1(""),
                  setPasswordText2("")
                )}
                style={({ hovered }) => [
                  {
                    width: 175,
                    backgroundColor: hovered ? "#e6e6e6" : "#f0f1f1",
                    height: 50,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color: "#505456",
                  }}
                >
                  Cancel
                </Text>
              </Pressable>
              <View style={{ width: "5%" }} />
              <Pressable
                onPress={() => setNewPassword()}
                disabled={atleast6Characters && passwordsMatch ? false : true}
                style={({ hovered }) => [
                  {
                    width: 175,
                    backgroundColor:
                      passwordsMatch && atleast6Characters
                        ? hovered
                          ? colors.redHover
                          : colors.red
                        : "#f0f1f1",
                    height: 50,
                    borderRadius: 5,
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
              >
                <Text
                  style={{
                    fontFamily: "Inter_600SemiBold",
                    fontSize: 16,
                    color:
                      passwordsMatch && atleast6Characters
                        ? "white"
                        : "lightgrey",
                  }}
                >
                  Submit Password
                </Text>
              </Pressable>
            </View>
          )}
          {!passwordsMatch &&
            passwordText2.length > 0 &&
            changePasswordPressed && (
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: "red",
                }}
              >
                Passwords do not match
              </Text>
            )}
          {!atleast6Characters &&
            passwordText1.length > 0 &&
            changePasswordPressed && (
              <Text
                style={{
                  fontFamily: "Inter_500Medium",
                  color: "red",
                  paddingTop: 5,
                }}
              >
                Must be at least 6 characters in length
              </Text>
            )}
        </View>
      )}
      {accountPressed && (
        <View
          style={{
            width: "80%",
            padding: "5%",
            height: "100%",
          }}
        >
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_700Bold",
              paddingTop: "5%",
            }}
          >
            Account
          </Text>
          <Text
            style={{
              color: "#505456",
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Edit your Account & Subscription
          </Text>
          <View
            style={{
              paddingTop: "10%",
              flexDirection: "row",
              width: "95%",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                color: "#000",
                fontSize: 16,
                fontFamily: "Inter_600SemiBold",
              }}
            >
              Subscription:
            </Text>
            <View style={{ width: 10 }} />
            <Text
              style={{
                color: "#505456",
                fontSize: 16,
                fontFamily: "Inter_500Medium",
                textTransform: "capitalize",
              }}
            >
              {subscriptionStatus}
            </Text>
          </View>
          {checkCanceled == "Canceled" && (
            <View
              style={{
                paddingTop: "4%",
                flexDirection: "row",
                width: "95%",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Status:
              </Text>
              <View style={{ width: 10 }} />
              <Text
                style={{
                  color: "#505456",
                  fontSize: 16,
                  fontFamily: "Inter_500Medium",
                  textTransform: "capitalize",
                }}
              >
                {checkCanceled}
              </Text>
              {checkCanceled == "Canceled" && subscriptionStatus != "none" && (
                <TouchableOpacity
                  onPress={() => cancelSubscription()}
                  style={{ position: "absolute", right: 0 }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      fontSize: 15,
                      color: "#1a75ff",
                    }}
                  >
                    Renew Plan
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}

          {checkCanceled != "Canceled" && subscriptionStatus != "none" && (
            <View
              style={{
                paddingTop: "4%",
                flexDirection: "row",
                width: "95%",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Renewal Date:
              </Text>
              <View style={{ width: 10 }} />
              <Text
                style={{
                  color: "#505456",
                  fontSize: 16,
                  fontFamily: "Inter_500Medium",
                  textTransform: "capitalize",
                }}
              >
                {moment(periodEnd.slice(0, -15)).format("LL")}
              </Text>
            </View>
          )}
          {checkCanceled == "Canceled" && subscriptionStatus != "none" && (
            <View
              style={{
                paddingTop: "4%",
                flexDirection: "row",
                width: "95%",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                End Date:
              </Text>
              <View style={{ width: 10 }} />
              <Text
                style={{
                  color: "#505456",
                  fontSize: 16,
                  fontFamily: "Inter_500Medium",
                  textTransform: "capitalize",
                }}
              >
                {moment(periodEnd.slice(0, -15)).format("LL")}
              </Text>
            </View>
          )}
          {subscriptionStatus != "none" && (
            <View
              style={{
                paddingTop: "4%",
                flexDirection: "row",
                width: "95%",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: "Inter_600SemiBold",
                }}
              >
                Billing Period:
              </Text>
              <View style={{ width: 10 }} />
              <Text
                style={{
                  color: "#505456",
                  fontSize: 16,
                  fontFamily: "Inter_500Medium",
                  textTransform: "capitalize",
                }}
              >
                {subscriptionLength == "month"
                  ? "Monthly"
                  : subscriptionLength == "year"
                  ? "Yearly"
                  : subscriptionLength}
              </Text>
              {subscriptionStatus != "none" && (
                <TouchableOpacity
                  onPress={() => cancelSubscription()}
                  style={{ position: "absolute", right: 0 }}
                >
                  <Text
                    style={{
                      fontFamily: "Inter_600SemiBold",
                      fontSize: 15,
                      color: "#1a75ff",
                    }}
                  >
                    Edit Subscription
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {subscriptionStatus == "none" && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "center",
                position: "absolute",
                bottom: 50,
              }}
            >
              <Pressable
                disabled={showingCheckout}
                onPress={() => {
                  setShowingCheckout(true),
                    createCheckoutSession(
                      auth.currentUser?.uid,
                      ENV_ST_MO,
                      ENV_POST_SUBSCR,
                      ENV_POST_CANCEL_SUBSCR
                    ),
                    setTimeout(() => {
                      setShowingCheckout(false);
                    }, 5000);
                }}
                style={({ hovered }) => [
                  {
                    borderRadius: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 175,
                    height: 50,
                    backgroundColor: hovered ? colors.redHover : colors.red,
                  },
                ]}
              >
                {!showingCheckout && (
                  <Text
                    style={{
                      fontSize: 16,
                      color: "white",
                      fontFamily: "Inter_500Medium",
                    }}
                  >
                    Upgrade
                  </Text>
                )}
                {showingCheckout && (
                  <LottieView
                    ref={animation}
                    style={{
                      width: 40,
                      height: 15,
                    }}
                    autoPlay={true}
                    loop={true}
                    source={require("../assets/loadingAnimation.json")}
                  />
                )}
              </Pressable>
            </View>
          )}
        </View>
      )}
      {supportPressed && (
        <View style={{ width: "80%", padding: "5%" }}>
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_700Bold",
              paddingTop: "5%",
            }}
          >
            Support
          </Text>
          <Text
            style={{
              color: "#505456",
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            If you have questions, issues, or feedback (including any bugs!) -
            please reach out to support@amplabel.io
          </Text>
          <Text
            style={{
              color: "#505456",
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Please allow at least 2 business days for a response
          </Text>
        </View>
      )}
      {signOutPressed && (
        <View style={{ width: "80%", padding: "5%" }}>
          <Text
            style={{
              color: "#505456",
              fontSize: 25,
              fontFamily: "Inter_700Bold",
              paddingTop: "5%",
            }}
          >
            Sign Out
          </Text>
          <Text
            style={{
              color: "#505456",
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            Are you sure you want to sign out?
          </Text>
          <Text
            style={{
              color: "#505456",
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            If you have the editor open, be sure to download your latest
            progress. Your images and model are deleted once you leave the
            editor.
          </Text>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignSelf: "center",
              position: "absolute",
              bottom: 50,
            }}
          >
            <Pressable
              onPress={() => signOutUser()}
              style={({ hovered }) => [
                {
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 50,
                  backgroundColor: hovered ? colors.redHover : colors.red,
                },
              ]}
            >
              <Text
                style={{
                  fontFamily: "Inter_600SemiBold",
                  fontSize: 16,
                  color: "white",
                }}
              >
                Sign Out
              </Text>
            </Pressable>
          </View>
        </View>
      )}
    </View>
  );
}
