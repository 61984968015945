export const Manufacturing_defects = [
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok1.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect1.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect2.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok2.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect3.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok4.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect4.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect5.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok5.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect6.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect7.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect8.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect9.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect10.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok12.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect11.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect12.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect13.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok15.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok14.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect15.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok16.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect16.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok17.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect17.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/defect18.jpg"),
  },
  {
    label: "Unlabeled",
    image: require("../Images/Manufacturing_defects/ok18.jpg"),
  },
];
