import React from "react";
import { Text, View } from "react-native";
import { Input, Overlay } from "react-native-elements";
import { colors } from "../Styles/colors";
import { Hoverable, Pressable, ScrollView } from "react-native-web-hover";
import MainButton from "./MainButton";
import SecondaryButton from "./SecondaryButton";

export default function SmallPopUp(props) {
  return (
    <Overlay
      isVisible={props.trigger}
      fullScreen={props.fullscreen == "true"}
      overlayStyle={props.overlaystyle}
    >
      <View
        style={{
          height: 400,
          width: 700,
          backgroundColor: colors.greySidebar,
          borderRadius: 10,
        }}
      >
        <View //header
          style={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderBottomWidth: 1,
            borderColor: colors.greyBorder,
            height: 100,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            width: "100%",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 20,
              fontFamily: "Inter_600SemiBold",
            }}
          >
            {props.headertext}
          </Text>
        </View>

        <View //body
          style={{ padding: 30 }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: 16,
              fontFamily: "Inter_500Medium",
              paddingTop: "5%",
            }}
          >
            {props.bodytext}
          </Text>
        </View>

        <View //footer
          style={{
            flexDirection: "row",
            justifyContent: "center",
            position: "absolute",
            bottom: 50,
            alignSelf: "center",
          }}
        >
          {props.showcancelbutton == "true" && (
            <SecondaryButton
              disabled={false}
              onPress={() => props.cancelbuttonfunction(false)}
              text={props.cancelbuttontext}
              showLoading={false}
            />
          )}
          {props.showcancelbutton == "true" &&
            props.showactionbutton == "true" && (
              <View style={{ width: "5%" }} />
            )}
          {props.showactionbutton == "true" && (
            <MainButton
              disabled={false}
              onPress={() => props.actionbuttonfunction()}
              text={props.actionbuttontext}
              showLoading={false}
            />
          )}
        </View>
      </View>
    </Overlay>
  );
}
