import React, { useState, useContext, useEffect, useRef } from "react";
import {
  ScrollView,
  Text,
  View,
  Image,
  StyleSheet,
  Animated,
  Button,
} from "react-native";
import * as Animatable from "react-native-animatable";
import { LinearGradient } from "expo-linear-gradient";
import { AuthContext } from "../References/Auth";
import { colors } from "../Styles/colors";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Anchor from "../Components/Anchor";
import HomeTitle from "../Components/HomeTitle";
import HomeCards from "../Components/HomeCards";
import HomeContents from "../Components/HomeContent";
import { auth } from "../References/firebase";
import hexToRgba from "hex-to-rgba";
import { colorsDark } from "react-native-elements/dist/config";
import { Video, ResizeMode } from "expo-av";

export default function Home({ navigation }) {
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const { signInCheck, width, height } = useContext(AuthContext);
  const [premiumStatus, setPremiumStatus] = useState(false);

  //Animations
  const [headerAnimation] = useState(new Animated.Value(0));
  const headerColor = headerAnimation.interpolate({
    inputRange: [0, 150],
    outputRange: ["rgba(255,255,255,0)", "white"],
  });

  //Check if the user is premium
  async function checkIfPremium() {
    try {
      const decodedToken = await auth.currentUser?.getIdTokenResult();
      setPremiumStatus(decodedToken?.claims?.stripeRole);
    } catch (e) {}
  }

  //Run premium status check (only if signed in)
  useEffect(() => {
    {
      signInCheck == "signed_in" &&
        premiumStatus != "premium" &&
        checkIfPremium();
    }
  });

  //Navigation function + parameters (passed to other pages)
  function goTo(page, params) {
    navigation.navigate(page, params);
  }

  //If the user signs in/out, close the profile info prompt
  useEffect(() => {
    showProfileInfo && setShowProfileInfo(false);
  }, [signInCheck]);

  return (
    <ScrollView
      stickyHeaderIndices={[1]}
      stickyHeaderHiddenOnScroll={true}
      style={styles.container}
      onScroll={Animated.event(
        [{ nativeEvent: { contentOffset: { y: headerAnimation } } }],
        { useNativeDriver: false }
      )}
      // onScroll will be fired every 16ms
      scrollEventThrottle={16}
    >
      <View style={styles.backgroundImagesContainer}>
        <Image
          source={require("../assets/HomeBackgroundHoneyComb.png")}
          style={{
            alignSelf: "center",
            width: width,
            height: 600,
            zIndex: 0,
          }}
        />
      </View>
      <Animated.View
        style={[styles.headerContainer, { backgroundColor: headerColor }]}
      >
        <Header goTo={goTo} style={styles.header} />
      </Animated.View>
      <Animatable.View
        animation={"fadeIn"}
        delay={300}
        style={styles.homeTitleContainer}
      >
        <HomeTitle goTo={goTo} premiumStatus={premiumStatus} />
      </Animatable.View>
      <View style={styles.homeCardsContainer}>
        <HomeCards />
      </View>
      <View
        style={{
          backgroundColor: colors.greyBackground,
          height: 650,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Animatable.View
          animation={"fadeIn"}
          delay={300}
          style={{
            width: 1037,
            height: 598,
            backgroundColor: "white",
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Video
            style={{ width: 1067, height: 600 }}
            source={require("../assets/BoltlabelDemo_video.mp4")}
            isMuted
            isLooping
            useNativeControls
            shouldPlay
            posterSource={require("../assets/VideoPlaceholder.png")}
            resizeMode="contain"
          />
        </Animatable.View>
      </View>
      <HomeContents style={styles.homeContents} />
      <Anchor goTo={goTo} />

      <Footer goTo={goTo} />
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: { backgroundColor: colors.greyBackground },
  headerContainer: {
    zIndex: 100,
    shadowColor: "black",
    shadowOpacity: 0,
    shadowRadius: 15,
    shadowOffset: {
      height: 1,
      width: 1,
    },
  },
  header: { zIndex: 100 },
  homeTitleContainer: { zIndex: 2 },
  homeCardsContainer: { zIndex: 1, marginTop: -100, marginBottom: 100 },
  backgroundImagesContainer: {
    position: "absolute",
    top: 0,
    alignSelf: "center",
    alignItems: "center",
    zIndex: 0,
  },
  backgroundImage1: {
    alignSelf: "center",
    width: 1000,
    height: 500,
    borderRadius: 15,
    zIndex: 0,
  },
  backgroundImage2: {
    width: 1000,
    height: 500,
    marginTop: -10,
    borderRadius: 15,
    zIndex: 0,
    transform: [{ rotateX: "180deg" }],
  },
  homeContents: { zIndex: 20 },
  bottomPageGradient: {
    height: 300,
    zIndex: 4,
  },
  bottomPageSpacer: { backgroundColor: colors.greySidebar, height: 100 },
});
